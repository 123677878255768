/**
 * @generated SignedSource<<f67ed6fce6824e68622d69f1e5a2d3eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type CreateUpdateLabel_labels$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<
    | {
        readonly node:
          | {
              readonly Color: string | null | undefined;
              readonly LabelID: number;
              readonly LabelType: string | null | undefined;
              readonly Name: string | null | undefined;
              readonly Sites:
                | {
                    readonly edges: ReadonlyArray<
                      | {
                          readonly node:
                            | {
                                readonly Name: string | null | undefined;
                                readonly SiteID: number;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined
                    >;
                  }
                | null
                | undefined;
              readonly id: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
  readonly ' $fragmentType': 'CreateUpdateLabel_labels';
};
export type CreateUpdateLabel_labels$key = {
  readonly ' $data'?: CreateUpdateLabel_labels$data;
  readonly ' $fragmentSpreads': FragmentRefs<'CreateUpdateLabel_labels'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'Name',
    storageKey: null,
  };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'CreateUpdateLabel_labels',
    selections: [
      {
        alias: null,
        args: null,
        concreteType: 'LabelEdge',
        kind: 'LinkedField',
        name: 'edges',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'Label',
            kind: 'LinkedField',
            name: 'node',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'LabelID',
                storageKey: null,
              },
              v0 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'LabelType',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'Color',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'SiteConnection',
                kind: 'LinkedField',
                name: 'Sites',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: 'SiteEdge',
                    kind: 'LinkedField',
                    name: 'edges',
                    plural: true,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        concreteType: 'Site',
                        kind: 'LinkedField',
                        name: 'node',
                        plural: false,
                        selections: [
                          {
                            alias: null,
                            args: null,
                            kind: 'ScalarField',
                            name: 'SiteID',
                            storageKey: null,
                          },
                          v0 /*: any*/,
                        ],
                        storageKey: null,
                      },
                    ],
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
      {
        kind: 'ClientExtension',
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: '__id',
            storageKey: null,
          },
        ],
      },
    ],
    type: 'LabelConnection',
    abstractKey: null,
  };
})();

(node as any).hash = 'd547be476eb28f1b291f504df2295f04';

export default node;
