import { Icon } from '@ant-design/compatible';
import { Button, Empty } from 'antd';
import _ from 'lodash';
import queryString from 'query-string';
import React from 'react';
import { connect, history } from 'umi';

import ChannelIcon from '@/assets/camera-plain';
import LocationsMap from '@/components/LocationsMap';
import PageHeader from '@/components/PageHeader2';
import SearchForm from '@/components/SearchForm2';
import SearchResults from '@/components/SearchResults';
import ChannelTile from '@/pages/locations/components/channel-tile-2';
import { DELIVERY_STATUS } from '@/types/location';
import { locationFilters } from '@/utils/filterModules';
import { entityHasLicenseOfType } from '@/utils/licenses';
import {
  displayTZ,
  getCurrentCustomerID,
  resetSearchForm2,
  urlTo,
} from '@/utils/utils';
import ChannelGroupTile from '../components/channel-group-tile';
import LocationSettings from './location-settings';
import styles from './style.less';

import type { AccountsModelState } from '@/models/accounts';
import type { Search2ModelType } from '@/models/search2';
import type {
  BaseStationNode,
  BASE_STN_TYPE,
  CH_GRP_TYPE,
  CH_TYPE,
  LocationNode,
  LOC_TYPE,
} from '@/types/location';
import { RouteComponentProps } from '@/types/utils';
import withRouter from '@/utils/withRouter';

interface MatchParams {
  locationID: string;
}

interface MyProps extends RouteComponentProps<MatchParams> {
  loc?: LOC_TYPE;
  ch_grp?: CH_GRP_TYPE;
  ch?: CH_TYPE;
  base_stn?: BASE_STN_TYPE;
  search2?: Search2ModelType;
  accounts?: AccountsModelState;
  currentUser?: any;
  dispatch?: (_any: any) => Promise<any>;
}

type MyState = {};

// @ts-expect-error
@connect(({ locations, search2, accounts, user }) => ({
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
  base_stn: locations.base_stn,
  search2,
  accounts,
  currentUser: user.currentUser,
}))
class LocationPage extends React.Component<MyProps, MyState> {
  locationHeaderRef: React.RefObject<HTMLDivElement>;
  searchFormRef: React.RefObject<SearchForm>;

  constructor(props: MyProps) {
    super(props);
    this.state = {};

    this.locationHeaderRef = React.createRef<HTMLDivElement>();
    this.searchFormRef = React.createRef<SearchForm>();
  }

  componentDidMount() {
    const { dispatch } = this.props;
    if (dispatch) resetSearchForm2(dispatch);
  }

  componentDidUpdate(prevProps: MyProps) {
    const prevLocID = +prevProps.match.params.locationID;
    const locationID = +this.props.match.params.locationID;
    if (prevLocID !== locationID && this.props.dispatch) {
      resetSearchForm2(this.props.dispatch);
    }

    const location = this.props.loc?.byId[+locationID];
    const baseStnID = this.props.base_stn?.map_loc_baseStn.get(+locationID);
    const baseStn = this.props.base_stn?.byId[+(baseStnID || 0)];

    if (location) {
      this.locationChecks(location, baseStn);
    }
  }

  shouldRenderBaseStationCheck(baseStn: BaseStationNode) {
    return (
      baseStn &&
      (!baseStn.DeliveryStatus ||
        baseStn.DeliveryStatus === DELIVERY_STATUS.BOOKED ||
        baseStn.DeliveryStatus === DELIVERY_STATUS.DISPATCHED)
    );
  }

  locationChecks(
    location: LocationNode,
    baseStn: BaseStationNode | undefined = undefined,
  ) {
    const isConnected = location.IsConnected && location.ProjectStatus;
    if (
      location.Channels.length === 0 &&
      baseStn &&
      (isConnected || baseStn.DeliveryStatus === DELIVERY_STATUS.DELIVERED)
    ) {
      const url = urlTo('LOCATION-CONFIGURE-CAMERA', { locID: location.ID });
      if (url) return history.push(url);
    }

    const renderBaseStationCheck =
      baseStn && this.shouldRenderBaseStationCheck(baseStn);
    if (
      !renderBaseStationCheck &&
      !location.IsConnected &&
      location.ChannelGroups.length === 0 &&
      location.Channels.length === 0
    ) {
      const defaultUploadLocatoinID = _.get(
        this.props.currentUser,
        `Customers[${getCurrentCustomerID()}].Customer.Config.UploadsLocationID`,
        0,
      );

      if (location.ID !== +defaultUploadLocatoinID) {
        const values = queryString.parse(this.props.location.search);
        let linkD = `/locations/${location.ID}/clientConnectionOptions`;
        if (values && values.dest === 'cco2') {
          linkD = `/locations/${location.ID}/uploaderClientConnection`;
        }
        return history.push(linkD);
      }
    }
  }

  baseStationBookedRender() {
    return (
      <div className={styles['base-station-detail-ctn']}>
        <div className={styles['tracking-detail']}>
          <span style={{ fontWeight: 500 }}>Status:</span>
          <span> Pending Shipment.</span>
        </div>
        <div className={styles['plugin-info-box']}>
          <div className={styles['icon-ctn']}>
            <Icon
              component={ChannelIcon}
              style={{
                color: '#fff',
                transform: 'scale(2.4)',
              }}
            />
          </div>
          <div className={styles['text-ctn']}>
            Once your Base Station arrives, plug it in at the physical location
            in which it will reside. Nearby cameras on your local network will
            be auto-discovered and displayed here for configuration.
          </div>
        </div>
      </div>
    );
  }

  baseStationDispatchedRender(baseStn: BaseStationNode) {
    const href =
      baseStn.TrackingUrl ||
      `https://www.google.com/search?q=${baseStn.TrackingNumber}`;
    return (
      <div className={styles['base-station-detail-ctn']}>
        <div className={styles['tracking-detail']}>
          <span style={{ fontWeight: 600 }}>Status:</span>
          <span> Shipped. </span>
          {baseStn.TrackingNumber && (
            <>
              <span style={{ fontWeight: 600 }}>Tracking number: </span>
              <a target="_blank" rel="noreferrer" href={href}>
                {baseStn.TrackingNumber}
              </a>
            </>
          )}
        </div>
        <div className={styles['plugin-info-box']}>
          <div className={styles['icon-ctn']}>
            <Icon
              component={ChannelIcon}
              style={{
                color: '#fff',
                transform: 'scale(2.4)',
              }}
            />
          </div>
          <div className={styles['text-ctn']}>
            Once your Base Station arrives, plug it in at the physical location
            in which it will reside. Nearby cameras on your local network will
            be auto-discovered and displayed here for configuration.
          </div>
        </div>
      </div>
    );
  }

  baseStationCheckRenderer(baseStn: BaseStationNode) {
    if (
      !baseStn.DeliveryStatus ||
      baseStn.DeliveryStatus === DELIVERY_STATUS.BOOKED
    ) {
      this.baseStationBookedRender();
    }
    if (baseStn.DeliveryStatus === DELIVERY_STATUS.DELIVERED) {
      this.baseStationDispatchedRender(baseStn);
    }
    return <></>;
  }

  locationContentRenderer(location: LocationNode) {
    const { ch_grp, ch, search2, accounts } = this.props;
    if (ch_grp && ch && search2 && accounts) {
      const loc_ch_grp = _.orderBy(
        location.ChannelGroups.filter((chGrpID) => chGrpID in ch_grp.byId).map(
          (chGrpID) => ch_grp?.byId[chGrpID],
        ),
        (channel_group) => channel_group?.Name,
        ['asc'],
      );
      const loc_ch = _.orderBy(
        location.Channels.filter((chID) => chID in ch.byId).map(
          (chID) => ch?.byId[chID],
        ),
        (channel) => channel?.RTSPconfig.monitor_status,
        ['desc'],
      );

      const locationHasDISLicense = entityHasLicenseOfType(
        accounts,
        'DIS',
        null,
        location.ID,
      );

      return (
        <div className={styles['main-area']}>
          <div style={{ flexGrow: 1, display: 'flex', flexWrap: 'wrap' }}>
            {search2.showSearchResults ? (
              <div
                className={styles['main-body']}
                style={{ marginRight: '16px' }}>
                <SearchResults
                  searchForm={this.searchFormRef}
                  currentLocation={location}
                />
              </div>
            ) : (
              <div className={styles['main-body']}>
                <LocationsMap
                  style={{ margin: '0 16px 16px 0' }}
                  locationIDs={[location.ID]}
                />
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {location.ChannelGroups.length === 0 &&
                  location.Channels.length === 0 ? (
                    <Empty
                      style={{ width: '100%' }}
                      description="No Cameras"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  ) : null}
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {loc_ch_grp.map((channelGroup) => (
                    <ChannelGroupTile
                      key={channelGroup.ID}
                      channelGroupID={channelGroup.ID}
                    />
                  ))}
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {loc_ch.map((channel) => (
                    <ChannelTile
                      key={channel.ID}
                      channelID={channel.ID}
                      showTime={false}
                      preload={true}
                      showImagePreview={false} // deprecated in locations page
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          {locationHasDISLicense && (
            <SearchForm
              ref={this.searchFormRef}
              showFilters={locationFilters}
              locationID={location.ID}
            />
          )}
        </div>
      );
    }

    return <></>;
  }

  render() {
    const { locationID } = this.props.match.params;
    const { loc, base_stn } = this.props;
    const location = loc?.byId[+locationID];

    if (!(locationID && location)) {
      return null;
    }

    const baseStnID = base_stn?.map_loc_baseStn.get(+locationID);
    const baseStn = base_stn?.byId[+(baseStnID || 0)];
    const renderBaseStationCheck =
      baseStn && this.shouldRenderBaseStationCheck(baseStn);

    return (
      <>
        <div
          className={styles.container}
          ref={this.locationHeaderRef}
          id="loc-ch_grp-ch-title">
          <PageHeader
            title={location.Name}
            subtitle={displayTZ(location.Timezone)}
            loc_node={location}
            right={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {location.VMSPluginID && location.VMSPluginID > 1 ? (
                  // Show "configue cameras" for VMSPluginID > 1
                  // '1' is the fake VMS upload ID
                  <Button
                    size="small"
                    type="default"
                    style={{ marginRight: '4px' }}
                    onClick={() => {
                      const url = urlTo('LOCATION-CONFIGURE-CAMERA', {
                        locID: location.ID,
                      });
                      if (url) history.push(url);
                    }}>
                    Configure Cameras
                  </Button>
                ) : null}
                <LocationSettings
                  // @ts-expect-error
                  locationID={location.ID}
                  showModal={
                    this.props.location.query?.showSettingsModal === 'true'
                  }
                  startTabName={this.props.location.query?.startTabName}>
                  <Button size="small" type="default">
                    Settings
                  </Button>
                </LocationSettings>
              </div>
            }
          />
          {renderBaseStationCheck
            ? this.baseStationCheckRenderer(baseStn)
            : this.locationContentRenderer(location)}
        </div>
      </>
    );
  }
}
export default withRouter(LocationPage);
