import { graphql } from 'react-relay';

import { ArrowDownOutlined, SearchOutlined } from '@ant-design/icons';
import { Navigate, useDispatch, useNavigate } from '@umijs/max';
import { Button, Flex, theme } from 'antd';
import { Suspense, useMemo, useState } from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay';

import SortListIcon from '@/assets/sort-list-icon.svg';
import { DfDropdown } from '@/components/DfDropdown';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useAppCapabilities } from '@/utils/useAppCapabilities';
import { CreateNewSceneCard } from '../components/CreateNewSceneCard';
import { Scene } from '../components/Scene';
import type { SceneFragment$key } from '../components/Scene/__generated__/SceneFragment.graphql';
import SceneViewer from '../components/SceneViewer';
import {
  CAPABILITIES_MAP,
  MonitorAppPathname,
  ScenesPerPage,
  SceneTypesMap,
} from '../constants';
import { useMonitorContext } from '../MonitorContext';
import { MonitorHeaderDropdownItem } from '../styles';
import type { MonitorSceneSortEnum } from '../TeamScenes/__generated__/TeamScenesPaginationQuery.graphql';
import { useMonitorAppSubHeaderAuxControls } from '../useMonitorAppSubHeaderAuxControls';
import {
  MyScenesContainer,
  MyScenesListContainer,
  MyScenesLoadMoreCtaContainer,
} from './styles';
import useMyScenesConnectionInfo from './useMyScenesConnectionInfo';
import type { MyScenesFragment$key } from './__generated__/MyScenesFragment.graphql';
import type { MyScenesRootQuery } from './__generated__/MyScenesRootQuery.graphql';

const { useToken } = theme;

const MyScenesQuery = graphql`
  query MyScenesRootQuery($appId: Int!, $customerId: Int!) {
    monitorUserScenes(appId: $appId, customerId: $customerId) {
      userScenesData: scenesData {
        __id
        ...MyScenesFragment @arguments(appId: $appId, customerId: $customerId)
      }
    }
  }
`;

export const MyScenesFragment = graphql`
  fragment MyScenesFragment on MonitorUserScenesData
  @argumentDefinitions(
    sort: { type: "MonitorSceneSortEnum", defaultValue: TIMESTAMP_DESC }
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "String" }
    appId: { type: "Int" }
    customerId: { type: "Int" }
  )
  @refetchable(queryName: "MyScenesPaginationQuery") {
    scenes(
      first: $count
      after: $cursor
      sort: $sort
      appId: $appId
      customerId: $customerId
    ) @connection(key: "MyScenesConnection_scenes") {
      __id
      edges {
        node {
          id
          ...SceneFragment
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const MyScenesContent = () => {
  const { customerId, appId } = useMonitorContext();
  const { token } = useToken();
  const dispatch = useDispatch();

  const { tabsToShow } = useAppCapabilities({
    appId,
  });

  const myScenesQueryData = useLazyLoadQuery<MyScenesRootQuery>(MyScenesQuery, {
    appId,
    customerId,
  });

  const [openSaveSceneModal, setOpenSaveSceneModal] = useState(false);

  const [selectedSceneRef, setSelectedSceneRef] = useState<SceneFragment$key>();

  const [isSearchMode, setIsSearchMode] = useState(false);

  const isSceneSelected = !!selectedSceneRef;

  const [selectedSortOption, setSelectedSortOption] =
    useState<MonitorSceneSortEnum>('TIMESTAMP_DESC');
  const {
    data: fragmentData,
    loadNext,
    refetch,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(
    MyScenesFragment,
    myScenesQueryData.monitorUserScenes?.userScenesData as MyScenesFragment$key,
  );

  const { getMyScenesConnectionId } = useMyScenesConnectionInfo(
    appId,
    customerId,
  );
  const connectionId = useMemo(
    () =>
      getMyScenesConnectionId({
        appId: appId,
        customerId: customerId,
        sort: selectedSortOption,
      }),
    [selectedSortOption],
  );

  const scenes = fragmentData.scenes?.edges;
  const navigate = useNavigate();

  const handleNewSceneClick = () => {
    navigate(`${MonitorAppPathname}/active-scene`);
  };

  const toggleSetSelectedSortingOption = (sortOption: MonitorSceneSortEnum) => {
    setSelectedSortOption(sortOption);
    refetch({
      count: ScenesPerPage,
      sort: sortOption,
      app_id: appId,
      customer_id: customerId,
    });
  };

  const handleModalClose = () => {
    setOpenSaveSceneModal(false);
  };

  const handleSaveScene = () => {
    setOpenSaveSceneModal(false);
  };

  const toggleSearchMode = () => {
    dispatch({ type: 'monitor_search/resetState' });
    setIsSearchMode(!isSearchMode);
  };

  useMonitorAppSubHeaderAuxControls(
    {
      auxControls: (
        <Flex gap={20}>
          {isSceneSelected && (
            <Button onClick={() => setOpenSaveSceneModal(true)} type="primary">
              Update Scene
            </Button>
          )}
          <Button onClick={handleNewSceneClick}>+ New Scene</Button>
          {isSceneSelected && (
            <Button
              onClick={toggleSearchMode}
              style={{
                border: 'none',
                backgroundColor: isSearchMode
                  ? token.colorPrimaryBg
                  : 'transparent',
              }}
              icon={<SearchOutlined style={{ fontSize: ' 24px' }} />}
            />
          )}
          {!isSceneSelected && (
            <DfDropdown
              menu={{
                items: [
                  {
                    key: '0',
                    label: (
                      <MonitorHeaderDropdownItem
                        isHighLighted={selectedSortOption === 'TIMESTAMP_DESC'}>
                        Most recently updated
                      </MonitorHeaderDropdownItem>
                    ),
                    onClick: () =>
                      toggleSetSelectedSortingOption('TIMESTAMP_DESC'),
                  },
                  {
                    key: '1',
                    label: (
                      <MonitorHeaderDropdownItem
                        isHighLighted={selectedSortOption === 'TIMESTAMP_ASC'}>
                        Least recently updated
                      </MonitorHeaderDropdownItem>
                    ),
                    onClick: () =>
                      toggleSetSelectedSortingOption('TIMESTAMP_ASC'),
                  },
                  {
                    key: '2',
                    label: (
                      <MonitorHeaderDropdownItem
                        isHighLighted={selectedSortOption === 'NAME_ASC'}>
                        Alphabetical A-Z
                      </MonitorHeaderDropdownItem>
                    ),
                    onClick: () => toggleSetSelectedSortingOption('NAME_ASC'),
                  },
                  {
                    key: '3',
                    label: (
                      <MonitorHeaderDropdownItem
                        isHighLighted={selectedSortOption === 'NAME_DESC'}>
                        Alphabetical Z-A
                      </MonitorHeaderDropdownItem>
                    ),
                    onClick: () => toggleSetSelectedSortingOption('NAME_DESC'),
                  },
                ],
              }}
              trigger={['click']}>
              <Button icon={<img src={SortListIcon} />} type="text" ghost />
            </DfDropdown>
          )}
        </Flex>
      ),
      backPath: isSceneSelected ? `${MonitorAppPathname}/my-scenes` : undefined,
      onBackClick: () => {
        setIsSearchMode(false);
        setSelectedSceneRef(undefined);
      },
    },
    [selectedSortOption, isSceneSelected, isSearchMode],
  );

  const handleTileSelect = (sceneRef: SceneFragment$key) => {
    setSelectedSceneRef(sceneRef);
  };

  const handleLoadMore = () => {
    loadNext(ScenesPerPage, {
      UNSTABLE_extraVariables: {
        app_id: appId,
        customer_id: customerId,
        sort: selectedSortOption,
      },
    });
  };

  if (tabsToShow.indexOf(CAPABILITIES_MAP.myScenes) === -1) {
    return <Navigate to={MonitorAppPathname} />;
  }

  if (!scenes?.length) {
    return <CreateNewSceneCard />;
  }

  return isSceneSelected ? (
    <SceneViewer
      selectedSceneRef={selectedSceneRef}
      openSaveSceneModal={openSaveSceneModal}
      onModalClose={handleModalClose}
      onSceneSave={handleSaveScene}
      isSearchMode={isSearchMode}
    />
  ) : (
    <MyScenesContainer>
      <MyScenesListContainer>
        {scenes.map((scene) => (
          <Scene
            key={scene?.node?.id}
            sceneKey={scene?.node as SceneFragment$key}
            onSelect={handleTileSelect}
            sceneType={SceneTypesMap.USER}
            parentConnectionId={connectionId}
          />
        ))}
      </MyScenesListContainer>
      <MyScenesLoadMoreCtaContainer>
        {hasNext && (
          <Button
            type="link"
            icon={<ArrowDownOutlined />}
            onClick={handleLoadMore}
            loading={isLoadingNext}>
            Load more
          </Button>
        )}
      </MyScenesLoadMoreCtaContainer>
    </MyScenesContainer>
  );
};

const MyScenes = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <MyScenesContent />
    </Suspense>
  );
};

export default MyScenes;
