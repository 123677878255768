import _ from 'lodash';

import BurglarAlarmApp from '../AlarmApp/BurglarAlarmApp';
import CameraMonitoringAlarmApp from '../AlarmApp/CameraMonitoringAlarmApp';
import LprAlarmApp from '../AlarmApp/LprAlarmApp';
import SmokeAndFireAlarmApp from '../AlarmApp/SmokeAndFireAlarmApp';
import BrivoApp from '../BrivoApp';
import CheckoutInsightsApp from '../CheckoutInsightsApp';
import CorporateApp from '../CorporateApp';
import CustomerJourneyApp from '../CustomerJourney';
import FeedStatusApp from '../FeedStatusApp';
import FleetManagerApp from '../FleetManagerApp';
import GeneaApp from '../GeneaApp';
import InsightsDashboardApp from '../InsightsDashboardApp';
import InternalMonitoringApp from '../InternalMonitoringApp';
import KaizenApp from '../KaizenApp';
import KisiApp from '../KisiApp';
import LicenseScannerApp from '../LicenseScannerApp';
import { default as LPRReportAppLegacy } from '../LPRReportAppLegacy';
import LPRReportsApp from '../LPRReports';
import NotificationsApp from '../NotificationsApp';
import OpenPathApp from '../OpenPathApp';
import POSApp from '../POSApp';
import ProductSearchApp from '../ProductSearchApp';
import RedactionApp from '../RedactionApp';
import RetailApp from '../RetailApp';
import RetailInsightLegacyApp from '../RetailInsightLegacyApp';
import RetailInsightsApp from '../RetailInsights';
import RollOutApp from '../RollOutApp';
import SeaWorldApp from '../SeaWorldApp';
import SelfCheckoutLossDetectionApp from '../SelfCheckoutLossDetectionApp';
import SharingApp from '../SharingApp';
import TestingApp from '../TestingApp';
import TruckTurnaroundTimeApp from '../TruckTurnaroundTimeApp';
import VMSPlus from '../VMSPlus';

export type AppItem = {
  component: React.ComponentType<any> & {
    CAPABILITIES?: string[] | object;
    DEFAULT_CAPABILITIES?: string[] | object;
  };
  appId: string;
  appName: string;
  isInternal?: boolean;
};

export const ALL_APPS: Record<string, AppItem> = {
  '7': { component: SharingApp, appId: '7', appName: 'Sharing App' },
  '40': {
    component: LicenseScannerApp,
    appId: '40',
    appName: 'License Scanner App',
  },
  '41': { component: FeedStatusApp, appId: '41', appName: 'Feed Status App' },
  '42': { component: SeaWorldApp, appId: '42', appName: 'Sea World App' },
  '43': {
    component: LPRReportAppLegacy,
    appId: '43',
    appName: 'LPR Report App Legacy',
  },
  '44': {
    component: NotificationsApp,
    appId: '44',
    appName: 'Notifications App',
  },
  '45': { component: CorporateApp, appId: '45', appName: 'Corporate App' },
  '46': { component: RedactionApp, appId: '46', appName: 'Redaction App' },
  '47': { component: RetailApp, appId: '47', appName: 'Retail App' },
  '48': {
    component: InternalMonitoringApp,
    appId: '48',
    appName: 'Internal Monitoring App',
  },
  '49': { component: OpenPathApp, appId: '49', appName: 'Open Path App' },
  '50': { component: GeneaApp, appId: '50', appName: 'Genea App' },
  '51': {
    component: TruckTurnaroundTimeApp,
    appId: '51',
    appName: 'Truck Turnaround Time App',
  },
  '52': { component: KisiApp, appId: '52', appName: 'Kisi App' },
  '53': { component: POSApp, appId: '53', appName: 'POS App' },
  '55': { component: BrivoApp, appId: '55', appName: 'Brivo App' },
  '60': {
    component: RetailInsightLegacyApp,
    appId: '60',
    appName: 'Retail Insight Legacy App',
  },
  '61': {
    component: ProductSearchApp,
    appId: '61',
    appName: 'Product Search App',
  },
  '63': { component: KaizenApp, appId: '63', appName: 'Kaizen App' },
  '64': {
    component: RetailInsightsApp,
    appId: '64',
    appName: 'Retail Insights App',
  },
  '65': { component: RollOutApp, appId: '65', appName: 'Roll Out App' },
  '98': {
    component: InsightsDashboardApp,
    appId: '98',
    appName: 'Insights Dashboard App',
  },
  '99': { component: TestingApp, appId: '99', appName: 'Testing App' },
  '101': {
    component: BurglarAlarmApp,
    appId: '101',
    appName: 'Burglar Alarm App',
  },
  '102': { component: LPRReportsApp, appId: '102', appName: 'LPR Reports App' },
  '103': {
    component: SmokeAndFireAlarmApp,
    appId: '103',
    appName: 'Smoke And Fire Alarm App',
  },
  '105': {
    component: SelfCheckoutLossDetectionApp,
    appId: '105',
    appName: 'Self Checkout Loss Detection App',
  },
  '106': {
    component: FleetManagerApp,
    appId: '106',
    appName: 'Fleet Manager',
  },
  '107': {
    component: VMSPlus,
    appId: '107',
    appName: 'Monitor', // The name has been changed to `Monitor` now from `VMS+` - https://dragonfruitai.slack.com/archives/C07CE7CR0A0/p1722492414135399
  },
  '108': {
    component: CustomerJourneyApp,
    appId: '108',
    appName: 'Customer Journey',
    isInternal: true,
  },
  '109': {
    component: CheckoutInsightsApp,
    appId: '109',
    appName: 'CheckoutInsights',
  },
  '110': {
    component: LprAlarmApp,
    appId: '110',
    appName: 'LPR Alarm App',
    // isInternal: true,
  },
  '119': {
    component: CameraMonitoringAlarmApp,
    appId: '119',
    appName: 'Camera Monitoring Alarm App',
  },
};

export const APPS = _.reduce(
  ALL_APPS,
  (acc: Record<AppItem['appId'], AppItem['component']>, app, key) => {
    acc[key] = app.component;
    return acc;
  },
  {},
);

export const ACCESS_CONTROL_APPS = [GeneaApp, OpenPathApp, KisiApp, BrivoApp];
