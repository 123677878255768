export const DFConfigKeys = {
  be_gpt_summary: 'be_gpt_summary',
  dc_onprem_cropRectangle: 'dc_onprem_cropRectangle',
  dc_onprem_baseFolderPath: 'dc_onprem_baseFolderPath',
  dc_onprem_mediaArchiveAgeInMins: 'dc_onprem_mediaArchiveAgeInMins',
  dc_onprem_externalStorageDevicesConfigs:
    'dc_onprem_externalStorageDevicesConfigs',
  dc_onprem_secondaryRTSPStreams: 'dc_onprem_secondaryRTSPStreams',
  fe_webrtc_enabled: 'fe_webrtc_enabled',
  fe_webrtc_archive_enabled: 'fe_webrtc_archive_enabled',
  fe_camera_controls_visibility: 'fe_camera_controls_visibility',
  fe_monitor_search_lpr: 'fe_monitor_search_lpr',
  fe_demographic_insights_visibility: 'fe_demographic_insights_visibility',
  fe_staff_detection: 'fe_staff_detection',
  fe_locations_tab_visibility: 'fe_locations_tab_visibility',
  fe_investigations_tab_visibility: 'fe_investigations_tab_visibility',
  fe_views_tab_visibility: 'fe_views_tab_visibility',
  fe_insights_tab_visibility: 'fe_insights_tab_visibility',
  fe_alerts_tab_visibility: 'fe_alerts_tab_visibility',
  fe_mapping_v2_selector: 'fe_mapping_v2_selector',
  fe_alert_sounds: 'fe_alert_sounds',
  fe_locations_showImagePreview: 'fe_locations_showImagePreview',
  fe_auto_tune_webrtc_video_quality: 'fe_auto_tune_webrtc_video_quality',
  fe_video_quality_selection: 'fe_video_quality_selection',
  geo_location: 'geo_location',
} as const;
