/**
 * @generated SignedSource<<7738d8c834b75ee1ca844a20f768e252>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChannelSelectCascaderFragment_ChannelsBySites$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly Channels: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly ChannelID: number;
            readonly Name: string | null | undefined;
          } | null | undefined;
        } | null | undefined>;
      } | null | undefined;
      readonly Name: string | null | undefined;
      readonly SiteID: number;
    } | null | undefined;
  } | null | undefined>;
  readonly " $fragmentType": "ChannelSelectCascaderFragment_ChannelsBySites";
};
export type ChannelSelectCascaderFragment_ChannelsBySites$key = {
  readonly " $data"?: ChannelSelectCascaderFragment_ChannelsBySites$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChannelSelectCascaderFragment_ChannelsBySites">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChannelSelectCascaderFragment_ChannelsBySites",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Site",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "SiteID",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "sort",
                  "value": [
                    "NAME_ASC"
                  ]
                }
              ],
              "concreteType": "ChannelConnection",
              "kind": "LinkedField",
              "name": "Channels",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChannelEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Channel",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "ChannelID",
                          "storageKey": null
                        },
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "Channels(sort:[\"NAME_ASC\"])"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SiteConnection",
  "abstractKey": null
};
})();

(node as any).hash = "ba7f8ae98b0638a7cadc3e029df5b664";

export default node;
