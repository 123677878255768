import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import { Tabs } from 'antd';
import _ from 'lodash';
import { connect, ConnectProps } from 'umi';
import BaseApp from '../../BaseApp';
import AlarmsTabContent from './AlarmsTabContent';
import { TABS, TABS_ORDER, TAB_LABELS } from './constants';

// Define the props interface for the component
interface CameraMonitoringAlarmAppProps extends ConnectProps {
  sites: any;
  locations: any;
  appConfig: any;
  app: any;
}

// @ts-expect-error
@connect(
  ({
    locations,
    camera_monitoring_alarm: app,
    apps,
    sites,
    accounts,
  }: {
    locations: any;
    camera_monitoring_alarm: any;
    apps: { all: any[] };
    sites: any;
    accounts: { users: any[] };
  }) => ({
    sites,
    locations,
    appConfig:
      apps.all.filter(
        (a: any) => a.AppID == CameraMonitoringAlarmApp.APP_ID,
      )[0] || {},
    app,
    users: accounts.users,
  }),
)
class CameraMonitoringAlarmApp extends BaseApp {
  static APP_ID = 119;
  static NAMESPACE = 'camera_monitoring_alarm';
  static CAPABILITIES = TABS;
  static CAPABILITIES_LABEL_MAP = TAB_LABELS;

  state: {
    activeTabKey: string;
  };

  constructor(props: CameraMonitoringAlarmAppProps) {
    super(props);
    this.state = {
      activeTabKey: TABS.ALARMS,
    };
  }

  onTabChangeHandler = (activeKey: string): void => {
    this.setState({ activeTabKey: activeKey });
  };

  render(): React.ReactNode {
    const { locations, sites, app, appConfig } = this
      .props as CameraMonitoringAlarmAppProps;
    const { activeTabKey } = this.state;

    if (!appConfig) {
      return <LoadingSpinner />;
    }

    const capabilities = _.get(appConfig, 'capabilities', []);
    const filterSiteIDs = _.map(
      _.get(appConfig, 'scopes.sites', []),
      (site) => site.id,
    );

    // Filter tabs based on capabilities
    let tabsToShow = TABS_ORDER.filter(
      (tab) => capabilities.includes(tab) || capabilities.includes('*'),
    );

    // If no tabs are available after filtering, default to showing only the ALARMS tab
    if (tabsToShow.length === 0) {
      tabsToShow = [TABS.ALARMS];
    }

    return (
      <>
        <PageHeader title={appConfig.Name} />
        <div style={{ margin: '0px 16px 16px 0px', maxWidth: '100%' }}>
          <Tabs
            className="df-full-height-tabs"
            tabBarStyle={{ margin: '0px' }}
            activeKey={activeTabKey}
            onChange={this.onTabChangeHandler}>
            {tabsToShow.map((tabId) => {
              const tabName = TAB_LABELS[tabId];

              return (
                <Tabs.TabPane
                  tab={tabName}
                  key={tabId}
                  style={{ marginTop: '12px' }}>
                  {tabId === TABS.ALARMS && (
                    <AlarmsTabContent
                      filteredSites={sites.all.filter((s: any) =>
                        filterSiteIDs.includes(s.SiteID),
                      )}
                      locations={locations}
                      sites={sites}
                      app={app}
                      namespace={CameraMonitoringAlarmApp.NAMESPACE}
                    />
                  )}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
      </>
    );
  }
}

export default CameraMonitoringAlarmApp;
