import {
  convertSearchClipsIntoSearchEvents,
  getClipStartTime,
  getLocalVideoEndSecs,
  getLocalVideoStartSecs,
  interpretClipData,
} from '@/utils/utils';
import _ from 'lodash';

export const computeTimelinePlayerProps = (
  clip: any,
  clips: any[],
  queryID: string,
  search_result_events: any[],
  locations: any,
  selectedChannels: number[],
) => {
  if (queryID === '1') {
    return {
      startTime: getClipStartTime(
        clip,
        _.get(locations.ch.byId[clip.ChannelID], 'Timezone'),
      )?.unix(),
      fitEventsOnAxis: true,
      events: search_result_events,
      eventsOnly: true,
    };
  } else if (queryID === '2') {
    const clipData = interpretClipData(
      clip,
      locations,
      _.get(locations.ch.byId[clip.ChannelID], 'Timezone'),
      clips,
    );
    return {
      autoPlay: true,
      showLive: false,
      fitEventsOnAxis: false,
      startTime: _.get(clipData, 'from')?.valueOf() / 1000 ?? 0,
      endTime: _.get(clipData, 'to')?.valueOf() / 1000 ?? 0,
      events: search_result_events,
      channelIdOrder: selectedChannels,
    };
  }
};

export const computeSearchEvents = (clips: any[], locations: any) => {
  const search_events = convertSearchClipsIntoSearchEvents(clips, locations);
  const videoToEventMap = {};
  _.forEach(clips, (clip) => {
    if (!videoToEventMap[clip.UploadID]) {
      const clipCopy = _.cloneDeep(clip);
      const channel_obj = _.get(locations.ch, `byId[${clipCopy.ChannelID}]`);
      const timezone = channel_obj?.Timezone || 'UTC';
      videoToEventMap[clipCopy.UploadID] = {
        clipCopy,
        Channel: channel_obj,
        ChannelID: +clipCopy.ChannelID,
        Timezone: timezone,
        Media: [clipCopy],
        // EventStart|End are ES time (local time but written in UTC)
        EventStart: getLocalVideoStartSecs(clipCopy, timezone) * 1000,
        EventEnd: getLocalVideoEndSecs(clipCopy, timezone) * 1000,
      };
    }
  });
  return [...search_events, ...Object.values(videoToEventMap)];
};

export const getClipKey = (clip: any): string => {
  if (clip?.ObjectID !== undefined) {
    return clip.ObjectID;
  }
  if (clip?.object_id !== undefined) {
    return clip.object_id;
  }
  if (clip?.UploadID !== undefined) {
    return clip.UploadID;
  }
  return 'no_key';
};
