import React, { useState } from 'react';
import { useDispatch } from 'umi';
import AlarmList from './AlarmList';
import SetupAlarm from './setup-alarm';

interface AlarmsTabContentProps {
  filteredSites: any[];
  locations: any;
  sites: any;
  app: any;
  namespace: string;
}

const AlarmsTabContent: React.FC<AlarmsTabContentProps> = ({
  filteredSites,
  locations,
  sites,
  app,
  namespace,
}) => {
  const [isEditingAlarm, setIsEditingAlarm] = useState(false);
  const [selectedAlarm, setSelectedAlarm] = useState<any>(null);
  const dispatch = useDispatch();

  const handleEditAlarm = (alarm: any) => {
    setSelectedAlarm(alarm);
    setIsEditingAlarm(true);
  };

  const handleDuplicateAlarm = (alarmId: number) => {
    dispatch({
      type: `${namespace}/duplicateAlarm`,
      payload: { alarm_id: alarmId },
    });
  };

  const handleCreateAlarm = () => {
    setSelectedAlarm(null);
    setIsEditingAlarm(true);
  };

  const handleSubmitAlarm = () => {
    setIsEditingAlarm(false);
    setSelectedAlarm(null);
  };

  const handleCancelEdit = () => {
    setIsEditingAlarm(false);
    setSelectedAlarm(null);
  };

  return isEditingAlarm ? (
    <SetupAlarm
      filteredSites={filteredSites}
      alarm={selectedAlarm}
      locations={locations}
      sites={sites}
      onSubmit={handleSubmitAlarm}
      onCancel={handleCancelEdit}
      namespace={namespace}
    />
  ) : (
    <AlarmList
      app={app}
      namespace={namespace}
      onEditAlarm={handleEditAlarm}
      onDuplicateAlarm={handleDuplicateAlarm}
      onCreateAlarm={handleCreateAlarm}
    />
  );
};

export default AlarmsTabContent;
