/**
 * @generated SignedSource<<0db12bd1106c3a45b575452e76741cc5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteChannelsListFragment$data = {
  readonly Channels: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly ChannelID: number;
        readonly MonitorStatus: string | null | undefined;
        readonly Name: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"ChannelTileAndName_Channel">;
      } | null | undefined;
    } | null | undefined>;
  } | null | undefined;
  readonly Name: string | null | undefined;
  readonly SiteID: number;
  readonly " $fragmentType": "SiteChannelsListFragment";
};
export type SiteChannelsListFragment$key = {
  readonly " $data"?: SiteChannelsListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SiteChannelsListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SiteChannelsListFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "SiteID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "sort",
          "value": [
            "NAME_ASC"
          ]
        }
      ],
      "concreteType": "ChannelConnection",
      "kind": "LinkedField",
      "name": "Channels",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChannelEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Channel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ChannelID",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "MonitorStatus",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ChannelTileAndName_Channel"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "Channels(sort:[\"NAME_ASC\"])"
    }
  ],
  "type": "Site",
  "abstractKey": null
};
})();

(node as any).hash = "522c1ceb8c89d2ed6825737f30a34a15";

export default node;
