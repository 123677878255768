import { DFConfigKeys } from '@/dfConfigKeys';
import { useCustomerProfileValue } from '@/utils/hooks';
import _ from 'lodash';
import { ReactElement, useEffect, useRef, useState } from 'react';

//Minimum width for video streaming, tiles below this will use image streaming
const WIDTH_THRESHOLD = 400;

const ComponentType = {
  VIDEO: 'video',
  IMAGE: 'image',
} as const;

interface AdaptiveChannelStreamProps {
  videoComponent: ReactElement;
  imageComponent: ReactElement;
  force?: (typeof ComponentType)[keyof typeof ComponentType];
}

const AdaptiveChannelStream = ({
  videoComponent,
  imageComponent,
  force,
}: AdaptiveChannelStreamProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  //Check if feature has been rolled out to the customer
  const adaptiveStreamingConfigVal = useCustomerProfileValue(
    DFConfigKeys.fe_video_quality_selection,
  );
  const adaptiveStreamingEnabled = !!_.get(
    adaptiveStreamingConfigVal,
    'enabled',
    false,
  );

  useEffect(() => {
    const measureWidth = () => {
      if (divRef.current) {
        setWidth(divRef.current.offsetWidth);
      }
    };

    measureWidth();

    const observer = new ResizeObserver(measureWidth);
    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const renderContent = () => {
    if (force === ComponentType.IMAGE) {
      return imageComponent;
    } else if (force === ComponentType.VIDEO) {
      return videoComponent;
    } else {
      return width < WIDTH_THRESHOLD && adaptiveStreamingEnabled
        ? imageComponent
        : videoComponent;
    }
  };

  return (
    <div ref={divRef} style={{ width: '100%', height: '100%' }}>
      {renderContent()}
    </div>
  );
};

export default AdaptiveChannelStream;
export { ComponentType };
