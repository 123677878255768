import ContextualCombobox from '@/components/ContextualCombobox';
import { Contexts } from '@/components/ContextualCombobox/constants';
import { Button, Form, Input, Modal } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useFleetManagerContext } from '../FleetManagerContext';
import { useUpdateSiteStatusMutation } from '../mutations/UpdateSiteStatusMutation';
import { UpdateSiteStatusMutation_site$key } from '../mutations/__generated__/UpdateSiteStatusMutation_site.graphql';
import styles from './style.less';
import { FleetStatusSidebarProps } from './types';
import {
  FleetStatusSidebar_SiteNodeQuery,
  FleetStatusSidebar_SiteNodeQuery$data,
} from './__generated__/FleetStatusSidebar_SiteNodeQuery.graphql';

const siteNodeQuery = graphql`
  query FleetStatusSidebar_SiteNodeQuery($siteId: ID!) {
    node(id: $siteId) {
      ... on Site {
        id
        SiteID
        Name
        Status
        StatusOptionID
        StatusNotes
        Issues
        IssuesOptionID
        IssueNotes
        HasStatusNotes
        HasIssueNotes
        ...UpdateSiteStatusMutation_site
      }
    }
  }
`;

const FleetStatusSidebar: React.FC<FleetStatusSidebarProps> = ({
  siteId,
  visible,
  onClose,
}) => {
  const [isEditingStatusNotes, setIsEditingStatusNotes] = useState(false);
  const [isEditingIssueNotes, setIsEditingIssueNotes] = useState(false);
  const [form] = Form.useForm();
  const { customerId, handleShowInRegistry } = useFleetManagerContext();
  const siteData = useLazyLoadQuery<FleetStatusSidebar_SiteNodeQuery>(
    siteNodeQuery,
    {
      siteId,
    },
  );
  const site = siteData?.node;
  const { isInFlight, updateSiteStatus } = useUpdateSiteStatusMutation(
    site as UpdateSiteStatusMutation_site$key,
  );

  const handleSubmit = useCallback(
    (values = form.getFieldsValue()) => {
      updateSiteStatus(
        {
          ...values,
          siteId: site?.SiteID,
        },
        () => {
          form.setFieldsValue(values);
          setIsEditingStatusNotes(false);
          setIsEditingIssueNotes(false);
        },
      );
    },
    [form, updateSiteStatus, site?.SiteID, customerId],
  );

  const handleDropdownChange = useCallback(
    (field: string, value: string | number | undefined, isNew: boolean) => {
      const newValues = {
        ...form.getFieldsValue(),
        [field + 'OptionText']: isNew ? value : undefined,
        [field + 'OptionId']: isNew ? undefined : value,
      };
      form.setFieldsValue({
        ...form.getFieldsValue(),
        [field + 'OptionText']: isNew ? value : undefined,
        [field + 'OptionId']: value,
      });
      updateSiteStatus({
        ...newValues,
        siteId: site?.SiteID,
      });
    },
    [form, updateSiteStatus],
  );

  const handleShowInRegistryClick = useCallback(() => {
    handleShowInRegistry(site?.SiteID);
    onClose();
  }, [site?.SiteID, handleShowInRegistry, onClose]);

  const getFormValuesFromSite = (
    siteInfo: FleetStatusSidebar_SiteNodeQuery$data['node'],
  ) => {
    return {
      issuesOptionId: siteInfo?.IssuesOptionID,
      issueNotes: siteInfo?.IssueNotes,
      issuesOptionText: siteInfo?.Issues,
      statusNotes: siteInfo?.StatusNotes,
      statusOptionId: siteInfo?.StatusOptionID,
      statusOptionText: siteInfo?.Status,
    };
  };

  const initialValues = useMemo(() => {
    return getFormValuesFromSite(site);
  }, [site]);

  useEffect(() => {
    form.setFieldsValue(getFormValuesFromSite(site));
  }, [site]);

  const statusOptionText = Form.useWatch('statusOptionText', form);
  const issuesOptionText = Form.useWatch('issuesOptionText', form);

  return (
    <Modal
      title={<span>Details: {site?.Name}</span>}
      open={visible}
      onCancel={onClose}
      width={400}
      className={styles.modal}
      footer={null}>
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <div className={styles.formHeader}>
          <Button onClick={handleShowInRegistryClick}>Show in Registry</Button>
        </div>

        <div className={styles.fieldHeader}>
          <div className={styles.fieldLabel}>Status:</div>
          <Form.Item name="statusOptionId" className={styles.inlineInput}>
            <ContextualCombobox
              context={Contexts.fm_site_status}
              selectedLabel={statusOptionText}
              onChange={(value, isNew) => {
                handleDropdownChange('status', value, isNew);
              }}
            />
          </Form.Item>
          <Form.Item name="statusOptionText" hidden>
            <Input />
          </Form.Item>
        </div>

        <div className={styles.fieldHeader}>
          <div className={styles.fieldLabel}>Status Notes</div>
          {!isEditingStatusNotes ? (
            <Button type="link" onClick={() => setIsEditingStatusNotes(true)}>
              Edit
            </Button>
          ) : (
            <div className={styles.actionButtons}>
              <Button
                type="link"
                onClick={() => handleSubmit(form.getFieldsValue())}
                loading={isInFlight}>
                Save
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setIsEditingStatusNotes(false);
                  form.setFieldsValue({ statusNotes: site?.StatusNotes });
                }}>
                Cancel
              </Button>
            </div>
          )}
        </div>
        <Form.Item name="statusNotes" className={styles.markdownField}>
          {isEditingStatusNotes ? (
            <Input.TextArea
              className={styles.markdownInput}
              rows={6}
              placeholder="Enter status notes in markdown format..."
            />
          ) : (
            <div
              className={classNames(
                'markdown-content',
                styles.markdownDisplay,
              )}>
              <ReactMarkdown>{site?.StatusNotes || ''}</ReactMarkdown>
            </div>
          )}
        </Form.Item>

        <div className={styles.fieldHeader}>
          <div className={styles.fieldLabel}>Issue:</div>
          <Form.Item name="issuesOptionId" className={styles.inlineInput}>
            <ContextualCombobox
              context={Contexts.fm_site_issues}
              selectedLabel={issuesOptionText}
              onChange={(value, isNew) =>
                handleDropdownChange('issues', value, isNew)
              }
            />
          </Form.Item>
          <Form.Item name="issuesOptionText" hidden>
            <Input />
          </Form.Item>
        </div>

        <div className={styles.fieldHeader}>
          <div className={styles.fieldLabel}>Issue Notes</div>
          {!isEditingIssueNotes ? (
            <Button type="link" onClick={() => setIsEditingIssueNotes(true)}>
              Edit
            </Button>
          ) : (
            <div className={styles.actionButtons}>
              <Button
                type="link"
                onClick={() => handleSubmit(form.getFieldsValue())}
                loading={isInFlight}>
                Save
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setIsEditingIssueNotes(false);
                  form.setFieldsValue({ issueNotes: site?.IssueNotes });
                }}>
                Cancel
              </Button>
            </div>
          )}
        </div>
        <Form.Item name="issueNotes" className={styles.markdownField}>
          {isEditingIssueNotes ? (
            <Input.TextArea
              className={styles.markdownInput}
              rows={6}
              placeholder="Enter issue notes in markdown format..."
            />
          ) : (
            <div
              className={classNames(
                'markdown-content',
                styles.markdownDisplay,
              )}>
              <ReactMarkdown>{site?.IssueNotes || ''}</ReactMarkdown>
            </div>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FleetStatusSidebar;
