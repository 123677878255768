/**
 * @generated SignedSource<<56e0e7af3017c85e35cbf96e7298f23f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type activityLog_BurglarAlarmsQuery$variables = {
  app_id: number;
  customer_id: number;
};
export type activityLog_BurglarAlarmsQuery$data = {
  readonly burglarAlarmRules: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly AlarmID: number;
        readonly Name: string | null | undefined;
      } | null | undefined;
    } | null | undefined>;
  } | null | undefined;
};
export type activityLog_BurglarAlarmsQuery = {
  response: activityLog_BurglarAlarmsQuery$data;
  variables: activityLog_BurglarAlarmsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "app_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customer_id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "app_id"
  },
  {
    "kind": "Variable",
    "name": "customerId",
    "variableName": "customer_id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "AlarmID",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "activityLog_BurglarAlarmsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BurglarAlarmRulesConnection",
        "kind": "LinkedField",
        "name": "burglarAlarmRules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BurglarAlarmRulesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BurglarAlarmRules",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "activityLog_BurglarAlarmsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BurglarAlarmRulesConnection",
        "kind": "LinkedField",
        "name": "burglarAlarmRules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BurglarAlarmRulesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BurglarAlarmRules",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1064a0da6d006926ae5a927d87c3a76b",
    "id": null,
    "metadata": {},
    "name": "activityLog_BurglarAlarmsQuery",
    "operationKind": "query",
    "text": "query activityLog_BurglarAlarmsQuery(\n  $app_id: Int!\n  $customer_id: Int!\n) {\n  burglarAlarmRules(appId: $app_id, customerId: $customer_id) {\n    edges {\n      node {\n        AlarmID\n        Name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dead02b764927e95d3a58fcba2d0c48e";

export default node;
