/**
 * @generated SignedSource<<c0afc921d60e734ef377db04fcf83f87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MonitorQueries_ChannelsByIds_Query$variables = {
  app_id: number;
  customer_id: number;
  filter_channel_ids: ReadonlyArray<number>;
};
export type MonitorQueries_ChannelsByIds_Query$data = {
  readonly channels: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly LatestMedia: string | null | undefined;
        readonly Project: {
          readonly Site: {
            readonly SiteID: number;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined>;
  } | null | undefined;
};
export type MonitorQueries_ChannelsByIds_Query = {
  response: MonitorQueries_ChannelsByIds_Query$data;
  variables: MonitorQueries_ChannelsByIds_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "app_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customer_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter_channel_ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "app_id"
  },
  {
    "kind": "Variable",
    "name": "customerId",
    "variableName": "customer_id"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "ChannelIDIn",
        "variableName": "filter_channel_ids"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LatestMedia",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SiteID",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MonitorQueries_ChannelsByIds_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChannelConnection",
        "kind": "LinkedField",
        "name": "channels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChannelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Channel",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "Project",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Site",
                        "kind": "LinkedField",
                        "name": "Site",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MonitorQueries_ChannelsByIds_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChannelConnection",
        "kind": "LinkedField",
        "name": "channels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChannelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Channel",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "Project",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Site",
                        "kind": "LinkedField",
                        "name": "Site",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f0ae456c7098fab29470ffe67b3910a3",
    "id": null,
    "metadata": {},
    "name": "MonitorQueries_ChannelsByIds_Query",
    "operationKind": "query",
    "text": "query MonitorQueries_ChannelsByIds_Query(\n  $app_id: Int!\n  $customer_id: Int!\n  $filter_channel_ids: [Int!]!\n) {\n  channels(appId: $app_id, customerId: $customer_id, filters: {ChannelIDIn: $filter_channel_ids}) {\n    edges {\n      node {\n        LatestMedia\n        Project {\n          Site {\n            SiteID\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8594a0203893a5a0ddc204a8e9da1816";

export default node;
