import { Values } from '@/types/utils';
import { getCurrentCustomerID } from '@/utils/utils';
import { commitLocalUpdate, Environment } from 'relay-runtime';
import { Contexts } from './constants';

export function updateGlobalOptionList(
  environment: Environment,
  context: Values<typeof Contexts>,
  optionId: number,
  optionText: string,
) {
  try {
    commitLocalUpdate(environment, (store) => {
      const root = store.getRoot();
      const contextOptions = root.getLinkedRecords('contextOptions', {
        customerId: getCurrentCustomerID(),
      });

      if (!contextOptions) {
        throw new Error('contextOptions not found in the relay store');
      }

      const contextRecord = contextOptions.find(
        (record) => record.getValue('context') === context,
      );

      if (!contextRecord) {
        throw new Error('Unrecognized context');
      }

      const options = contextRecord.getLinkedRecords('options') || [];
      const optionExists = options.some(
        (option) =>
          option.getValue('OptionID') === optionId &&
          option.getValue('OptionText') === optionText,
      );

      if (!optionExists) {
        const newOption = store.create(`option:${optionId}`, 'Option');
        newOption.setValue(optionId, 'OptionID');
        newOption.setValue(optionText, 'OptionText');
        contextRecord.setLinkedRecords([...options, newOption], 'options');
      }
    });
  } catch (e) {
    console.log('Failed to update local store: ', e);
  }
}
