/**
 * @generated SignedSource<<6e70317069b6975968bbb69ebbfca010>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type FleetStatus_sites$data = {
  readonly edges: ReadonlyArray<
    | {
        readonly node:
          | {
              readonly ExternalID: string | null | undefined;
              readonly HasIssueNotes: boolean | null | undefined;
              readonly HasStatusNotes: boolean | null | undefined;
              readonly Issues: string | null | undefined;
              readonly Labels:
                | ReadonlyArray<
                    | {
                        readonly Color: string | null | undefined;
                        readonly LabelID: number;
                        readonly Name: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              readonly Name: string | null | undefined;
              readonly Status: string | null | undefined;
              readonly id: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
  readonly ' $fragmentType': 'FleetStatus_sites';
};
export type FleetStatus_sites$key = {
  readonly ' $data'?: FleetStatus_sites$data;
  readonly ' $fragmentSpreads': FragmentRefs<'FleetStatus_sites'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'Name',
    storageKey: null,
  };
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'FleetStatus_sites',
    selections: [
      {
        alias: null,
        args: null,
        concreteType: 'SiteEdge',
        kind: 'LinkedField',
        name: 'edges',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'Site',
            kind: 'LinkedField',
            name: 'node',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'ExternalID',
                storageKey: null,
              },
              v0 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'Status',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'HasStatusNotes',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'Issues',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'HasIssueNotes',
                storageKey: null,
              },
              {
                alias: null,
                args: null,
                concreteType: 'Label',
                kind: 'LinkedField',
                name: 'Labels',
                plural: true,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'LabelID',
                    storageKey: null,
                  },
                  v0 /*: any*/,
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'Color',
                    storageKey: null,
                  },
                ],
                storageKey: null,
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'SiteConnection',
    abstractKey: null,
  };
})();

(node as any).hash = '4734f4f5656dd39b9e71aec828e476f2';

export default node;
