import { Select } from 'antd';
import { TileLayout, TileLayoutLabels } from './constants';
import { TileLayoutType } from './types';

const { Option } = Select;

interface TileLayoutSelectorProps {
  value?: TileLayoutType;
  onChange: (value: TileLayoutType) => void;
}

const Options = [
  TileLayout.FIT,
  TileLayout.ONE_COL,
  TileLayout.TWO_COL,
  TileLayout.THREE_COL,
  TileLayout.FIVE_COL,
  TileLayout.TEN_COL,
];

const TileLayoutSelector = ({
  value = TileLayout.FIT,
  onChange,
}: TileLayoutSelectorProps) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select Layout"
      style={{ minWidth: '180px' }}>
      {Options.map((layout) => (
        <Option key={layout} value={layout}>
          {TileLayoutLabels[layout]}
        </Option>
      ))}
    </Select>
  );
};

export { TileLayoutSelector };
