/**
 * @generated SignedSource<<c001422bc8615ffff46325b3e2bdc1d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, FragmentRefs } from 'relay-runtime';
export type MonitorSceneSortEnum =
  | 'NAME_ASC'
  | 'NAME_DESC'
  | 'TIMESTAMP_ASC'
  | 'TIMESTAMP_DESC'
  | '%future added value';
export type TeamScenesPaginationQuery$variables = {
  appId?: number | null | undefined;
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  customerId?: number | null | undefined;
  id: string;
  sort?: MonitorSceneSortEnum | null | undefined;
};
export type TeamScenesPaginationQuery$data = {
  readonly node:
    | {
        readonly ' $fragmentSpreads': FragmentRefs<'TeamScenesFragment'>;
      }
    | null
    | undefined;
};
export type TeamScenesPaginationQuery = {
  response: TeamScenesPaginationQuery$data;
  variables: TeamScenesPaginationQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'appId',
    },
    v1 = {
      defaultValue: 10,
      kind: 'LocalArgument',
      name: 'count',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'cursor',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'customerId',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v5 = {
      defaultValue: 'TIMESTAMP_DESC',
      kind: 'LocalArgument',
      name: 'sort',
    },
    v6 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v7 = {
      kind: 'Variable',
      name: 'appId',
      variableName: 'appId',
    },
    v8 = {
      kind: 'Variable',
      name: 'customerId',
      variableName: 'customerId',
    },
    v9 = {
      kind: 'Variable',
      name: 'sort',
      variableName: 'sort',
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v12 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'cursor',
      },
      v7 /*: any*/,
      v8 /*: any*/,
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'count',
      },
      v9 /*: any*/,
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'TeamScenesPaginationQuery',
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              args: [
                v7 /*: any*/,
                {
                  kind: 'Variable',
                  name: 'count',
                  variableName: 'count',
                },
                {
                  kind: 'Variable',
                  name: 'cursor',
                  variableName: 'cursor',
                },
                v8 /*: any*/,
                v9 /*: any*/,
              ],
              kind: 'FragmentSpread',
              name: 'TeamScenesFragment',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v5 /*: any*/,
        v4 /*: any*/,
      ],
      kind: 'Operation',
      name: 'TeamScenesPaginationQuery',
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            v10 /*: any*/,
            v11 /*: any*/,
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: v12 /*: any*/,
                  concreteType: 'MonitorSceneConnection',
                  kind: 'LinkedField',
                  name: 'scenes',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'MonitorSceneEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'MonitorScene',
                          kind: 'LinkedField',
                          name: 'node',
                          plural: false,
                          selections: [
                            v11 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'name',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'isFavorite',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'timestamp',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'anchorTimeStamp',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'createdBy',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'isSharedWithTeam',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              concreteType: 'Channel',
                              kind: 'LinkedField',
                              name: 'channels',
                              plural: true,
                              selections: [
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'ChannelID',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'Name',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'MonitorStatus',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'LatestMedia',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  concreteType: 'Project',
                                  kind: 'LinkedField',
                                  name: 'Project',
                                  plural: false,
                                  selections: [
                                    {
                                      alias: null,
                                      args: null,
                                      concreteType: 'Site',
                                      kind: 'LinkedField',
                                      name: 'Site',
                                      plural: false,
                                      selections: [
                                        {
                                          alias: null,
                                          args: null,
                                          kind: 'ScalarField',
                                          name: 'SiteID',
                                          storageKey: null,
                                        },
                                        v11 /*: any*/,
                                      ],
                                      storageKey: null,
                                    },
                                    v11 /*: any*/,
                                  ],
                                  storageKey: null,
                                },
                                v11 /*: any*/,
                              ],
                              storageKey: null,
                            },
                            v10 /*: any*/,
                          ],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'cursor',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'PageInfo',
                      kind: 'LinkedField',
                      name: 'pageInfo',
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'startCursor',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'endCursor',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'hasNextPage',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'hasPreviousPage',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                    {
                      kind: 'ClientExtension',
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: '__id',
                          storageKey: null,
                        },
                      ],
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: v12 /*: any*/,
                  filters: ['sort', 'appId', 'customerId'],
                  handle: 'connection',
                  key: 'TeamScenesConnection_scenes',
                  kind: 'LinkedHandle',
                  name: 'scenes',
                },
              ],
              type: 'MonitorTeamScenesData',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '7df1dce4c99d4f1a57fd2c25002159d3',
      id: null,
      metadata: {},
      name: 'TeamScenesPaginationQuery',
      operationKind: 'query',
      text: 'query TeamScenesPaginationQuery(\n  $appId: Int\n  $count: Int = 10\n  $cursor: String\n  $customerId: Int\n  $sort: MonitorSceneSortEnum = TIMESTAMP_DESC\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...TeamScenesFragment_17HIme\n    id\n  }\n}\n\nfragment ChannelTileAndName_Channel on Channel {\n  Name\n  ChannelID\n  MonitorStatus\n  LatestMedia\n  Project {\n    Site {\n      SiteID\n      id\n    }\n    id\n  }\n}\n\nfragment SceneFragment on MonitorScene {\n  id\n  name\n  isFavorite\n  timestamp\n  anchorTimeStamp\n  createdBy\n  isSharedWithTeam\n  channels {\n    ChannelID\n    Name\n    ...ChannelTileAndName_Channel\n    ...TileThumbnailChannelsMedia\n    id\n  }\n}\n\nfragment TeamScenesFragment_17HIme on MonitorTeamScenesData {\n  scenes(first: $count, after: $cursor, sort: $sort, appId: $appId, customerId: $customerId) {\n    edges {\n      node {\n        id\n        ...SceneFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n\nfragment TileThumbnailChannelsMedia on Channel {\n  LatestMedia\n}\n',
    },
  };
})();

(node as any).hash = 'dd54e7d74960f55f4b49b1586ee99dea';

export default node;
