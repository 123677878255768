import LoadingSpinner from '@/components/LoadingSpinner';
import {
  doNotificationOp,
  notificationAppID,
  notificationOps,
} from '@/utils/notifications';
import { DEFAULT_ROLE, INTERNAL_ROLES, ROLE_INFO } from '@/utils/roles';
import {
  dispatchWithFeedback,
  getFlexibleDateFormat,
  isInternalUser,
} from '@/utils/utils';
import {
  ClockCircleOutlined,
  CloseCircleOutlined,
  DeleteFilled,
  DeleteOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import { Button, Divider, Form, Input, Select, Switch } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { history, Link } from 'umi';

import tstyles from '@/components/InfoSidebar/style.less';
import { ALARM_INCIDENT_PRIORITIES } from '@/pages/apps/app/AlarmApp/constants';
import styles from './datatable.less';

export const getFilterableColumn = (key, name, responsive) => ({
  key,
  dataIndex: key,
  ellipsis: true,
  onFilter: (value, record) => record[key]?.toString().startsWith(value),
  responsive,
  title: name,
});

export const getBasicColumn = (key, name, responsive) => ({
  key,
  dataIndex: key,
  ellipsis: true,
  responsive,
  title: name,
});

const notificationActionOps = {
  status: () => {},
  priority: ({ dispatch, target, container }, value) => {
    doNotificationOp(
      dispatch,
      notificationOps.setPriorityAlert,
      {
        alert_id: target.id,
        value,
      },
      { showFeedbackOnFailureOnly: true },
    );
    target.priority = value;
    if (container.sidebarTargetUpdated)
      container.sidebarTargetUpdated(target, 'priority');
  },
  addNote: ({ dispatch, formRef, target, container, currentUser }, values) => {
    doNotificationOp(
      dispatch,
      notificationOps.saveNoteAlert,
      {
        alert_id: target.id,
        text: values.note,
      },
      { showFeedbackOnFailureOnly: true },
    );
    if (formRef.current) {
      formRef.current.resetFields();
    }
    if (!target.log) {
      target.log = [];
    }
    target.log.push({
      action: 'note',
      text: values.note,
      timestamp: Math.floor(Date.now() / 1000),
      user: {
        id: currentUser.UserID,
        name: currentUser.FirstName,
      },
    });
    if (container.sidebarTargetUpdated) container.sidebarTargetUpdated(target);
  },
  deleteNote: ({ dispatch, formRef, target, container }, index) => {
    doNotificationOp(
      dispatch,
      notificationOps.deleteNoteAlert,
      {
        alert_id: target.id,
        note_index: index,
      },
      { showFeedbackOnFailureOnly: true },
    );
    if (formRef.current) {
      formRef.current.resetFields();
    }
    target.log.splice(index, 1);
    if (container.sidebarTargetUpdated) container.sidebarTargetUpdated(target);
  },
  assignToMe: ({ dispatch, assignmentRef, currentUser, target, container }) => {
    doNotificationOp(
      dispatch,
      notificationOps.assignToMeAlert,
      {
        alert_id: target.id,
      },
      { showFeedbackOnFailureOnly: true },
    );
    assignmentRef.current?.setFieldsValue({
      assignee: currentUser.UserID,
    });
    target.statusOwnerID = currentUser.UserID;
    target.statusOwnerName = `${currentUser.FirstName} ${currentUser.LastName}`;
    if (container.sidebarTargetUpdated) container.sidebarTargetUpdated(target);
  },
  reassign: ({ dispatch, target, container }, value, user) => {
    doNotificationOp(
      dispatch,
      notificationOps.reassignToUserAlert,
      {
        alert_id: target.id,
        owner_id: value,
      },
      { showFeedbackOnFailureOnly: true },
    );
    target.statusOwnerID = value;
    target.statusOwnerName = user
      ? `${user.User.FirstName} ${user.User.LastName}`
      : value;
    if (container.sidebarTargetUpdated) container.sidebarTargetUpdated(target);
  },
  markUnread: ({ dispatch, target, container }) => {
    doNotificationOp(
      dispatch,
      notificationOps.setReadStateAlert,
      {
        alert_id: target.id,
        read_state: 'unread',
      },
      { showFeedbackOnFailureOnly: true },
    );
    target.readStatus = 'unread';
    if (container.sidebarTargetUpdated) container.sidebarTargetUpdated(target);
  },
};

export const convertInvestigationToDatasourceRow = (accounts, inv) => {
  let ret = {
    ...inv,
    // bring the json config blob up one level
    ...inv.TimelineConfig,
    id: inv.InvestigationID,
  };
  let ownerID = _.get(ret, 'TimelineConfig.statusOwnerID');
  if (ownerID) {
    let user = _.find(accounts.users, (obj) => obj.User.UserID === ownerID);
    ret.statusOwnerName = user
      ? `${user.User.FirstName} ${user.User.LastName}`
      : '-';
  }
  return ret;
};

const saveTimelineConfig = (
  { container, accounts, dispatch, target },
  toUpdate,
) => {
  let config = _.cloneDeep(target.TimelineConfig || {});
  return dispatchWithFeedback(
    dispatch,
    'Update',
    {
      type: 'investigations/updateInvestigationTimelineConfig',
      investigationID: target.InvestigationID,
      payload: {
        ...config,
        ...toUpdate,
      },
    },
    true,
  ).then((inv) => {
    if (container.sidebarTargetUpdated) {
      container.sidebarTargetUpdated(
        convertInvestigationToDatasourceRow(accounts, inv),
      );
    }
  });
};

const investigationActionOps = {
  status: (opContext, value) => {
    saveTimelineConfig(opContext, { status: value });
  },
  priority: (opContext, value) => {
    saveTimelineConfig(opContext, { priority: value });
  },
  addNote: (opContext, values) => {
    let config = _.cloneDeep(opContext.target.TimelineConfig || {});
    let log = _.get(config, 'log', []);
    log.push({
      action: 'note',
      text: values.note,
      annotationTime: values.annotationTime,
      timestamp: Math.floor(Date.now() / 1000),
      user: {
        id: opContext.currentUser.UserID,
        name: opContext.currentUser.FirstName,
      },
    });
    saveTimelineConfig(opContext, { log });

    if (opContext.formRef.current) {
      opContext.formRef.current.resetFields();
    }
  },
  deleteNote: (opContext, timestamp) => {
    const config = _.cloneDeep(opContext.target.TimelineConfig || {});
    const log = _.get(config, 'log', []).filter(
      (x) => x.timestamp !== timestamp,
    );
    saveTimelineConfig(opContext, { log });
  },
  assignToMe: (opContext) => {
    saveTimelineConfig(opContext, {
      statusOwnerID: opContext.currentUser.UserID,
    });
    opContext.assignmentRef.current?.setFieldsValue({
      assignee: opContext.currentUser.UserID,
    });
  },
  reassign: (opContext, value) => {
    saveTimelineConfig(opContext, { statusOwnerID: value });
    opContext.assignmentRef.current?.setFieldsValue({
      assignee: value,
    });
  },
  markUnread: () => {},
};

export const getSidebarActions = (
  container,
  target,
  type = 'notifications',
) => {
  if (!target) {
    return null;
  }
  const { dispatch, accounts, currentUser, loadingDoAppOp, loadingSaveConfig } =
    container.props;
  // NOTE: the following container accessors are used below, if needed:
  // for setting state for copying vars etc
  //   container.setState and container.state
  // used to sync comments with timeline/timestamps
  //   container.setTimelineTime and container.getTimelineTime
  // called if the selectedAlert object is updated in the sidebar.
  // the caller can use this info to refresh the view if they desire
  //   container.sidebarTargetUpdated;

  if (!accounts || !currentUser) {
    throw new Error('Missing props', accounts, currentUser);
  }

  if (!container.formRef) {
    container.formRef = React.createRef();
  }
  let formRef = container.formRef;

  const assignmentRef = React.createRef();

  const opContext = {
    dispatch,
    accounts,
    currentUser,

    container,

    formRef,
    assignmentRef,

    target,
  };

  let opsProvider, permalink, loaderVar;

  if (type === 'notifications') {
    let appID = container.props.notificationAppID || notificationAppID;
    opsProvider = notificationActionOps;
    permalink = `${BASE_URL}/apps/${appID}?alertID=${target.id}`;
    loaderVar = loadingDoAppOp;
  } else if (type === 'investigations') {
    opsProvider = investigationActionOps;
    permalink = `${BASE_URL}/investigations/${target.id}`;
    loaderVar = loadingSaveConfig;
  }

  let people = accounts.users
    .filter((x) => {
      if (
        !isInternalUser(currentUser) &&
        _.endsWith(x.email, 'dragonfruit.ai')
      ) {
        return false;
      }
      let role =
        _.get(_.intersection(x.Roles, Object.keys(ROLE_INFO)), '0') ||
        DEFAULT_ROLE;

      if (!role || INTERNAL_ROLES.indexOf(role) !== -1) {
        return false;
      }
      return true;
    })
    .sort((a, b) =>
      `${a.User.FirstName}/${a.User.LastName}`.localeCompare(
        `${b.User.FirstName}/${b.User.LastName}`,
      ),
    )
    .map((x) => ({
      label: (
        // `${x.User.FirstName} ${x.User.LastName} ${(x.User.UserID === currentUser.UserID) ? ' (me)' : ''}`,
        <div title={x.User.Email} style={{ overflow: 'hidden' }}>
          <span>
            {x.User.FirstName} {x.User.LastName}
          </span>
          {x.User.UserID === currentUser.UserID && (
            <span style={{ color: '#8E8E95' }}> (me)</span>
          )}
        </div>
      ),
      key: x.User.UserID,
      value: x.User.UserID,
    }));

  return (
    <div style={{ marginBottom: '8px', position: 'relative' }}>
      {loaderVar && (
        <div className={styles['loading-ctn']}>
          <LoadingSpinner />
        </div>
      )}

      <Form layout="vertical" ref={formRef}>
        {type === 'investigations' && (
          <>
            <Divider plain="true" orientation="left" orientationMargin="0">
              Status
            </Divider>
            <Form.Item label="Status" noStyle>
              <Switch
                checkedChildren={
                  <>
                    <InboxOutlined /> Open
                  </>
                }
                unCheckedChildren={
                  <>
                    <DeleteOutlined /> Closed
                  </>
                }
                checked={_.get(target, 'status') !== false}
                onChange={(value) => opsProvider.status(opContext, value)}
              />
            </Form.Item>
          </>
        )}
        <Divider plain="true" orientation="left" orientationMargin="0">
          Priority
        </Divider>
        <Form.Item label="Priority" noStyle>
          <Select
            value={_.get(target, 'priority', 3)}
            onChange={(value) => opsProvider.priority(opContext, value)}
            style={{ width: '150px' }}>
            {ALARM_INCIDENT_PRIORITIES.map((n) => (
              <Select.Option key={n} value={n}>
                {n}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div style={{ marginBottom: '16px' }}>
          <Divider plain="true" orientation="left" orientationMargin="0">
            Comments
          </Divider>
          {_.get(target, 'log', [])
            .filter((e) => e.action === 'note')
            .map((t, i) => (
              <div className={styles['notes']} key={i}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <div className={styles['by']}>
                    {_.get(t, 'user.name', 'Unknown')} said:
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {_.get(t, 'timestamp') && (
                      <div className={styles['timestamp']}>
                        {getFlexibleDateFormat(moment.unix(t.timestamp))}
                      </div>
                    )}
                    <div
                      className={styles['delete']}
                      onClick={() => opsProvider.deleteNote(opContext, i)}>
                      <DeleteFilled />
                    </div>
                  </div>
                </div>
                <div className={styles['text']}>
                  <div>{t.text}</div>
                  {t.annotationTime && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 4,
                        fontSize: 12,
                      }}>
                      <ClockCircleOutlined />
                      {container.setTimelineTime ? (
                        <div style={{ margin: '0 4px' }}>Jump to:</div>
                      ) : (
                        <div style={{ margin: '0 4px' }}>At:</div>
                      )}
                      <div
                        className="df-link"
                        onClick={() => {
                          if (container.setTimelineTime) {
                            container.setTimelineTime(
                              t.annotationTime.timestamp,
                            );
                          } else {
                            let url = `/investigations/${target.InvestigationID}/timeline`;
                            history.push(url);
                          }
                        }}>
                        {getFlexibleDateFormat(
                          1000 * t.annotationTime.timestamp,
                          true,
                          false,
                          t.annotationTime?.timezone,
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          {container.state.noteOpen ? (
            <>
              <Form.Item name="note" noStyle>
                <Input.TextArea
                  placeholder="Add Comment"
                  autoSize={{ minRows: 3, maxRows: 8 }}
                />
              </Form.Item>
              {container.getTimelineTime ? (
                container.state.annotationTime ? (
                  <div className={styles['new-note-time']}>
                    <div style={{ display: 'flex' }}>
                      <div>Annotated:</div>
                      &nbsp;
                      <div style={{ fontWeight: 500 }}>
                        {getFlexibleDateFormat(
                          1000 * container.state.annotationTime?.timestamp,
                          true,
                          false,
                          container.state.annotationTime?.timezone,
                        )}
                      </div>
                    </div>
                    <div
                      onClick={() =>
                        container.setState({ annotationTime: null })
                      }>
                      <CloseCircleOutlined />
                    </div>
                  </div>
                ) : (
                  <Button
                    onClick={() =>
                      container.setState({
                        annotationTime: container.getTimelineTime(),
                      })
                    }
                    icon={<ClockCircleOutlined />}
                    size="small"
                    type="link">
                    Add Time Annotation
                  </Button>
                )
              ) : target.InvestigationID ? (
                <div className={styles['new-note-time']}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ClockCircleOutlined />
                    &nbsp;
                    <div>Use</div>
                    &nbsp;
                    <Link
                      to={`/investigations/${target.InvestigationID}/timeline`}>
                      Timeline
                    </Link>
                    &nbsp;
                    <div>to add time annotations.</div>
                  </div>
                </div>
              ) : null}
              <div style={{ marginTop: 8 }}>
                <Button
                  size="small"
                  onClick={() => {
                    formRef.current.validateFields().then(
                      (values) => {
                        // add annotation info as well
                        let toSave = {
                          ...values,
                          annotationTime: container.state.annotationTime,
                        };
                        opsProvider.addNote(opContext, toSave);
                        container.setState({ annotationTime: null });
                      },
                      (err: any) => console.log('err', err),
                    );
                  }}>
                  Save
                </Button>
                &nbsp;
                <Button
                  size="small"
                  onClick={() =>
                    container.setState({
                      noteOpen: false,
                      annotationTime: null,
                    })
                  }>
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <Button
              onClick={() => container.setState({ noteOpen: true })}
              size="small"
              type="link">
              Add Comment
            </Button>
          )}
        </div>
      </Form>

      <div style={{ margin: '16px 0' }}>
        <Divider plain="true" orientation="left" orientationMargin="0">
          Assignments
        </Divider>
        <Form ref={assignmentRef} style={{ flexGrow: 1 }}>
          <div
            className={tstyles['info-block-ctn']}
            style={{ marginBottom: 0 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '16px',
              }}>
              <span style={{ marginRight: '8px', whiteSpace: 'nowrap' }}>
                Re-assign to:
              </span>
              <Form.Item noStyle>
                <Select
                  size="small"
                  style={{ minWidth: 0 }}
                  value={_.get(target, 'statusOwnerID')}
                  onChange={(value) =>
                    opsProvider.reassign(
                      opContext,
                      value,
                      _.find(accounts.users, (u) => u.User.UserID === value),
                    )
                  }
                  options={people}
                />
              </Form.Item>
            </div>
          </div>
          {_.get(target, 'statusOwnerID') !== currentUser.UserID && (
            <div
              className={tstyles['info-block-ctn']}
              style={{ marginBottom: 0 }}>
              <Button
                size="small"
                block
                onClick={() => opsProvider.assignToMe(opContext)}>
                Assign To Me
              </Button>
            </div>
          )}
        </Form>
      </div>
      {_.get(target, 'readStatus') === 'read' && (
        <div style={{ margin: '16px 0' }}>
          <Divider plain="true" orientation="left" orientationMargin="0">
            Read Status
          </Divider>
          <div
            className={tstyles['info-block-ctn']}
            style={{ marginBottom: 0 }}>
            <Button
              size="small"
              block
              onClick={() => opsProvider.markUnread(opContext)}>
              Mark Unread
            </Button>
          </div>
        </div>
      )}

      {permalink && (
        <div style={{ margin: '16px 0' }}>
          <Divider plain="true" orientation="left" orientationMargin="0">
            Permalink
          </Divider>
          <div
            className={tstyles['info-block-ctn']}
            style={{ marginBottom: 0 }}>
            <CopyToClipboard
              onCopy={() => {
                container.setState({ copying: true }, () =>
                  setTimeout(
                    () => container.setState({ copying: false }),
                    1000,
                  ),
                );
              }}
              text={permalink}>
              <Button size="small" loading={container.state.copying} block>
                {container.state.copying ? 'Copying...' : 'Copy to Clipboard'}
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      )}
    </div>
  );
};

export const getInvSidebarActions =
  (container, target, showEdit = true, showTimeline = true) =>
  () => {
    // the target needs to have the TimelineConfig and id at the right level
    target = {
      ...target,
      ...target.TimelineConfig,
      id: target.InvestigationID,
    };
    return (
      <>
        {showEdit && (
          <div style={{ marginBottom: 8 }}>
            <Link to={`/investigations/${target.InvestigationID}`}>
              <Button size="small" block>
                Edit Investigation
              </Button>
            </Link>
          </div>
        )}
        {showTimeline && (
          <div style={{ marginBottom: 8 }}>
            <Link to={`/investigations/${target.InvestigationID}/timeline`}>
              <Button size="small" block>
                Timeline
              </Button>
            </Link>
          </div>
        )}
        {getSidebarActions(container, target, 'investigations')}
      </>
    );
  };
