/**
 * @generated SignedSource<<7a202c33dea9adc0d8a6803036b7e209>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChannelSelectQuery$variables = {
  appId: number;
  customerId: number;
};
export type ChannelSelectQuery$data = {
  readonly labels: {
    readonly " $fragmentSpreads": FragmentRefs<"ChannelSelectCascaderFragment_ChannelsBySiteGroups">;
  } | null | undefined;
  readonly sites: {
    readonly " $fragmentSpreads": FragmentRefs<"ChannelSelectCascaderFragment_ChannelsBySites">;
  } | null | undefined;
};
export type ChannelSelectQuery = {
  response: ChannelSelectQuery$data;
  variables: ChannelSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customerId"
  }
],
v1 = {
  "kind": "Literal",
  "name": "sort",
  "value": [
    "NAME_ASC"
  ]
},
v2 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "appId"
  },
  {
    "kind": "Variable",
    "name": "customerId",
    "variableName": "customerId"
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Name",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SiteEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "SiteID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ChannelConnection",
            "kind": "LinkedField",
            "name": "Channels",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChannelEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Channel",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ChannelID",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "Channels(sort:[\"NAME_ASC\"])"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChannelSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SiteConnection",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChannelSelectCascaderFragment_ChannelsBySites"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "LabelConnection",
        "kind": "LinkedField",
        "name": "labels",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChannelSelectCascaderFragment_ChannelsBySiteGroups"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChannelSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SiteConnection",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "LabelConnection",
        "kind": "LinkedField",
        "name": "labels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LabelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "LabelID",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "SiteConnection",
                    "kind": "LinkedField",
                    "name": "Sites",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": "Sites(sort:[\"NAME_ASC\"])"
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8033c66ac929e7748f549cfdec7e1166",
    "id": null,
    "metadata": {},
    "name": "ChannelSelectQuery",
    "operationKind": "query",
    "text": "query ChannelSelectQuery(\n  $appId: Int!\n  $customerId: Int!\n) {\n  sites(appId: $appId, customerId: $customerId, sort: [NAME_ASC]) {\n    ...ChannelSelectCascaderFragment_ChannelsBySites\n  }\n  labels(appId: $appId, customerId: $customerId, sort: [NAME_ASC]) {\n    ...ChannelSelectCascaderFragment_ChannelsBySiteGroups\n  }\n}\n\nfragment ChannelSelectCascaderFragment_ChannelsBySiteGroups on LabelConnection {\n  edges {\n    node {\n      Name\n      LabelID\n      Sites(sort: [NAME_ASC]) {\n        edges {\n          node {\n            Name\n            SiteID\n            Channels(sort: [NAME_ASC]) {\n              edges {\n                node {\n                  ChannelID\n                  Name\n                  id\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment ChannelSelectCascaderFragment_ChannelsBySites on SiteConnection {\n  edges {\n    node {\n      Name\n      SiteID\n      Channels(sort: [NAME_ASC]) {\n        edges {\n          node {\n            ChannelID\n            Name\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a52d62c81aeca191f467cad2c2e86e65";

export default node;
