import {
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragStartEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Typography } from 'antd';
import { useState } from 'react';
import ChannelTileAndName from './ChannelTileAndName';
import SortableChannelTileWrapper from './SortableChannelTileWrapper';
import {
  ChannelGroupContainer,
  ChannelGroupHeaderContainer,
  ChannelGroupListContainer,
} from './styles';
import type { ChannelTileAndName_Channel$key } from './__generated__/ChannelTileAndName_Channel.graphql';

type SelectedChannelListProps = {
  channelRefs: ChannelTileAndName_Channel$key[];
  reorderChannel: (oldIndex: number, newIndex: number) => void;
  removeChannel: (channelID: number | number[]) => void;
  selectedChannels: number[];
};

const SelectedChannelList = ({
  selectedChannels,
  channelRefs,
  reorderChannel,
  removeChannel,
}: SelectedChannelListProps) => {
  const [activeDraggingId, setActiveDraggingId] = useState<number | null>(null);
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDeselectAll = () => {
    removeChannel(selectedChannels);
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveDraggingId(active.id as number);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = selectedChannels.indexOf(active.id as number);
      const newIndex = selectedChannels.indexOf(over.id as number);
      reorderChannel(oldIndex, newIndex);
    }
    setActiveDraggingId(null);
  };

  // @TODO - add some component here
  if (!channelRefs.length) {
    return <Typography.Text>No cameras selected</Typography.Text>;
  }
  return (
    <ChannelGroupContainer>
      <ChannelGroupHeaderContainer>
        <Typography style={{ fontWeight: 500 }}>
          {channelRefs.length} Selected
        </Typography>
        <Button
          type="link"
          style={{ padding: '0', height: '22px' }}
          onClick={handleDeselectAll}>
          Deselect all
        </Button>
      </ChannelGroupHeaderContainer>
      <ChannelGroupListContainer>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}>
          <SortableContext
            items={selectedChannels}
            strategy={verticalListSortingStrategy}>
            {selectedChannels.map((channelID, index) => (
              <SortableChannelTileWrapper id={channelID} key={channelID}>
                <ChannelTileAndName
                  key={channelID}
                  tileFragmentRef={channelRefs[index]}
                  siteName=""
                  isSelected={true}
                  onClick={(channelId) => removeChannel(channelId)}
                />
              </SortableChannelTileWrapper>
            ))}
          </SortableContext>
          <DragOverlay>
            {activeDraggingId ? (
              <ChannelTileAndName
                tileFragmentRef={
                  channelRefs[selectedChannels.indexOf(activeDraggingId)]
                }
                siteName=""
                isSelected={true}
                onClick={(channelId) => removeChannel(channelId)}
                dragging={true}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </ChannelGroupListContainer>
    </ChannelGroupContainer>
  );
};

export { SelectedChannelList };
