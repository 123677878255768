/**
 * @generated SignedSource<<0971cba759fcb292ad93144077a3dfe9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSiteStatusMutation_site$data = {
  readonly HasIssueNotes: boolean | null | undefined;
  readonly HasStatusNotes: boolean | null | undefined;
  readonly IssueNotes: string | null | undefined;
  readonly Issues: string | null | undefined;
  readonly IssuesOptionID: number | null | undefined;
  readonly SiteID: number;
  readonly Status: string | null | undefined;
  readonly StatusNotes: string | null | undefined;
  readonly StatusOptionID: number | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "UpdateSiteStatusMutation_site";
};
export type UpdateSiteStatusMutation_site$key = {
  readonly " $data"?: UpdateSiteStatusMutation_site$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateSiteStatusMutation_site">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateSiteStatusMutation_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "SiteID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "StatusNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Issues",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "IssueNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "HasStatusNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "HasIssueNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "StatusOptionID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "IssuesOptionID",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "7f7599e59f43138cd196a7aba900d90c";

export default node;
