import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { getChannelThumbnailImageUrl } from '../../utils';
import { ThumbnailBackground } from './styles';
import type { TileThumbnailChannelsMedia$key } from './__generated__/TileThumbnailChannelsMedia.graphql';

export const TileThumbnailChannelsMediaFragment = graphql`
  fragment TileThumbnailChannelsMedia on Channel @relay(plural: true) {
    LatestMedia
  }
`;

interface TileThumbnailProps {
  channelsRef: TileThumbnailChannelsMedia$key;
}

const TileThumbnail = ({ channelsRef }: TileThumbnailProps) => {
  const channelsData = useFragment(
    TileThumbnailChannelsMediaFragment,
    channelsRef,
  ).slice(0, 8);
  const channelsThumbnails =
    useMemo(
      () => channelsData.map((channel) => getChannelThumbnailImageUrl(channel)),
      [channelsData],
    ) || [];

  return (
    <>
      {channelsThumbnails.map((thumbnail, index) => (
        <ThumbnailBackground
          $url={thumbnail}
          key={index}
          $allThumbnailsCount={channelsThumbnails.length}
        />
      ))}
    </>
  );
};

export { TileThumbnail };
