import Image from '@/components/Image';
import LoadingSpinner from '@/components/LoadingSpinner';
import StreamTypeSelector from '@/components/StreamTypeSelector';
import { STREAM_TYPES } from '@/utils/utils';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';
import styles from './style.less';

type Props = any;
type State = any;

const ARCHIVE_STREAM_DURATION_SECS = 300;
const PLAYHEAD_CHANGE_SENSITIVITY_SECS = 5;

// @ts-expect-error
@connect(({ user }, { channelID }) => {
  const imageData = _.get(user, `channelImageData[${channelID}]`, {});
  return { imageData };
})
class ChannelImageStream extends React.Component<Props, State> {
  updateInterval: NodeJS.Timer;
  archiveStreamStartTime: number;
  sessionID: string | null;
  constructor(props: Props) {
    super(props);
    this.state = {
      count: 0,
      streamType: props.streamType || STREAM_TYPES.CIF_1,
    };
    this.requestImageStream = this.requestImageStream.bind(this);
    this.unrequestImageStream = this.unrequestImageStream.bind(this);
    this.archiveStreamStartTime = 0;
    this.sessionID = null;
  }

  componentDidMount() {
    this.requestImageStream();
    this.updateInterval = setInterval(() => this.forceUpdate(), 5000);
  }

  componentDidUpdate() {
    this.setState({ count: this.state.count + 1 });
  }

  shouldComponentUpdate(prevProps) {
    let prevImage = this.getImageData(prevProps).data;
    let newImage = this.getImageData(this.props).data;
    const prevStartTime = prevProps.startTime;
    const startTime = this.props.startTime;
    if (prevProps.channelID !== this.props.channelID) {
      this.unrequestImageStream(prevProps);
      this.requestImageStream();
      return true;
    } else if (startTime && prevStartTime) {
      //Refetch in the following cases -
      //If the current start time has moved significantly since the last iteration
      //Or if the current stream has been playing for more than 5 minutes
      const hasPlayheadPositionChanged =
        Math.abs(prevStartTime - startTime) >= PLAYHEAD_CHANGE_SENSITIVITY_SECS;
      const hasStreamExpired =
        this.archiveStreamStartTime &&
        Math.abs(startTime - this.archiveStreamStartTime) >=
          ARCHIVE_STREAM_DURATION_SECS;
      if (hasPlayheadPositionChanged || hasStreamExpired) {
        this.unrequestImageStream(prevProps);
        this.requestImageStream();
        return true;
      }
    }
    if (!_.isEqual(prevImage, newImage)) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
    this.unrequestImageStream();
  }

  getImageData(props) {
    props = props || this.props;
    return props.imageData || {};
  }

  unrequestImageStream(props) {
    props = props || this.props;
    let { channelID, startTime, dispatch } = props;
    let { streamType } = this.state;

    if (!channelID) return;

    if (startTime) {
      if (this.archiveStreamStartTime) {
        dispatch({
          type: 'user/archiveImageStreamOp',
          op: 'unrequest',
          channelID,
          streamType,
          startTime: this.archiveStreamStartTime,
          endTime: this.archiveStreamStartTime + ARCHIVE_STREAM_DURATION_SECS,
          sessionID: this.sessionID,
        });
        this.archiveStreamStartTime = 0;
      }
    } else {
      dispatch({
        type: 'user/imageStreamOp',
        op: 'unrequest',
        channelID: this.props.channelID,
        streamType,
      });
    }
  }

  requestImageStream(e = undefined) {
    let { channelID, startTime, dispatch } = this.props;
    let { streamType } = this.state;

    if (!channelID) return;

    if (startTime) {
      const sessionID = `${channelID}-${startTime}`;
      this.sessionID = sessionID;
      this.archiveStreamStartTime = startTime;
      dispatch({
        type: 'user/archiveImageStreamOp',
        op: 'request',
        channelID,
        streamType,
        startTime,
        endTime: startTime + ARCHIVE_STREAM_DURATION_SECS,
        sessionID,
      });
    } else {
      dispatch({
        type: 'user/imageStreamOp',
        op: 'request',
        channelID,
        streamType,
      });
    }

    this.forceUpdate();
    if (e !== undefined) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  }

  getCurrentImage() {
    return this.getImageData().data;
  }

  switchStreams(newStreamType) {
    const { channelID, streamChangeCallback } = this.props;
    const { streamType } = this.state;

    if (_.isEqual(newStreamType, streamType)) {
      return;
    }

    if (streamChangeCallback) {
      streamChangeCallback(channelID, newStreamType);
    }

    // first unrequest previous type before requesting new one
    this.unrequestImageStream();
    this.setState({ streamType: newStreamType }, () =>
      this.requestImageStream(),
    );
  }

  render() {
    const {
      channelID,
      hideBadge,
      showStreamTypeSelector,
      streamSelections,
      showLoader = false,
    } = this.props;
    const { streamType } = this.state;

    if (!channelID) return <></>;
    const isStale =
      new Date().getTime() - (this.getImageData().timestamp || 0) > 15000;

    if (this.getCurrentImage() === null) {
      if (!showLoader) {
        return <></>;
      }
      if (isStale) {
        // Requested and waited for 15 secs
        return (
          <div className={styles['stream-failed-ctn']}>
            <div style={{ marginBottom: '10px', textAlign: 'center' }}>
              Video did not load. Try again later.
            </div>
            <Button onClick={this.requestImageStream} type="primary">
              Retry
            </Button>
          </div>
        );
      }
      return <LoadingSpinner />;
    }
    if (!this.getCurrentImage()) {
      return <></>;
    }

    const src = `data:image/jpeg;base64, ${this.getCurrentImage()}`;

    return (
      <div className={styles.ctn}>
        {!hideBadge && !isStale && (
          <div onClick={this.requestImageStream} className="df-tile-badge">
            <Flex align="center" justify="center" gap={3}>
              <span style={{ fontSize: '13px', transform: 'translateY(-1px)' }}>
                &bull;{' '}
              </span>
              <span>LIVE</span>
            </Flex>
          </div>
        )}
        {isStale && (
          <div
            onClick={this.requestImageStream}
            className="df-tile-badge"
            style={{ cursor: 'pointer' }}>
            <span>
              <span>
                Stopped <ReloadOutlined />
              </span>
            </span>
          </div>
        )}
        <div className={styles.imagectn}>
          <Image src={src} />
        </div>
        {showStreamTypeSelector && (
          <div className={styles['res-selector']}>
            <StreamTypeSelector
              streamType={streamType}
              streamSelections={streamSelections}
              onChange={(newStreamType) => this.switchStreams(newStreamType)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ChannelImageStream;
