import SpatialFiltersModal from '@/components/SearchForm2/SpatialFiltersModal';
import _ from 'lodash';
import React, { ChangeEvent } from 'react';
import { connect } from 'umi';

import type ChannelActions from '@/components/ChannelComponents/channel-actions';
import PageHeader from '@/components/PageHeader2';
import styles from './style.less';

import {
  BASE_STN_TYPE,
  ChannelNode,
  CH_GRP_TYPE,
  CH_TYPE,
  LocationNode,
  LOC_TYPE,
} from '@/types/location';
import { RouteComponentProps } from '@/types/utils';
import {
  displayTZ,
  findChannelInLoc,
  getCurrentCustomerID,
  getIdToken,
  resetSearchForm2,
} from '@/utils/utils';

import RangePickerDF from '@/components/RangePickerDF';
import type { AccountsModelState } from '@/models/accounts';
import type { Search2ModelType } from '@/models/search2';
import AuditTool from '@/pages/channel-audit-tool';
import withRouter from '@/utils/withRouter';
import { Button, Checkbox, Input } from 'antd';
import moment from 'moment';

const CheckboxGroup = Checkbox.Group;

const OBJECT_TYPES = [
  { label: 'People', value: 'timeline.person' },
  { label: 'Vehicle', value: 'timeline.vehicle' },
  { label: 'Other', value: 'other' },
];
const DEFAULT_SELECTED_OBJECT_TYPES = ['timeline.person'];

interface MatchParams {
  locationID: string;
  channelID: string;
}

interface MyProps extends RouteComponentProps<MatchParams> {
  loc?: LOC_TYPE;
  ch_grp?: CH_GRP_TYPE;
  ch?: CH_TYPE;
  base_stn?: BASE_STN_TYPE;
  search2?: Search2ModelType;
  accounts?: AccountsModelState;
  appsAll?: Record<number, any>[];
  dispatch?: (_any: any) => Promise<any>;
}

type MyState = {
  currentLocation: LocationNode | undefined;
  currentChannel: ChannelNode | undefined;
  searchData: any;
  loadingData: boolean;
  queryStartTime: moment.Moment;
  queryEndTime: moment.Moment;
  selectedObjectTypes: string[];
  customObjectType: string;
};

declare const DF_SERVICES_API_ENDPOINT: string;

// @ts-expect-error
@connect(({ locations }) => ({
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
  base_stn: locations.base_stn,
}))
class ChannelPage extends React.Component<MyProps, MyState> {
  channelActionsRef: React.RefObject<ChannelActions>;
  dateRange: moment.Moment[];
  videoWidth: number;
  videoBackgroundImageUrl: string;

  constructor(props: MyProps) {
    super(props);
    this.channelActionsRef = React.createRef();
    this.dateRange = [
      // these values are taken from range picker default values
      moment({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).add(
        -1,
        'days',
      ),
      moment({ hour: 23, minute: 59, seconds: 59, milliseconds: 0 }).add(
        -1,
        'days',
      ),
    ];
    this.spatialFilter = {};
    this.state = {
      currentLocation: undefined,
      currentChannel: undefined,
      loadingData: false,
      searchData: null,
      queryStartTime: this.dateRange[0],
      queryEndTime: this.dateRange[1],
      spatialFilter: {},
      selectedObjectTypes: DEFAULT_SELECTED_OBJECT_TYPES,
      customObjectType: '',
    };

    this.videoWidth = 0;
    this.videoBackgroundImageUrl = '';
    this.handleObjectTypeChange = this.handleObjectTypeChange.bind(this);
    this.handleCustomObjectChange = this.handleCustomObjectChange.bind(this);
  }

  componentDidMount() {
    this.currentURLParamsDidUpdate();
  }

  async getSearchResult() {
    const token = await getIdToken();
    const { selectedObjectTypes, customObjectType } = this.state;
    let objectsToSearch = selectedObjectTypes.filter(
      (item) => item !== 'other',
    );
    if (selectedObjectTypes.includes('other') && customObjectType) {
      objectsToSearch.push(customObjectType);
    }

    const data = {
      queryID: '2',
      Metadata: {
        CustomerID: getCurrentCustomerID(),
        ChannelID: [this.state.currentChannel?.ID],
      },
      p_number: 1,
      p_size: 10000, // TODO, need to check if clips exceed this number
      ESVideoStartTime: `${this.dateRange[1].format(
        'YYYY-MM-DDTHH:mm:ss.000000',
      )}Z`,
      ESVideoEndTime: `${this.dateRange[0].format(
        'YYYY-MM-DDTHH:mm:ss.000000',
      )}Z`,
      Objects: objectsToSearch,
      // lineFilter: this.spatialFilter.lineFilters.length > 0? {channelID: this.state.currentChannel?.ID.toString(), line:this.spatialFilter.lineFilters}: {},
      regionFilter:
        this.spatialFilter.regionFilters?.length > 0
          ? {
              channelID: this.state.currentChannel?.ID.toString(),
              regions: this.spatialFilter.regionFilters,
            }
          : undefined,
    };

    this.setState({ loadingData: true });
    const response = await fetch(
      `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/search`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          accept: 'application/json',
          'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
          'content-type': 'application/json;charset=UTF-8',
          'sec-ch-ua':
            '"Not A(Brand";v="99", "Google Chrome";v="121", "Chromium";v="121"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"macOS"',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'cross-site',
        },
        body: JSON.stringify(data),
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
      },
    );

    const result = await response.json();

    /*
    this call is  not done in an optimal way right now
    1. this call is only needed when locationId changes in the URL so no need to do this every time we search
    2. this call can be done in parallel to previous call for search using Promise.all, but point 1 is more important, this call shouldn't be here
    */
    const locationResponse = await fetch(
      `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/locations/${
        this.props.match.params.locationID
      }`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    const locationData = await locationResponse.json();

    const videoData = this.getVideoDetailsFromLocation(locationData);
    this.videoBackgroundImageUrl = videoData.imageUrl;
    this.videoWidth = videoData.width;

    this.setState({
      searchData: result,
      loadingData: false,
      queryStartTime: this.dateRange[0],
      queryEndTime: this.dateRange[1],
      spatialFilter: this.spatialFilter,
    });
  }

  componentDidUpdate(prevProps: MyProps) {
    this.currentURLParamsDidUpdate(prevProps);
  }

  getVideoDetailsFromLocation(locationData: any): {
    width: number;
    imageUrl: string;
  } {
    const currentChannelId = +this.props.match.params.channelID;
    const channelPath = findChannelInLoc(currentChannelId, locationData?.data);
    if (!channelPath || !channelPath.length) return { width: 0, imageUrl: '' };

    const currentChannelInfo = channelPath[channelPath.length - 1];
    const latestMedia = currentChannelInfo.LatestMedia;
    if (!latestMedia || !latestMedia.length) return { width: 0, imageUrl: '' };

    const width = latestMedia[0]?.MetadataDF?.ThumbnailInfo?.Width;
    const imageUrl = latestMedia[0]?.Thumbnail?.SignedUrl;

    return { width: width || 0, imageUrl: imageUrl || '' };
  }

  // this is from channel component, keeping it because it updates page on url change
  currentURLParamsDidUpdate(prevProps: MyProps | null = null) {
    const { locationID, channelID } = this.props.match.params;
    const {
      locationID: prev_locationID = null,
      channelID: prev_channelID = null,
    } = _.get(prevProps, 'match.params', {});
    const currentLocation = this.props.loc?.byId[+locationID];
    const prevLocation = _.get(
      prevProps,
      `loc.byId[${+prev_locationID}]`,
      null,
    );
    const currentChannel = this.props.ch?.byId[+channelID];
    const prev_currentChannel = _.get(
      prevProps,
      `ch.byId[${+prev_channelID}]`,
      null,
    );

    const newState: Record<any, any> = {};

    if (
      currentLocation &&
      currentLocation instanceof LocationNode &&
      (!_.isEqual(
        locationID,
        _.get(prevProps, 'match.params.locationID', -1),
      ) ||
        !_.isEqual(prevLocation, currentLocation))
    ) {
      newState.currentLocation = currentLocation;
    }

    if (
      currentChannel &&
      currentChannel instanceof ChannelNode &&
      (!_.isEqual(channelID, _.get(prevProps, 'match.params.channelID', -1)) ||
        !_.isEqual(prev_currentChannel, currentChannel))
    ) {
      newState.currentChannel = currentChannel;
    }

    if (!_.isEmpty(newState)) {
      this.setState(newState, () => {
        const { dispatch } = this.props;
        if (dispatch && prev_channelID != channelID) resetSearchForm2(dispatch);
      });
    }
  }

  handleObjectTypeChange(selectedObjectTypes: string[]) {
    this.setState({
      selectedObjectTypes,
    });
  }

  handleCustomObjectChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({
      customObjectType: e.target.value,
    });
  }

  render() {
    const { currentLocation, currentChannel, selectedObjectTypes } = this.state;
    if (
      currentLocation &&
      currentLocation instanceof LocationNode &&
      currentChannel &&
      currentChannel instanceof ChannelNode
    ) {
      return (
        <div id="loc-ch_grp-ch-title">
          <PageHeader
            title={currentChannel.Name}
            subtitle={
              <div style={{ display: 'flex' }}>
                {displayTZ(currentChannel.Timezone)}
              </div>
            }
          />
          <div className={styles['top-ctn']}>
            <div className={styles['main-ctn']}>
              {this.state.loadingData ? (
                'loading'
              ) : (
                <AuditTool
                  data={this.state.searchData?.data}
                  queryStartTime={this.state.queryStartTime}
                  queryEndTime={this.state.queryEndTime}
                  videoWidth={this.videoWidth}
                  bgImageUrl={this.videoBackgroundImageUrl}
                  channelId={this.state.currentChannel?.ID as number}
                />
              )}
            </div>
            <div className={styles['right-pannel']}>
              <div style={{ margin: '20px 20px 20px 0' }}>
                <RangePickerDF
                  layout="vertical"
                  onChange={(dateRange) => {
                    this.dateRange = dateRange;
                  }}
                />
              </div>
              <div style={{ margin: '20px 20px 20px 0' }}>
                <SpatialFiltersModal
                  onOk={(value) => {
                    this.spatialFilter = value;
                  }}
                  channelID={this.state.currentChannel?.ID}>
                  <Button>Spatial Filter</Button>
                </SpatialFiltersModal>
              </div>
              <div style={{ margin: '20px 20px 20px 0' }}>
                <CheckboxGroup
                  options={OBJECT_TYPES}
                  value={selectedObjectTypes}
                  onChange={this.handleObjectTypeChange}
                />
              </div>
              {selectedObjectTypes.includes('other') && (
                <div style={{ margin: '10px 20px 20px 0' }}>
                  <Input
                    placeholder="Please specify"
                    onChange={this.handleCustomObjectChange}
                  />
                </div>
              )}
              <Button
                type="primary"
                onClick={() => {
                  this.getSearchResult();
                }}>
                Get Video Data
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  }
}
export default withRouter(ChannelPage);
