export const APP_ID = 106;
export const CAPABILITIES = [
  'sites-and-labels',
  'users-and-roles',
  'monitoring',
  'engagement-plan',
  'event-log',
  'fleet-status',
];
export const CAPABILITIES_LABEL_MAP = {
  'users-and-roles': 'Users & Roles',
  monitoring: 'Monitoring',
  'sites-and-labels': 'Fleet Registry',
  'engagement-plan': 'Engagement Plan',
  'event-log': 'Log',
  'fleet-status': 'Fleet Status',
};

export enum EventType {
  CREATE_SITE = 'CreateSite',
  UPDATE_SITE = 'UpdateSite',
  DELETE_SITE = 'DeleteSite',
  CREATE_LABEL = 'CreateLabel',
  UPDATE_LABEL = 'UpdateLabel',
  DELETE_LABEL = 'DeleteLabel',
  INVITE_USER = 'invite_user_api',
  REMOVE_USER = 'remove_user_api',
  CREATE_ACCESS_RULES = 'create_access_rules',
  UPDATE_ACCESS_RULES = 'update_access_rules',
  DELETE_ACCESS_RULES = 'delete_access_rules',
  IMPORT_SITES_AND_CONTACTS = 'import_sites_and_contacts',
  DELETE_INVITE = 'delete_invite_api',
  ENGAGMENT_PLAN_UPDATED = 'CreateUpdateFleetManagerEngagementPlan',
  UPDATE_SITE_STATUS = 'UpdateSiteStatus',
  ENTITY_CONFIG_MODIFIED = 'add_config_to_entity',
  ENTITY_CONFIG_REMOVED = 'remove_config_for_entity',
  APP_ADDED = 'add_app_api',
  APP_UPDATED = 'update_app_api',
  APP_DELETED = 'delete_app_api',
}

export const EVENT_TYPE_LABEL_MAPPING: Record<string, string> = {
  [EventType.CREATE_SITE]: 'Site Added',
  [EventType.UPDATE_SITE]: 'Site Updated',
  [EventType.DELETE_SITE]: 'Site Deleted',
  [EventType.CREATE_LABEL]: 'Site Group Added',
  [EventType.UPDATE_LABEL]: 'Site Group Updated',
  [EventType.DELETE_LABEL]: 'Site Group Deleted',
  [EventType.INVITE_USER]: 'User Invited',
  [EventType.REMOVE_USER]: 'User Removed',
  [EventType.CREATE_ACCESS_RULES]: 'Access Rule Created',
  [EventType.UPDATE_ACCESS_RULES]: 'Access Rule Updated',
  [EventType.DELETE_ACCESS_RULES]: 'Access Rule Deleted',
  [EventType.IMPORT_SITES_AND_CONTACTS]: 'Sites and Contacts Imported',
  [EventType.DELETE_INVITE]: 'Invite Deleted',
  [EventType.ENGAGMENT_PLAN_UPDATED]: 'Engagement Plan Updated',
  [EventType.UPDATE_SITE_STATUS]: 'Site Status Updated',
  [EventType.ENTITY_CONFIG_MODIFIED]: 'Entity Config Modified',
  [EventType.ENTITY_CONFIG_REMOVED]: 'Entity Config Removed',
  [EventType.APP_ADDED]: 'App Added',
  [EventType.APP_UPDATED]: 'App Updated',
  [EventType.APP_DELETED]: 'App Deleted',
};
