import LoadingSpinner from '@/components/LoadingSpinner';
import { useDfMediaQuery } from '@/utils/dfMediaQuery';
import { useAppCapabilities } from '@/utils/useAppCapabilities';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Navigate, useDispatch } from '@umijs/max';
import { Button, Flex, theme } from 'antd';
import { Suspense, useMemo, useState } from 'react';
import type { SceneFragment$key } from '../components/Scene/__generated__/SceneFragment.graphql';
import type { SceneViewerProps } from '../components/SceneViewer';
import SceneViewer from '../components/SceneViewer';
import { CAPABILITIES_MAP, MonitorAppPathname } from '../constants';
import { useMonitorContext } from '../MonitorContext';
import { useMonitorAppSubHeaderAuxControls } from '../useMonitorAppSubHeaderAuxControls';
import type { MonitorMutations_UserSceneConnectionAdd_Mutation$data } from '../__generated__/MonitorMutations_UserSceneConnectionAdd_Mutation.graphql';
const { useToken } = theme;

const ActiveSceneContent = () => {
  const { isMobile } = useDfMediaQuery();
  const { appId } = useMonitorContext();
  const { tabsToShow } = useAppCapabilities({
    appId,
  });
  const { token } = useToken();
  const dispatch = useDispatch();
  // Define the state variables and functions you want to expose in the context
  const [isSidebarHidden, setIsSidebarHidden] = useState(isMobile);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [openSaveSceneModal, setOpenSaveSceneModal] = useState(false);
  const [selectedSceneRef, setSelectedSceneRef] = useState<SceneFragment$key>();

  const toggleSidebarVisibility = () => {
    setIsSidebarHidden((prev) => !prev);
  };

  const handleSceneSave: SceneViewerProps['onSceneSave'] = (scene) => {
    const newScene = (
      scene as MonitorMutations_UserSceneConnectionAdd_Mutation$data
    )?.createNewUserScene?.sceneEdge?.node;
    setSelectedSceneRef(newScene as SceneFragment$key);
    setOpenSaveSceneModal(false);
  };

  const handleModalClose = () => {
    setOpenSaveSceneModal(false);
  };

  const passedChannelIds = useMemo(() => {
    const channelIds = new URLSearchParams(location.search).getAll(
      'channelIds',
    );
    return channelIds.map((id) => Number(id)).filter((id) => !isNaN(id));
  }, [location.search]);

  const toggleSearchMode = () => {
    dispatch({ type: 'monitor_search/resetState' });
    setIsSearchMode(!isSearchMode);
  };

  useMonitorAppSubHeaderAuxControls(
    {
      auxControls: (
        <Flex gap={20}>
          <Button onClick={() => setOpenSaveSceneModal(true)} type="primary">
            Create Scene
          </Button>
          <Button
            onClick={toggleSearchMode}
            style={{
              border: 'none',
              backgroundColor: isSearchMode
                ? token.colorPrimaryBg
                : 'transparent',
            }}
            icon={<SearchOutlined style={{ fontSize: ' 24px' }} />}
          />
          {isSidebarHidden ? (
            <MenuFoldOutlined
              onClick={toggleSidebarVisibility}
              style={{ fontSize: '24px' }}
            />
          ) : (
            <MenuUnfoldOutlined
              onClick={toggleSidebarVisibility}
              style={{ fontSize: '24px' }}
            />
          )}
        </Flex>
      ),
    },
    [isSidebarHidden, isSearchMode],
  );

  if (tabsToShow.indexOf(CAPABILITIES_MAP.activeScene) === -1) {
    return <Navigate to={MonitorAppPathname} />;
  }

  return (
    <SceneViewer
      selectedSceneRef={selectedSceneRef}
      passedChannelIds={passedChannelIds}
      openSaveSceneModal={openSaveSceneModal}
      onModalClose={handleModalClose}
      onSceneSave={handleSceneSave}
      isCameraListHidden={isSidebarHidden}
      isSearchMode={isSearchMode}
    />
  );
};

const ActiveScene = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ActiveSceneContent />
    </Suspense>
  );
};

export default ActiveScene;
