import { getCurrentCustomerID, STREAM_TYPES } from '@/utils/utils';
import _ from 'lodash';
import { useSelector } from 'umi';
import { STREAM_QUALITY } from '../StreamQualitySelector/constants';
import { StreamQuality } from '../StreamQualitySelector/types';
import { StreamType } from '../StreamTypeSelector/types';

export interface DFRTCPeerConnection extends RTCPeerConnection {
  uuid?: string;
  params?: {
    video_quality?: StreamQuality;
  };
}

export const makeSignalingRequest = async (
  pc: DFRTCPeerConnection,
  baseStationID: string,
  channelID: string,
  streamQuality?: StreamQuality,
  streamType?: StreamType,
  arePeersLocal?: boolean | null,
  videoStartTime?: number,
  videoEndTime?: number,
  uuid?: string,
): Promise<any> => {
  if (!pc.localDescription) {
    throw new Error("PeerConnection's localDescription is not set.");
  }

  let signalingUrl = `https://${baseStationID}.${PROXY_SUB_DOMAIN}.dragonfruit.ai/webrtc_signaling?channel_id=${channelID}`;

  if (videoStartTime && videoEndTime) {
    signalingUrl += `&start_time=${videoStartTime}&end_time=${videoEndTime}`;
  }

  if (uuid) {
    signalingUrl += `&webrtc_uuid=${uuid}`;
  }

  //This is an exception:
  //If both global and local resolution selections are default
  //AND we're not streaming locally i.e both peers are not on same network, use medium resolution
  //(base stations use high resolution by default if nothing is passed)
  if (
    streamQuality === STREAM_QUALITY.DEFAULT &&
    streamType?.name === STREAM_TYPES.DEFAULT.name &&
    arePeersLocal === false
  ) {
    signalingUrl += `&video_quality=${STREAM_QUALITY.MEDIUM}`;
  } else {
    //1) Stream type selection always overrides stream quality selection, however, to avoid confusion,
    //we only pass stream quality only when stream type is default
    //2) Default stream quality is something that BE does not understand -
    //it is only for the UI. What this selection implies is => use high quality if on same local network else use medium quality
    if (
      streamQuality &&
      streamQuality !== STREAM_QUALITY.DEFAULT &&
      streamType?.name === STREAM_TYPES.DEFAULT.name
    ) {
      signalingUrl += `&video_quality=${streamQuality}`;
    }
  }

  if (streamType) {
    signalingUrl += `&viewport=${streamType.viewport}&playback_fps=${streamType.fps}`;
  }

  const response = await fetch(signalingUrl, {
    body: JSON.stringify({
      sdp: pc.localDescription.sdp,
      type: pc.localDescription.type,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  const url = new URL(signalingUrl);
  const query = Object.fromEntries(url.searchParams.entries());
  const request = {
    query,
  };

  if (!response.ok) {
    const text = await response.text();
    throw new Error(text);
  }

  const answer = await response.json();

  return [answer, request];
};

export const negotiate = async (
  pc: DFRTCPeerConnection,
  baseStationID: string,
  channelID: string,
  streamQuality?: StreamQuality,
  streamType?: StreamType,
  arePeersLocal?: boolean | null,
  videoStartTime?: number,
  videoEndTime?: number,
): Promise<any> => {
  pc.addTransceiver('video', { direction: 'recvonly' });

  try {
    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    await new Promise<void>((resolve) => {
      const checkState = () => {
        if (pc.iceGatheringState === 'complete') {
          pc.removeEventListener('icegatheringstatechange', checkState);
          resolve();
        }
      };
      pc.addEventListener('icegatheringstatechange', checkState);
      checkState(); // Check immediately in case the state is already complete
    });

    const [answer, request] = await makeSignalingRequest(
      pc,
      baseStationID,
      channelID,
      streamQuality,
      streamType,
      arePeersLocal,
      videoStartTime,
      videoEndTime,
    );

    if (pc.signalingState !== 'closed') {
      await pc.setRemoteDescription(answer);
      pc.uuid = answer.uuid;
      pc.params = request.query;
    }
  } catch (e: any) {
    throw new Error(
      `${_.get(e, 'message') ? e.message : 'Negotiation failed'}`,
    );
  }
};

export const STUN_SERVER_URL =
  ENVIRONMENT === 'production'
    ? 'stun:coturn.dragonfruit.ai:3478'
    : 'stun:stage-coturn.dragonfruit.ai:3478';

export const TURN_SERVER_URL =
  ENVIRONMENT === 'production'
    ? 'turn:coturn.dragonfruit.ai:3478'
    : 'turn:stage-coturn.dragonfruit.ai:3478';

export const useStunServerConfig = () => {
  return {
    urls: STUN_SERVER_URL,
  };
};

export const useTurnServerConfig = () => {
  const customerID = getCurrentCustomerID();
  const currentUser = useSelector((state: any) => state.user.currentUser);
  const config = _.get(currentUser, `Customers[${customerID}].Customer.Config`);

  if (!config || !config.TurnServerCredential || !config.TurnServerUsername) {
    return null;
  }

  return {
    urls: TURN_SERVER_URL,
    username: config.TurnServerUsername,
    credential: config.TurnServerCredential,
  };
};

export const arePeersOnSameNetwork = (
  localCandidate: any,
  remoteCandidate: any,
) => {
  //Check below must suffice, ip address check may not be required, commenting for now
  //Sample usage -> isSameSubnet("192.168.1.10", "192.168.1.15", "255.255.255.0")
  // function isSameSubnet(ip1: string, ip2: string, subnetMask: string) {
  //   const ipToBinary = (ip: string) => ip.split('.').map(Number).map((num) => num.toString(2).padStart(8, '0')).join('');
  //   const maskToBinary = (mask: string) => mask.split('.').map(Number).map((num) => num.toString(2).padStart(8, '0')).join('');

  //   const binaryIp1 = ipToBinary(ip1);
  //   const binaryIp2 = ipToBinary(ip2);
  //   const binaryMask = maskToBinary(subnetMask);

  //   for (let i = 0; i < binaryMask.length; i++) {
  //     if (binaryMask[i] === '1' && binaryIp1[i] !== binaryIp2[i]) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }

  return !!(
    localCandidate &&
    remoteCandidate &&
    localCandidate.candidateType === 'host' &&
    remoteCandidate.candidateType === 'host'
  );
};
