import LoadingSpinner from '@/components/LoadingSpinner';
import StreamTypeSelector from '@/components/StreamTypeSelector';
import type Timeline from '@/components/Timeline';
import { DFConfigKeys } from '@/dfConfigKeys';
import { LocationsModalState } from '@/models/location';
import {
  beginTransaction,
  endTransactionWithFailure,
  endTransactionWithSuccess,
} from '@/monitoring';
import { generateRandomID, STREAM_TYPES } from '@/utils/utils';
import { Button } from 'antd';
import _ from 'lodash';
import OvenPlayer from 'ovenplayer';
import React, { useEffect } from 'react';
import semver from 'semver';
import { connect, useDispatch, useSelector } from 'umi';
import { STREAM_QUALITY } from '../StreamQualitySelector/constants';
import { StreamQuality } from '../StreamQualitySelector/types';
import WebRTCVideoPlayer from '../webrtc-stream';
import ZoomControls from '../ZoomControls';
import styles from './style.less';

type MyProps = {
  isArchive: boolean;
  startTime: any;
  currentUser: any;
  streamSelections: any;
  channelID: number;
  locationID: number;
  baseStationID: any;
  baseStationWifiMAC: any;
  baseStationVersion: any;
  channelVideoData: any;
  channelArchiveData: any;
  isPlaying: boolean;
  TimelineComponent: typeof Timeline;
  streamType: any;
  preferredStreamQuality: StreamQuality;
  dispatch: (_any: any) => Promise<any>;
};

type MyState = {
  loadingStream: boolean;
  archiveStreamStartTime: any;
  retryStream: boolean;
  hls_playlist_path: any;
  streamKey: any;
  archiveStreamKey: any;
  streamError: any;
  streamPath: any;
  streamType: any;
};

const ARCHIVE_STREAM_DURATION_SECS = 300;
const PLAYHEAD_CHANGE_SENSITIVITY_SECS = 5;
// used in global.less for styling
const OME_CNT = 'ome_player_container';
//Number of seconds to wait to receive an hls path from websocket
const ARCHIVE_STREAM_TIMEOUT_MS = 20000;
const STREAM_RELOAD_RETRY_COUNT = 5;
const TRANSACTION_NAME_FOR_MONITORING = 'streaming';

// @ts-expect-error
@connect(({ user, locations }, { channelID }) => ({
  currentUser: user.currentUser,
  channelVideoData: user.channelVideoData,
  channelArchiveData: user.channelArchiveData,
  cropRectangleConfig:
    locations.ch.byId[channelID]?.ConfigProfiles[
      DFConfigKeys.dc_onprem_cropRectangle
    ],
  baseFolderPath:
    locations.ch.byId[channelID]?.ConfigProfiles[
      DFConfigKeys.dc_onprem_baseFolderPath
    ],
  secondaryStreamConfigured: !_.isEmpty(
    locations.ch.byId[channelID]?.ConfigProfiles[
      DFConfigKeys.dc_onprem_secondaryRTSPStreams
    ],
  ),
}))
class ChannelVideoStream extends React.Component<MyProps, MyState> {
  timer: any;
  player: any;
  playerRef: any;
  archive_stream_message_timer: any;
  containerIDPrefix: string;
  private videoContainerRef: React.RefObject<HTMLDivElement>;
  private videoDivRef: React.RefObject<HTMLDivElement>;
  constructor(props: MyProps) {
    super(props);
    let streamType = this.props.streamType || STREAM_TYPES.R720p_15;
    if (!this.isVirtualChannel()) {
      streamType = STREAM_TYPES.DEFAULT;
    }
    this.state = {
      retryStream: false,
      archiveStreamStartTime: undefined,
      loadingStream: false,
      streamError: '',
      streamPath: '',
      hls_playlist_path: '',
      streamKey: `video-stream-${this.props.channelID}-${streamType.key}`,
      archiveStreamKey: '',
      streamType,
    };
    this.playerRef = React.createRef();
    this.videoContainerRef = React.createRef();
    this.videoDivRef = React.createRef();
    this.archive_stream_message_timer = null;
    this.handleArchiveStreamMessageTimeout =
      this.handleArchiveStreamMessageTimeout.bind(this);
    this.containerIDPrefix = generateRandomID();
  }

  componentDidMount() {
    this.requestStream();
  }

  componentDidUpdate(prevProps: MyProps) {
    const { isArchive, startTime: currentPlayTime } = this.props;
    const { startTime: prevPlayTime } = prevProps;
    const { archiveStreamStartTime, retryStream } = this.state;

    if (isArchive && this.props.isPlaying) {
      //Refetch in the following cases -
      //If the current play time has moved significantly since the last iteration
      //Or if the current stream has been playing for more than 5 minutes
      const hasPlayheadPositionChanged =
        Math.abs(currentPlayTime - prevPlayTime) >=
        PLAYHEAD_CHANGE_SENSITIVITY_SECS;
      const hasStreamExpired =
        Math.abs(currentPlayTime - archiveStreamStartTime) >=
        ARCHIVE_STREAM_DURATION_SECS;
      if (hasPlayheadPositionChanged || hasStreamExpired) {
        this.requestStream();
      }
    }

    if (prevProps.channelID !== this.props.channelID) {
      // if there was an old channel, turn that off
      this.unrequestStream(prevProps.channelID);
      this.setState(
        {
          streamKey: `video-stream-${this.props.channelID}-${this.state.streamType.key}`,
        },
        () => this.requestStream(),
      );
      return;
    }

    if (
      prevProps.preferredStreamQuality !== this.props.preferredStreamQuality
    ) {
      this.unrequestStream(this.props.channelID);
      this.setState({ streamType: STREAM_TYPES.DEFAULT }, () => {
        if (this.props.isPlaying) {
          this.requestStream();
        }
      });
      return;
    }

    if (prevProps.isPlaying !== this.props.isPlaying) {
      if (this.props.isPlaying) {
        // if we're newly playing request from base station
        this.requestStream();
      } else {
        // if we're newly not playing tell the base station to stop sending video
        this.unrequestStream(this.props.channelID, false);
      }
    }

    const { channelArchiveData } = this.props;
    const stream_path = _.get(
      channelArchiveData[this.props.channelID],
      'stream_path',
    );
    const session_id = _.get(
      channelArchiveData[this.props.channelID],
      'session_id',
    );
    const expected_stream_path = this.state.streamPath;
    const expected_session_id = this.state.archiveStreamKey;
    if (
      stream_path?.length &&
      expected_stream_path?.length &&
      (stream_path.includes(expected_stream_path) ||
        session_id.includes(expected_session_id))
    ) {
      const hls_playlist_path = _.get(
        channelArchiveData[this.props.channelID],
        'hls_playlist_path',
      );
      if (
        hls_playlist_path !== this.state.hls_playlist_path ||
        retryStream === true
      ) {
        this.setState({ hls_playlist_path, retryStream: false });
        this.loadStream({
          hls_url: hls_playlist_path,
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer);
    if (this.archive_stream_message_timer)
      clearTimeout(this.archive_stream_message_timer);
    this.removePlayer();
    this.unrequestStream(this.props.channelID);
  }

  removePlayer() {
    if (this.player) {
      this.player.stop();
      this.player.remove();
    }
  }

  isVirtualChannel() {
    const cropRectangle = _.get(
      this.props.cropRectangleConfig,
      ['values', 'w:h:x:y'],
      [],
    );
    return cropRectangle.length > 0;
  }

  handleArchiveStreamMessageTimeout() {
    if (!this.state.hls_playlist_path && this.state.loadingStream) {
      this.setState({
        loadingStream: false,
        streamError: 'Video does not exist for the requested time.',
      });
    }
    this.archive_stream_message_timer = null;
  }

  requestStream() {
    const {
      channelID,
      dispatch,
      isArchive,
      startTime,
      baseStationVersion,
      TimelineComponent,
      preferredStreamQuality,
    } = this.props;
    let { streamType, streamKey } = this.state;
    let newState = {};

    //Unrequest current stream
    this.unrequestStream(this.props.channelID);

    //Show loader and pause timeline from progressing
    TimelineComponent.pauseTObjForLoading();
    newState.streamError = '';
    newState.loadingStream = true;

    if (isArchive) {
      const currentPlayTime = startTime;
      const archiveStreamKey = `video-${channelID}-${currentPlayTime}-${
        currentPlayTime + ARCHIVE_STREAM_DURATION_SECS
      }-${streamType.viewport}-${streamType.fps}-${preferredStreamQuality}`;

      newState = {
        ...newState,
        hls_playlist_path: '',
        archiveStreamStartTime: currentPlayTime,
        archiveStreamKey,
        streamPath: `/channel/${channelID}/archive_video_stream/${currentPlayTime}/${currentPlayTime}/${
          currentPlayTime + ARCHIVE_STREAM_DURATION_SECS
        }/${streamType.key}`,
      };

      let payload = {
        type: 'user/archiveVideoStreamOp',
        op: 'request',
        channelID,
        streamKey: archiveStreamKey,
        streamType,
        start_time: currentPlayTime,
        end_time: currentPlayTime + ARCHIVE_STREAM_DURATION_SECS,
      };

      //Send video quality only if it is not default (since that is not a value BE recognizes)
      //and the selection is not overridden by a tile level resolution
      if (
        streamType.name === STREAM_TYPES.DEFAULT.name &&
        preferredStreamQuality &&
        preferredStreamQuality !== STREAM_QUALITY.DEFAULT
      ) {
        payload['video_quality'] = preferredStreamQuality;
      }

      dispatch(payload);

      this.archive_stream_message_timer = setTimeout(
        this.handleArchiveStreamMessageTimeout,
        ARCHIVE_STREAM_TIMEOUT_MS,
      );
    } else {
      if (semver.gte(baseStationVersion, '3.0.0') && !this.isVirtualChannel()) {
        const response = {
          hls_url: '',
        };
        this.loadStream(response);
      } else {
        dispatch({
          type: 'user/videoStreamOp',
          op: 'request',
          channelID,
          streamKey,
          streamType,
          callback: (response) => {
            if (
              _.get(response, 'data.message.msg') ===
              'request_video_stream successful'
            ) {
              this.loadStream(response);
            }
          },
        });
      }
    }

    this.setState(newState);
  }

  loadStream(response) {
    const {
      channelID,
      locationID,
      baseStationID = '',
      baseStationVersion = '',
      isArchive,
      TimelineComponent,
      preferredStreamQuality,
      secondaryStreamConfigured,
    } = this.props;
    const { streamType } = this.state;

    //Common config for both archive and live streams
    const config = {
      controls: false,
      mute: true,
      autoStart: true,
      showBigPlayButton: false,
      hlsConfig: {
        maxLoadingDelay: 0,
        nudgeMaxRetry: 6,
        manifestLoadingTimeOut: 25000,
      },
      webrtcConfig: {},
      sources: [],
    };

    //Create hls config object
    if (isArchive) {
      let hls_url = ``;
      let resp_url = response.hls_url;
      resp_url = resp_url.replace(
        this.props.baseFolderPath['values']['path'],
        '',
      );
      hls_url = `https://${baseStationID.toLowerCase()}.${PROXY_SUB_DOMAIN}.dragonfruit.ai${resp_url}`;
      config.sources.push({
        label: 'Archive Stream',
        type: 'hls',
        file: hls_url,
      });
      //Cancel timer
      clearTimeout(this.archive_stream_message_timer);
      this.archive_stream_message_timer = null;
    } else {
      let hls_url = ``;
      if (semver.gte(baseStationVersion, '3.2.0') && !this.isVirtualChannel()) {
        const streamQualitySuffix =
          preferredStreamQuality === STREAM_QUALITY.HIGH &&
          secondaryStreamConfigured
            ? '_2'
            : '';
        hls_url = `https://${baseStationID.toLowerCase()}.${PROXY_SUB_DOMAIN}.dragonfruit.ai/static/VideoFiles/${locationID}/${channelID}${streamQualitySuffix}/live/live.m3u8`;
        config.hlsConfig = {
          ...config.hlsConfig,
          liveSyncDurationCount: 1,
          liveMaxLatencyDurationCount: 3,
        };
      } else if (
        semver.gte(baseStationVersion, '3.0.0') &&
        !this.isVirtualChannel()
      ) {
        hls_url = `https://${baseStationID.toLowerCase()}.${PROXY_SUB_DOMAIN}.dragonfruit.ai/static/VideoFiles/${locationID}/${channelID}/live.m3u8`;
        config.hlsConfig = {
          ...config.hlsConfig,
          liveSyncDurationCount: 1,
          liveMaxLatencyDurationCount: 3,
        };
      } else {
        hls_url = `https://${baseStationID.toLowerCase()}.${PROXY_SUB_DOMAIN}.dragonfruit.ai/static/LiveStreamFiles/${locationID}/${channelID}/${channelID}_${
          streamType.viewport
        }_${streamType.fps}/${channelID}_live.m3u8`;
        config.hlsConfig = {
          ...config.hlsConfig,
          liveSyncDurationCount: 1,
          liveMaxLatencyDurationCount: 3,
        };
      }
      config.sources.push({
        label: 'Live Stream',
        type: 'hls',
        file: hls_url,
      });
    }

    //Keep the timeline paused and continue showing loader
    TimelineComponent.pauseTObjForLoading();
    this.setState({ loadingStream: true, streamError: '' });
    beginTransaction(TRANSACTION_NAME_FOR_MONITORING);

    let tryCount = 0;
    const createPlayer = (cnfg) => {
      this.removePlayer();
      // OvenPlayer.debug(true);
      tryCount++;
      try {
        const oven_config = _.cloneDeep(cnfg);
        this.player = OvenPlayer.create(
          this.getContainerIDForPlayer(),
          oven_config,
        );
      } catch (err) {
        console.error(err);
        return;
      }
      //this.setState({ loadingStream: true, streamError: '' });
      //TimelineComponent.pauseTObjForLoading();
      this.player.on('stateChanged', (data) => {
        //Playback successful
        if (
          // data.newstate === 'loading' ||
          data.newstate === 'playing'
          // data.newstate === 'stalled'
        ) {
          TimelineComponent.playTObjAfterLoading();
          this.setState({ loadingStream: false });
          endTransactionWithSuccess(TRANSACTION_NAME_FOR_MONITORING);
        }
        //Buffering
        if (data.newstate === 'stalled') {
          TimelineComponent.pauseTObjForLoading();
          this.setState({ loadingStream: true });
        }
        if (data.newstate === 'error') {
          TimelineComponent.pauseTObjForLoading();
          let streamError = '';
          let loadingStream = true;
          this.timer = setTimeout(() => {
            if (isArchive) {
              const { channelArchiveData } = this.props;
              streamError = _.get(
                channelArchiveData[channelID],
                'error_message',
                '',
              );
              if (tryCount > STREAM_RELOAD_RETRY_COUNT || streamError !== '') {
                if (streamError === '') {
                  streamError = 'Archived Video did not load. Try again.';
                }
                endTransactionWithFailure(TRANSACTION_NAME_FOR_MONITORING);
              } else {
                createPlayer(cnfg);
              }
            } else {
              const { channelVideoData } = this.props;
              streamError = _.get(
                channelVideoData[channelID],
                'error_message',
                '',
              );
              if (tryCount > STREAM_RELOAD_RETRY_COUNT) {
                if (streamError === '') {
                  streamError = 'Video did not load. Try again.';
                }
                endTransactionWithFailure(TRANSACTION_NAME_FOR_MONITORING);
              } else {
                createPlayer(cnfg);
              }
            }
            if (streamError !== '') {
              loadingStream = false;
            }
            this.setState({
              loadingStream,
              streamError,
            });
          }, 2000);
        }
      });
      this.player.on('ready', () => {
        const element = this.player.getMediaElement();
        //Sync the timingv3 object and the media element
        if (isArchive)
          TimelineComponent.syncElementToTimingObj(
            element,
            this.state.archiveStreamStartTime * 1000,
          );
      });
    };
    setTimeout(() => createPlayer(config), 1000);
  }

  unrequestStream(channelID, removePlayer = true) {
    if (removePlayer) {
      this.removePlayer();
    }
    const { dispatch, isArchive } = this.props;
    const { archiveStreamKey, streamKey, streamType, archiveStreamStartTime } =
      this.state;
    if (this.archive_stream_message_timer) {
      clearTimeout(this.archive_stream_message_timer);
      this.archive_stream_message_timer = null;
    }
    if (isArchive) {
      if (archiveStreamKey !== '' && archiveStreamStartTime) {
        dispatch({
          type: 'user/archiveVideoStreamOp',
          op: 'unrequest',
          channelID,
          streamKey: archiveStreamKey,
          streamType,
          start_time: archiveStreamStartTime,
          end_time: archiveStreamStartTime + ARCHIVE_STREAM_DURATION_SECS,
        });
      }
    } else {
      dispatch({
        type: 'user/videoStreamOp',
        op: 'unrequest',
        channelID,
        streamKey,
        streamType,
      });
    }
  }

  switchStreams(newStreamType) {
    const { channelID, streamChangeCallback } = this.props;
    const { streamType } = this.state;

    if (_.isEqual(newStreamType, streamType)) {
      return;
    }

    if (streamChangeCallback) {
      streamChangeCallback(channelID, newStreamType);
    }

    // first unrequest previous type before requesting new one
    this.unrequestStream(channelID);
    this.setState({ streamType: newStreamType }, () => this.requestStream());
  }

  getID() {
    const { channelID } = this.props;
    return `${OME_CNT}-${channelID}`;
  }

  getContainerIDForPlayer() {
    const { streamKey } = this.state;
    return this.containerIDPrefix + streamKey;
  }

  render() {
    const { streamSelections, isArchive } = this.props;
    const { streamType, loadingStream, streamError } = this.state;
    return (
      <div className={styles.ctn}>
        <div
          className={styles['video-ctn']}
          id="video-ctn"
          ref={this.videoContainerRef}>
          {streamError !== '' && (
            <div className={styles['stream-failed-ctn']}>
              <div style={{ marginBottom: '10px' }}>
                {streamError.split('\n').map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
              <Button
                onClick={() => {
                  this.setState({
                    loadingStream: true,
                    streamError: '',
                    retryStream: true,
                  });
                  this.requestStream();
                }}
                type="primary">
                Retry
              </Button>
            </div>
          )}
          {loadingStream && (
            <LoadingSpinner
              color="#FFF"
              position="absolute"
              text={isArchive ? 'Loading stream...' : 'Loading Live stream...'}
            />
          )}
          <div
            id={this.getID()}
            ref={this.videoDivRef}
            onClick={() => {}}
            className={`${styles.imagectn} ${OME_CNT}`}>
            <div
              ref={(element) => {
                this.props.innerRef(this.player);
                this.playerRef.current = element;
              }}
              id={this.getContainerIDForPlayer()}
            />
          </div>
        </div>
        <div className={styles['tools-ctn']}>
          <ZoomControls
            videoDivRef={this.videoDivRef}
            videoContainerRef={this.videoContainerRef}
            className={styles['zoom-controls']}
          />
          <div className={styles['res-selector']}>
            <StreamTypeSelector
              streamType={streamType}
              streamSelections={streamSelections}
              onChange={(newStreamType) => this.switchStreams(newStreamType)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => {
  let { locationID, channelID, isArchive } = props;
  const dispatch = useDispatch();
  const ch = useSelector(
    (state: { locations: LocationsModalState }) =>
      state['locations']['ch']['byId'][channelID],
  );

  useEffect(() => {
    if (ch && _.isEmpty(ch.ConfigProfiles)) {
      dispatch({
        type: 'locations/fetchLocation',
        payload: { locationID: locationID },
      });
    }
  }, []);

  const isWebRTCEnabledForLive = _.get(
    ch,
    ['ConfigProfiles', DFConfigKeys.fe_webrtc_enabled, 'values', 'enabled'],
    false,
  );

  const isWebRTCEnabledForArchive = _.get(
    ch,
    [
      'ConfigProfiles',
      DFConfigKeys.fe_webrtc_archive_enabled,
      'values',
      'enabled',
    ],
    false,
  );

  if (
    (!isArchive && isWebRTCEnabledForLive) ||
    (isArchive && isWebRTCEnabledForArchive)
  ) {
    return (
      <WebRTCVideoPlayer
        {...props}
        playTObjAfterLoading={props.TimelineComponent.playTObjAfterLoading}
        pauseTObjForLoading={props.TimelineComponent.pauseTObjForLoading}
      />
    );
  }

  return ch && _.isEmpty(ch.ConfigProfiles) ? null : (
    <ChannelVideoStream innerRef={ref} {...props} />
  );
});
