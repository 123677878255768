import { Cascader } from 'antd/lib';
import { CascaderProps, DefaultOptionType } from 'antd/lib/cascader';
import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { ChannelCascaderValue, ChannelSelectorCascaderProps } from './types';
import { getFullCascaderPaths, updateCascaderOptions } from './utils';

export const ChannelSelectCascaderFragment_ChannelsBySites = graphql`
  fragment ChannelSelectCascaderFragment_ChannelsBySites on SiteConnection {
    edges {
      node {
        Name
        SiteID
        Channels(sort: [NAME_ASC]) {
          edges {
            node {
              ChannelID
              Name
            }
          }
        }
      }
    }
  }
`;

export const ChannelSelectCascaderFragment_ChannelsBySiteGroups = graphql`
  fragment ChannelSelectCascaderFragment_ChannelsBySiteGroups on LabelConnection {
    edges {
      node {
        Name
        LabelID
        Sites(sort: [NAME_ASC]) {
          edges {
            node {
              Name
              SiteID
              Channels(sort: [NAME_ASC]) {
                edges {
                  node {
                    ChannelID
                    Name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ChannelSelectCascader = ({
  value,
  channelsBySiteGroupsFragmentRef,
  channelsBySitesFragmentRef,
  onChange,
  ...restProps
}: ChannelSelectorCascaderProps) => {
  const sitesData = useFragment(
    ChannelSelectCascaderFragment_ChannelsBySites,
    channelsBySitesFragmentRef,
  );
  const siteGroupsData = useFragment(
    ChannelSelectCascaderFragment_ChannelsBySiteGroups,
    channelsBySiteGroupsFragmentRef,
  );

  const siteGroupOptions: DefaultOptionType[] =
    siteGroupsData?.edges.map((siteGroup) => ({
      value: siteGroup?.node?.LabelID,
      label: siteGroup?.node?.Name,
      disabled: !siteGroup?.node?.Sites?.edges.length,
      children: siteGroup?.node?.Sites?.edges.map((site) => ({
        value: site?.node?.SiteID,
        label: site?.node?.Name,
        disabled: !site?.node?.Channels?.edges.length,
        children: site?.node?.Channels?.edges.map((channel) => ({
          value: channel?.node?.ChannelID,
          label: channel?.node?.Name,
        })) as DefaultOptionType[],
      })) as DefaultOptionType[],
    })) ?? [];

  const siteOptions: DefaultOptionType[] =
    sitesData?.edges.map((site) => ({
      value: site?.node?.SiteID,
      label: site?.node?.Name,
      disabled: !site?.node?.Channels?.edges.length,
      children: site?.node?.Channels?.edges.map((channel) => ({
        value: channel?.node?.ChannelID,
        label: channel?.node?.Name,
      })) as DefaultOptionType[],
    })) ?? [];

  const channelOptions: DefaultOptionType[] = [
    {
      value: 'sites',
      label: 'Sites',
      children: siteOptions,
    },
    {
      value: 'siteGroups',
      label: 'Site Groups',
      children: siteGroupOptions,
    },
  ];

  const handleChange: CascaderProps<
    DefaultOptionType,
    'value',
    true
  >['onChange'] = (selectedPaths: ChannelCascaderValue[]) => {
    onChange?.(selectedPaths);
  };

  const valuesStringified =
    value?.map((v) => v.map((w) => (!!w ? w.toString() : w))) ?? [];

  const pathsValues = useMemo(
    () =>
      getFullCascaderPaths(valuesStringified, channelOptions) as (
        | string
        | number
        | null
      )[][],
    [valuesStringified],
  );

  const updatedChannelOptions = useMemo(
    () => updateCascaderOptions(channelOptions, pathsValues),
    [pathsValues],
  );

  return (
    <Cascader
      value={pathsValues}
      options={updatedChannelOptions}
      onChange={handleChange}
      multiple
      showCheckedStrategy={Cascader.SHOW_CHILD}
      showSearch={true}
      dropdownStyle={{ zIndex: 2000 }}
      {...restProps}
    />
  );
};

export { ChannelSelectCascader };
