/**
 * @generated SignedSource<<fbecbadfd0fab9407a1e215c55222841>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChannelTileAndName_Channel$data = {
  readonly ChannelID: number;
  readonly LatestMedia: string | null | undefined;
  readonly MonitorStatus: string | null | undefined;
  readonly Name: string | null | undefined;
  readonly Project: {
    readonly Site: {
      readonly SiteID: number;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ChannelTileAndName_Channel";
};
export type ChannelTileAndName_Channel$key = {
  readonly " $data"?: ChannelTileAndName_Channel$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChannelTileAndName_Channel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChannelTileAndName_Channel",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "Name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ChannelID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "MonitorStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "LatestMedia",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "Project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Site",
          "kind": "LinkedField",
          "name": "Site",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "SiteID",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Channel",
  "abstractKey": null
};

(node as any).hash = "4ef5ea98dc282424584c055f2ef55338";

export default node;
