/**
 * @generated SignedSource<<038d8ef2d3c14ee81820ec306a6e0d0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamScenesRootQuery$variables = {
  appId: number;
  customerId: number;
};
export type TeamScenesRootQuery$data = {
  readonly monitorTeamScenes: {
    readonly teamScenesData: {
      readonly __id: string;
      readonly " $fragmentSpreads": FragmentRefs<"TeamScenesFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type TeamScenesRootQuery = {
  response: TeamScenesRootQuery$data;
  variables: TeamScenesRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customerId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "appId",
  "variableName": "appId"
},
v2 = {
  "kind": "Variable",
  "name": "customerId",
  "variableName": "customerId"
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v5 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": "TIMESTAMP_DESC"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamScenesRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "MonitorTeamScenes",
        "kind": "LinkedField",
        "name": "monitorTeamScenes",
        "plural": false,
        "selections": [
          {
            "alias": "teamScenesData",
            "args": null,
            "concreteType": "MonitorTeamScenesData",
            "kind": "LinkedField",
            "name": "scenesData",
            "plural": false,
            "selections": [
              {
                "args": (v3/*: any*/),
                "kind": "FragmentSpread",
                "name": "TeamScenesFragment"
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeamScenesRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "MonitorTeamScenes",
        "kind": "LinkedField",
        "name": "monitorTeamScenes",
        "plural": false,
        "selections": [
          {
            "alias": "teamScenesData",
            "args": null,
            "concreteType": "MonitorTeamScenesData",
            "kind": "LinkedField",
            "name": "scenesData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "MonitorSceneConnection",
                "kind": "LinkedField",
                "name": "scenes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MonitorSceneEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MonitorScene",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isFavorite",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "timestamp",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "anchorTimeStamp",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdBy",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isSharedWithTeam",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Channel",
                            "kind": "LinkedField",
                            "name": "channels",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ChannelID",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "Name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "MonitorStatus",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "LatestMedia",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Project",
                                "kind": "LinkedField",
                                "name": "Project",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "Site",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "SiteID",
                                        "storageKey": null
                                      },
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [
                  "sort",
                  "appId",
                  "customerId"
                ],
                "handle": "connection",
                "key": "TeamScenesConnection_scenes",
                "kind": "LinkedHandle",
                "name": "scenes"
              },
              (v6/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1224322c8a81a2126b4b337ccd575f33",
    "id": null,
    "metadata": {},
    "name": "TeamScenesRootQuery",
    "operationKind": "query",
    "text": "query TeamScenesRootQuery(\n  $appId: Int!\n  $customerId: Int!\n) {\n  monitorTeamScenes(appId: $appId, customerId: $customerId) {\n    teamScenesData: scenesData {\n      ...TeamScenesFragment_39zX9\n      id\n    }\n    id\n  }\n}\n\nfragment ChannelTileAndName_Channel on Channel {\n  Name\n  ChannelID\n  MonitorStatus\n  LatestMedia\n  Project {\n    Site {\n      SiteID\n      id\n    }\n    id\n  }\n}\n\nfragment SceneFragment on MonitorScene {\n  id\n  name\n  isFavorite\n  timestamp\n  anchorTimeStamp\n  createdBy\n  isSharedWithTeam\n  channels {\n    ChannelID\n    Name\n    ...ChannelTileAndName_Channel\n    ...TileThumbnailChannelsMedia\n    id\n  }\n}\n\nfragment TeamScenesFragment_39zX9 on MonitorTeamScenesData {\n  scenes(first: 10, sort: TIMESTAMP_DESC, appId: $appId, customerId: $customerId) {\n    edges {\n      node {\n        id\n        ...SceneFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n\nfragment TileThumbnailChannelsMedia on Channel {\n  LatestMedia\n}\n"
  }
};
})();

(node as any).hash = "7e9def5f70a21644f97d7342e9cbb64f";

export default node;
