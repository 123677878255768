import { Select } from 'antd';
import { STREAM_QUALITY, STREAM_QUALITY_LABELS } from './constants';
import { StreamQuality } from './types';

const { Option } = Select;

interface StreamQualitySelectorProps {
  value?: StreamQuality;
  onChange: (value: StreamQuality) => void;
}

const DROPDOWN_OPTIONS = [
  STREAM_QUALITY.DEFAULT,
  STREAM_QUALITY.LOW,
  STREAM_QUALITY.MEDIUM,
  STREAM_QUALITY.HIGH,
];

const StreamQualitySelector = ({
  value = STREAM_QUALITY.DEFAULT,
  onChange,
}: StreamQualitySelectorProps) => {
  return (
    <Select value={value} onChange={onChange} placeholder="Select Resolution">
      {DROPDOWN_OPTIONS.map((quality) => (
        <Option key={quality} value={quality}>
          {STREAM_QUALITY_LABELS[quality]}
        </Option>
      ))}
    </Select>
  );
};

export { StreamQualitySelector };
