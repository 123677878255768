/**
 * @generated SignedSource<<f7d1d3f18a72078780a68a27d82bffcc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, FragmentRefs } from 'relay-runtime';
export type MyScenesRootQuery$variables = {
  appId: number;
  customerId: number;
};
export type MyScenesRootQuery$data = {
  readonly monitorUserScenes:
    | {
        readonly userScenesData:
          | {
              readonly __id: string;
              readonly ' $fragmentSpreads': FragmentRefs<'MyScenesFragment'>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};
export type MyScenesRootQuery = {
  response: MyScenesRootQuery$data;
  variables: MyScenesRootQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'appId',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'customerId',
      },
    ],
    v1 = {
      kind: 'Variable',
      name: 'appId',
      variableName: 'appId',
    },
    v2 = {
      kind: 'Variable',
      name: 'customerId',
      variableName: 'customerId',
    },
    v3 = [v1 /*: any*/, v2 /*: any*/],
    v4 = {
      kind: 'ClientExtension',
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: '__id',
          storageKey: null,
        },
      ],
    },
    v5 = [
      v1 /*: any*/,
      v2 /*: any*/,
      {
        kind: 'Literal',
        name: 'first',
        value: 10,
      },
      {
        kind: 'Literal',
        name: 'sort',
        value: 'TIMESTAMP_DESC',
      },
    ],
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'MyScenesRootQuery',
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'MonitorUserScenes',
          kind: 'LinkedField',
          name: 'monitorUserScenes',
          plural: false,
          selections: [
            {
              alias: 'userScenesData',
              args: null,
              concreteType: 'MonitorUserScenesData',
              kind: 'LinkedField',
              name: 'scenesData',
              plural: false,
              selections: [
                {
                  args: v3 /*: any*/,
                  kind: 'FragmentSpread',
                  name: 'MyScenesFragment',
                },
                v4 /*: any*/,
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'MyScenesRootQuery',
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'MonitorUserScenes',
          kind: 'LinkedField',
          name: 'monitorUserScenes',
          plural: false,
          selections: [
            {
              alias: 'userScenesData',
              args: null,
              concreteType: 'MonitorUserScenesData',
              kind: 'LinkedField',
              name: 'scenesData',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: v5 /*: any*/,
                  concreteType: 'MonitorSceneConnection',
                  kind: 'LinkedField',
                  name: 'scenes',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'MonitorSceneEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'MonitorScene',
                          kind: 'LinkedField',
                          name: 'node',
                          plural: false,
                          selections: [
                            v6 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'name',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'isFavorite',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'timestamp',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'anchorTimeStamp',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'createdBy',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'isSharedWithTeam',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              concreteType: 'Channel',
                              kind: 'LinkedField',
                              name: 'channels',
                              plural: true,
                              selections: [
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'ChannelID',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'Name',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'MonitorStatus',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  kind: 'ScalarField',
                                  name: 'LatestMedia',
                                  storageKey: null,
                                },
                                {
                                  alias: null,
                                  args: null,
                                  concreteType: 'Project',
                                  kind: 'LinkedField',
                                  name: 'Project',
                                  plural: false,
                                  selections: [
                                    {
                                      alias: null,
                                      args: null,
                                      concreteType: 'Site',
                                      kind: 'LinkedField',
                                      name: 'Site',
                                      plural: false,
                                      selections: [
                                        {
                                          alias: null,
                                          args: null,
                                          kind: 'ScalarField',
                                          name: 'SiteID',
                                          storageKey: null,
                                        },
                                        v6 /*: any*/,
                                      ],
                                      storageKey: null,
                                    },
                                    v6 /*: any*/,
                                  ],
                                  storageKey: null,
                                },
                                v6 /*: any*/,
                              ],
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: '__typename',
                              storageKey: null,
                            },
                          ],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'cursor',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'PageInfo',
                      kind: 'LinkedField',
                      name: 'pageInfo',
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'startCursor',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'endCursor',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'hasNextPage',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'hasPreviousPage',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                    v4 /*: any*/,
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: v5 /*: any*/,
                  filters: ['sort', 'appId', 'customerId'],
                  handle: 'connection',
                  key: 'MyScenesConnection_scenes',
                  kind: 'LinkedHandle',
                  name: 'scenes',
                },
                v6 /*: any*/,
                v4 /*: any*/,
              ],
              storageKey: null,
            },
            v6 /*: any*/,
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'cb2bb1f8b097fe579ce786b9eaec1f09',
      id: null,
      metadata: {},
      name: 'MyScenesRootQuery',
      operationKind: 'query',
      text: 'query MyScenesRootQuery(\n  $appId: Int!\n  $customerId: Int!\n) {\n  monitorUserScenes(appId: $appId, customerId: $customerId) {\n    userScenesData: scenesData {\n      ...MyScenesFragment_39zX9\n      id\n    }\n    id\n  }\n}\n\nfragment ChannelTileAndName_Channel on Channel {\n  Name\n  ChannelID\n  MonitorStatus\n  LatestMedia\n  Project {\n    Site {\n      SiteID\n      id\n    }\n    id\n  }\n}\n\nfragment MyScenesFragment_39zX9 on MonitorUserScenesData {\n  scenes(first: 10, sort: TIMESTAMP_DESC, appId: $appId, customerId: $customerId) {\n    edges {\n      node {\n        id\n        ...SceneFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n\nfragment SceneFragment on MonitorScene {\n  id\n  name\n  isFavorite\n  timestamp\n  anchorTimeStamp\n  createdBy\n  isSharedWithTeam\n  channels {\n    ChannelID\n    Name\n    ...ChannelTileAndName_Channel\n    ...TileThumbnailChannelsMedia\n    id\n  }\n}\n\nfragment TileThumbnailChannelsMedia on Channel {\n  LatestMedia\n}\n',
    },
  };
})();

(node as any).hash = '8872dd4ac2e9f2160728c4de6f969046';

export default node;
