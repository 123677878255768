/**
 * @generated SignedSource<<a8f25205f50850d5cb96887ae79b9101>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type CreateUpdateSite_labels$data = {
  readonly edges: ReadonlyArray<
    | {
        readonly node:
          | {
              readonly Color: string | null | undefined;
              readonly LabelID: number;
              readonly Name: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
  readonly ' $fragmentType': 'CreateUpdateSite_labels';
};
export type CreateUpdateSite_labels$key = {
  readonly ' $data'?: CreateUpdateSite_labels$data;
  readonly ' $fragmentSpreads': FragmentRefs<'CreateUpdateSite_labels'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'CreateUpdateSite_labels',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'LabelEdge',
      kind: 'LinkedField',
      name: 'edges',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Label',
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              kind: 'RequiredField',
              field: {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'Name',
                storageKey: null,
              },
              action: 'LOG',
              path: 'edges.node.Name',
            },
            {
              kind: 'RequiredField',
              field: {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'LabelID',
                storageKey: null,
              },
              action: 'LOG',
              path: 'edges.node.LabelID',
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'Color',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ],
  type: 'LabelConnection',
  abstractKey: null,
};

(node as any).hash = '05c1335aac4e707091315fc2d9478e58';

export default node;
