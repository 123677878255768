/**
 * @generated SignedSource<<0ee182c29cb4b26be8d5eb283fb43cc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Sites_sites$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly AddressInfo: {
        readonly City: string | null | undefined;
        readonly Country: string | null | undefined;
        readonly State: string | null | undefined;
        readonly id: string;
      } | null | undefined;
      readonly ExternalID: string | null | undefined;
      readonly HasContacts: boolean | null | undefined;
      readonly HasEscalationPath: boolean | null | undefined;
      readonly HasShippingAddress: boolean | null | undefined;
      readonly Labels: ReadonlyArray<{
        readonly Color: string | null | undefined;
        readonly LabelID: number;
        readonly Name: string;
        readonly id: string;
      } | null | undefined> | null | undefined;
      readonly Name: string;
      readonly SiteID: number;
      readonly id: string;
    } | null | undefined;
  } | null | undefined>;
  readonly " $fragmentType": "Sites_sites";
};
export type Sites_sites$key = {
  readonly " $data"?: Sites_sites$data;
  readonly " $fragmentSpreads": FragmentRefs<"Sites_sites">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Sites_sites",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Site",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "SiteID",
                "storageKey": null
              },
              "action": "LOG",
              "path": "edges.node.SiteID"
            },
            {
              "kind": "RequiredField",
              "field": (v1/*: any*/),
              "action": "LOG",
              "path": "edges.node.Name"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ExternalID",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AddressInfo",
              "kind": "LinkedField",
              "name": "AddressInfo",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "City",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "State",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Country",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Label",
              "kind": "LinkedField",
              "name": "Labels",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "LabelID",
                    "storageKey": null
                  },
                  "action": "LOG",
                  "path": "edges.node.Labels.LabelID"
                },
                {
                  "kind": "RequiredField",
                  "field": (v1/*: any*/),
                  "action": "LOG",
                  "path": "edges.node.Labels.Name"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "Color",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "HasShippingAddress",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "HasEscalationPath",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "HasContacts",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__id",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "SiteConnection",
  "abstractKey": null
};
})();

(node as any).hash = "4d2b0cb2ee39494b5e7b1847d39850b3";

export default node;
