import { ReactComponent as MoveHandle } from '@/assets/move-handle.svg';
import { type UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState, type ReactNode } from 'react';
import { DragHandle, SortableChannelTileWrapperContainer } from './styles';

const SortableChannelTileWrapper = ({
  id,
  children,
}: {
  id: UniqueIdentifier;
  children: ReactNode;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const [isHovered, setIsHovered] = useState(false);

  return (
    <SortableChannelTileWrapperContainer
      ref={setNodeRef}
      $transform={CSS.Transform.toString(transform)}
      $transition={transition}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <DragHandle
        component={MoveHandle}
        $isVisible={isHovered}
        {...listeners}
        {...attributes}
      />

      {children}
    </SortableChannelTileWrapperContainer>
  );
};

export default SortableChannelTileWrapper;
