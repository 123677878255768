// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/__w/df-client/df-client/src/models/access_rules';
import model_2 from '/__w/df-client/df-client/src/models/accounts';
import model_3 from '/__w/df-client/df-client/src/models/apps';
import model_4 from '/__w/df-client/df-client/src/models/channel';
import model_5 from '/__w/df-client/df-client/src/models/global';
import model_6 from '/__w/df-client/df-client/src/models/insights';
import model_7 from '/__w/df-client/df-client/src/models/investigation_event';
import model_8 from '/__w/df-client/df-client/src/models/investigation';
import model_9 from '/__w/df-client/df-client/src/models/lables';
import model_10 from '/__w/df-client/df-client/src/models/location_map';
import model_11 from '/__w/df-client/df-client/src/models/location';
import model_12 from '/__w/df-client/df-client/src/models/monitor_search';
import model_13 from '/__w/df-client/df-client/src/models/scopes';
import model_14 from '/__w/df-client/df-client/src/models/search_filter';
import model_15 from '/__w/df-client/df-client/src/models/search2';
import model_16 from '/__w/df-client/df-client/src/models/site';
import model_17 from '/__w/df-client/df-client/src/models/user';
import model_18 from '/__w/df-client/df-client/src/models/view';
import model_19 from '/__w/df-client/df-client/src/pages/apps/app/AlarmApp/BurglarAlarmApp/models/burglar_alarm';
import model_20 from '/__w/df-client/df-client/src/pages/apps/app/AlarmApp/CameraMonitoringAlarmApp/models/camera_monitoring_alarm';
import model_21 from '/__w/df-client/df-client/src/pages/apps/app/AlarmApp/LprAlarmApp/models/lpr_alarm';
import model_22 from '/__w/df-client/df-client/src/pages/apps/app/AlarmApp/SmokeAndFireAlarmApp/models/smoke_and_fire_alarm';
import model_23 from '/__w/df-client/df-client/src/pages/apps/app/CheckoutInsightsApp/models/checkout-insights';
import model_24 from '/__w/df-client/df-client/src/pages/apps/app/CustomerJourney/models/customer-journey';
import model_25 from '/__w/df-client/df-client/src/pages/apps/app/FleetManagerApp/models/fleet_manager';
import model_26 from '/__w/df-client/df-client/src/pages/apps/app/KaizenApp/models/kaizen';
import model_27 from '/__w/df-client/df-client/src/pages/apps/app/LPRReports/models/lpr_reports';
import model_28 from '/__w/df-client/df-client/src/pages/apps/app/ProductSearchApp/models/product_search';
import model_29 from '/__w/df-client/df-client/src/pages/apps/app/RetailInsights/models/retail_insights';
import model_30 from '/__w/df-client/df-client/src/pages/apps/app/SelfCheckoutLossDetectionApp/models/self_checkout_loss_detection';

export const models = {
model_1: { namespace: 'access_rules', model: model_1 },
model_2: { namespace: 'accounts', model: model_2 },
model_3: { namespace: 'apps', model: model_3 },
model_4: { namespace: 'channel', model: model_4 },
model_5: { namespace: 'global', model: model_5 },
model_6: { namespace: 'insights', model: model_6 },
model_7: { namespace: 'investigation_event', model: model_7 },
model_8: { namespace: 'investigation', model: model_8 },
model_9: { namespace: 'lables', model: model_9 },
model_10: { namespace: 'location_map', model: model_10 },
model_11: { namespace: 'location', model: model_11 },
model_12: { namespace: 'monitor_search', model: model_12 },
model_13: { namespace: 'scopes', model: model_13 },
model_14: { namespace: 'search_filter', model: model_14 },
model_15: { namespace: 'search2', model: model_15 },
model_16: { namespace: 'site', model: model_16 },
model_17: { namespace: 'user', model: model_17 },
model_18: { namespace: 'view', model: model_18 },
model_19: { namespace: 'apps.app.AlarmApp.BurglarAlarmApp.burglar_alarm', model: model_19 },
model_20: { namespace: 'apps.app.AlarmApp.CameraMonitoringAlarmApp.camera_monitoring_alarm', model: model_20 },
model_21: { namespace: 'apps.app.AlarmApp.LprAlarmApp.lpr_alarm', model: model_21 },
model_22: { namespace: 'apps.app.AlarmApp.SmokeAndFireAlarmApp.smoke_and_fire_alarm', model: model_22 },
model_23: { namespace: 'apps.app.CheckoutInsightsApp.checkout-insights', model: model_23 },
model_24: { namespace: 'apps.app.CustomerJourney.customer-journey', model: model_24 },
model_25: { namespace: 'apps.app.FleetManagerApp.fleet_manager', model: model_25 },
model_26: { namespace: 'apps.app.KaizenApp.kaizen', model: model_26 },
model_27: { namespace: 'apps.app.LPRReports.lpr_reports', model: model_27 },
model_28: { namespace: 'apps.app.ProductSearchApp.product_search', model: model_28 },
model_29: { namespace: 'apps.app.RetailInsights.retail_insights', model: model_29 },
model_30: { namespace: 'apps.app.SelfCheckoutLossDetectionApp.self_checkout_loss_detection', model: model_30 },
} as const
