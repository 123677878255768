/**
 * @generated SignedSource<<3efeaeec071f049d256b02c2ff65276b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MonitorMutations_SceneUpdate_Mutation$variables = {
  anchorTimeStamp?: string | null | undefined;
  app_id: number;
  channel_ids: ReadonlyArray<string>;
  customer_id: number;
  id: string;
  isFavorite?: boolean | null | undefined;
  name?: string | null | undefined;
};
export type MonitorMutations_SceneUpdate_Mutation$data = {
  readonly updateExistingScene: {
    readonly sceneEdge: {
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"SceneFragment">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type MonitorMutations_SceneUpdate_Mutation = {
  response: MonitorMutations_SceneUpdate_Mutation$data;
  variables: MonitorMutations_SceneUpdate_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "anchorTimeStamp"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "app_id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "channel_ids"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customer_id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isFavorite"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v7 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "anchorTimeStamp",
        "variableName": "anchorTimeStamp"
      },
      {
        "kind": "Variable",
        "name": "appId",
        "variableName": "app_id"
      },
      {
        "kind": "Variable",
        "name": "channelIDs",
        "variableName": "channel_ids"
      },
      {
        "kind": "Variable",
        "name": "customerId",
        "variableName": "customer_id"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "isFavorite",
        "variableName": "isFavorite"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MonitorMutations_SceneUpdate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "UpdateExistingScenePayload",
        "kind": "LinkedField",
        "name": "updateExistingScene",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MonitorSceneEdge",
            "kind": "LinkedField",
            "name": "sceneEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MonitorScene",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SceneFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MonitorMutations_SceneUpdate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "UpdateExistingScenePayload",
        "kind": "LinkedField",
        "name": "updateExistingScene",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MonitorSceneEdge",
            "kind": "LinkedField",
            "name": "sceneEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MonitorScene",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isFavorite",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timestamp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "anchorTimeStamp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSharedWithTeam",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Channel",
                    "kind": "LinkedField",
                    "name": "channels",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ChannelID",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "Name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "MonitorStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "LatestMedia",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Project",
                        "kind": "LinkedField",
                        "name": "Project",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Site",
                            "kind": "LinkedField",
                            "name": "Site",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "SiteID",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e1350e85c523fc863be562ee08f0f79",
    "id": null,
    "metadata": {},
    "name": "MonitorMutations_SceneUpdate_Mutation",
    "operationKind": "mutation",
    "text": "mutation MonitorMutations_SceneUpdate_Mutation(\n  $app_id: Int!\n  $customer_id: Int!\n  $channel_ids: [String!]!\n  $id: String!\n  $name: String\n  $isFavorite: Boolean\n  $anchorTimeStamp: String\n) {\n  updateExistingScene(input: {appId: $app_id, customerId: $customer_id, channelIDs: $channel_ids, id: $id, name: $name, isFavorite: $isFavorite, anchorTimeStamp: $anchorTimeStamp}) {\n    sceneEdge {\n      node {\n        ...SceneFragment\n        id\n      }\n    }\n  }\n}\n\nfragment ChannelTileAndName_Channel on Channel {\n  Name\n  ChannelID\n  MonitorStatus\n  LatestMedia\n  Project {\n    Site {\n      SiteID\n      id\n    }\n    id\n  }\n}\n\nfragment SceneFragment on MonitorScene {\n  id\n  name\n  isFavorite\n  timestamp\n  anchorTimeStamp\n  createdBy\n  isSharedWithTeam\n  channels {\n    ChannelID\n    Name\n    ...ChannelTileAndName_Channel\n    ...TileThumbnailChannelsMedia\n    id\n  }\n}\n\nfragment TileThumbnailChannelsMedia on Channel {\n  LatestMedia\n}\n"
  }
};
})();

(node as any).hash = "ea7927e6bfc619d00167c3b51ccb7acc";

export default node;
