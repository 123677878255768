export const TABS = {
  ALARMS: 'alarms',
};

export const TABS_ORDER = [TABS.ALARMS];

export const TAB_LABELS = {
  [TABS.ALARMS]: 'Alert Schedules',
};

export enum AlertType {
  CAMERA_DOWN = 'Camera Down',
}

export const ARCHIVED_INCIDENT_PRIORITY = 7;
