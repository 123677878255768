import DataList from '@/components/DataList';
import LabelAndSiteSelector from '@/components/LabelAndSiteSelector';
import LoadingSpinner from '@/components/LoadingSpinner';
import { PauseCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tag } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Dispatch, useDispatch, useSelector } from 'umi';
import styles from '../alarm-list/style.less';

const ITEMS_PER_PAGE = 10;

const DeleteAlarmWrapper: React.FC<{
  alarm_id: number;
  children: React.ReactNode;
  namespace: string;
}> = ({ alarm_id, children, namespace }) => {
  const dispatch = useDispatch<Dispatch>();

  return (
    <Popconfirm
      title="Are you sure?"
      style={{ color: 'red' }}
      onConfirm={() => {
        dispatch({
          type: `${namespace}/deleteAlarm`,
          payload: {
            alarm_id,
          },
        });
      }}
      onCancel={(e) => e?.preventDefault()}
      okText="Yes"
      cancelText="No">
      {children}
    </Popconfirm>
  );
};

interface AlarmData {
  alarm_id: number;
  data: {
    name: string;
    timeframe: any;
  };
  rule: any;
}

interface AlarmListProps {
  app: any;
  namespace: string;
  onEditAlarm: (alarm: any) => void;
  onDuplicateAlarm: (alarmId: number) => void;
  onCreateAlarm: () => void;
}

const AlarmList: React.FC<AlarmListProps> = ({
  app,
  namespace,
  onEditAlarm,
  onDuplicateAlarm,
  onCreateAlarm,
}) => {
  const dispatch = useDispatch();
  const loadingAlarms = useSelector(
    (state: any) => state.loading.effects[`${namespace}/fetchAlarms`],
  );
  const [alarmStatusLoading, setAlarmStatusLoading] = useState<
    Record<number, boolean>
  >({});
  const [filters, setFilters] = useState<Record<string, any>>({});

  const fetchAlarms = async (
    pagination: Record<string, any> = {},
    newFilters: Record<string, any> = {},
    override = false,
  ): Promise<void> => {
    try {
      const _filters = {
        ...(override ? {} : filters),
        ...newFilters,
      };
      const newParamsFetchAlarms = {
        pagination: {
          ...{ p_size: ITEMS_PER_PAGE, p_number: 1 },
          ...pagination,
        },
        filters: _filters,
      };
      const params = {
        ...newParamsFetchAlarms.pagination,
        ...newParamsFetchAlarms.filters,
      };
      setFilters(_filters);
      const response = await dispatch({
        type: `${namespace}/fetchAlarms`,
        params,
      });
      return response;
    } catch (error) {
      console.error('Error fetching alarms:', error);
    }
  };

  useEffect(() => {
    fetchAlarms();
  }, []);

  let filteredAlarms = _.get(app, 'alarms.list', [] as AlarmData[]).sort(
    (a: AlarmData, b: AlarmData) => a.data.name.localeCompare(b.data.name),
  );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '12px',
        }}>
        <LabelAndSiteSelector
          app_id={app.AppID}
          onSitesChange={(value) =>
            fetchAlarms({ p_number: 1 }, { site_ids: value })
          }
        />
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onCreateAlarm}>Create Alert</Button>
        </div>
      </div>
      <DataList
        isLoading={loadingAlarms}
        pagination={{
          p_number: _.get(app, 'alarms.p_number', 1),
          p_size: ITEMS_PER_PAGE,
          total_pages: _.get(app, 'alarms.total_pages', 1),
        }}
        isControlled={true}
        onChange={fetchAlarms}
        columns={[
          {
            title: 'Name',
            render: (record: any) => {
              return (
                <span>
                  <span>
                    {record.rule.code_state == 'disabled' ? (
                      <PauseCircleOutlined />
                    ) : null}
                    {record.step == 'error' ? <WarningOutlined /> : null}
                    &nbsp;
                  </span>
                  <span>{record.data.name}</span>
                </span>
              );
            },
            key: 'name',
          },
          {
            title: 'Time',
            render: (record: any) => {
              return `${record.data.timeframe.time_range.from} - ${record.data.timeframe.time_range.to}`;
            },
            key: 'time',
          },
          {
            title: 'Status',
            render: (record: any) => {
              return (
                <Tag
                  color={record.rule.enabled ? 'green' : 'red'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setAlarmStatusLoading({
                      ...alarmStatusLoading,
                      [record.alarm_id]: true,
                    });
                    dispatch({
                      type: `${namespace}/toggleAlarmStatus`,
                      payload: {
                        alarm_id: record.alarm_id,
                        enabled: !record.rule.enabled,
                      },
                    }).then(() => {
                      setAlarmStatusLoading({
                        ...alarmStatusLoading,
                        [record.alarm_id]: false,
                      });
                    });
                  }}>
                  <div style={{ display: 'flex' }}>
                    <div>{record.rule.enabled ? 'Enabled' : 'Disabled'}</div>
                    {alarmStatusLoading[record.alarm_id] && (
                      <LoadingSpinner fontSize={12} padding={'0px 3px'} />
                    )}
                  </div>
                </Tag>
              );
            },
            key: 'status',
          },
          {
            title: 'Actions',
            key: 'actions',
            render: (record: any) => (
              <div>
                <span onClick={() => onEditAlarm(record)} className="df-link">
                  Edit
                </span>
                &nbsp;&nbsp;
                <span
                  onClick={() => onDuplicateAlarm(record.alarm_id)}
                  className="df-link">
                  Duplicate
                </span>
                &nbsp;&nbsp;
                <DeleteAlarmWrapper
                  alarm_id={record.alarm_id}
                  namespace={namespace}>
                  <span className="df-link df-error-text">Delete</span>
                </DeleteAlarmWrapper>
              </div>
            ),
          },
        ]}
        dataList={filteredAlarms}
        cardMap={{
          content: (record: AlarmData) => {
            return (
              <div className={styles['alarm-card']}>
                <div className={styles['alarm-card-header']}>
                  <div
                    style={{
                      fontWeight: '500',
                      fontSize: '14px',
                      padding: '4px',
                      textAlign: 'center',
                    }}>
                    {record.data.name}
                  </div>
                  <div style={{ fontSize: '10px' }}>
                    {_.get(record.rule, 'sources.channels[0].name')}
                  </div>
                  <div style={{ padding: '4px' }}>
                    <Tag
                      color={record.rule.enabled ? 'green' : 'red'}
                      style={{ cursor: 'pointer', margin: '0px' }}
                      onClick={() => {
                        setAlarmStatusLoading({
                          ...alarmStatusLoading,
                          [record.alarm_id]: true,
                        });
                        dispatch({
                          type: `${namespace}/toggleAlarmStatus`,
                          payload: {
                            alarm_id: record.alarm_id,
                            enabled: !record.rule.enabled,
                          },
                        }).then(() => {
                          setAlarmStatusLoading({
                            ...alarmStatusLoading,
                            [record.alarm_id]: false,
                          });
                        });
                      }}>
                      <div style={{ display: 'flex' }}>
                        <div>
                          {record.rule.enabled ? 'Enabled' : 'Disabled'}
                        </div>
                        {alarmStatusLoading[record.alarm_id] && (
                          <LoadingSpinner fontSize={12} padding={'0px 3px'} />
                        )}
                      </div>
                    </Tag>
                  </div>
                </div>
                <div className={styles['alarm-card-footer']}>
                  <div style={{ fontSize: '12px', padding: '4px' }}>
                    {_.get(record, 'data.timeframe.time_range.from').slice(
                      0,
                      5,
                    )}{' '}
                    to{' '}
                    {_.get(record, 'data.timeframe.time_range.to').slice(0, 5)}{' '}
                    {_.get(record, 'data.timeframe.days')
                      .map((d: string) => _.capitalize(d).slice(0, 2))
                      .join(', ')}
                  </div>
                  <div>
                    <div></div>
                    <div></div>
                  </div>
                  <div>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => onEditAlarm(record)}>
                      Edit
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      onClick={() => onDuplicateAlarm(record.alarm_id)}
                      size="small">
                      Duplicate
                    </Button>
                    &nbsp;&nbsp;
                    <DeleteAlarmWrapper
                      alarm_id={record.alarm_id}
                      namespace={namespace}>
                      <Button size="small" type="primary" danger>
                        Delete
                      </Button>
                    </DeleteAlarmWrapper>
                  </div>
                </div>
              </div>
            );
          },
        }}
      />
    </div>
  );
};

export default AlarmList;
