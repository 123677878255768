import { t_video_size_from_media } from '@/constants';
import { Button } from 'antd';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import React from 'react';

import CalibrationControls from './CalibrationControls';
import styles from './style.less';

PIXI.utils.skipHello();
const isWebGLSupported = PIXI.utils.isWebGLSupported();

type State = any;
type Props = any;

class RegionFilters extends React.Component<Props, State> {
  filterPixi: any;
  imageRef: any;
  regionRef: any;
  constructor(props: {}) {
    super(props);
    this.state = {
      regions: _.get(this.props, 'defaultFilterValue', []),
      canvasRegions: [],
      activeRegion: 0,
      showCalibration: false,
      noFiltersActive: _.get(this.props, 'noFiltersActive', true),
    };
    if (isWebGLSupported) {
      this.filterPixi = new PIXI.Application({
        sharedLoader: true,
        antialias: true,
        backgroundAlpha: 0,
        height: 3000,
        width: 3000,
      });
    }
    this.imageRef = React.createRef({});
    this.regionRef = React.createRef({});
  }

  componentDidMount() {}

  componentDidUpdate(prevProps: Props) {
    if (prevProps.noFiltersActive !== this.props.noFiltersActive) {
      this.setState({
        noFiltersActive: this.props.noFiltersActive,
      });
    }
  }

  onThumbnailLoaded() {
    this.regionRef.current.appendChild(this.filterPixi.view);
    this.initFilterPixi(this.regionRef.current);
  }

  componentWillUnmount() {
    this.filterPixi.destroy(true);
  }

  updateFilters() {
    this.props.updateFilters(
      _.cloneDeep(
        this.state.regions.filter((region: any) => Array.isArray(region)),
      ),
    );
  }

  createPoint = (x, y, index) => {
    const point = new PIXI.Graphics();
    point.beginFill(0xffffff);
    point.drawCircle(0, 0, 4);
    point.position.set(x, y);
    point.zIndex = 4;
    point.endFill();
    point.relativeIndex = index;
    this.addInteraction(point);
    this.addPointHoverEnlarge(point);
    this.filterPixi.stage.addChild(point);
    return point;
  };

  createInterpoint = (index) => {
    const interpoint = new PIXI.Graphics();
    interpoint.lineStyle(1, 0xffffff, 0.3);
    interpoint.beginFill(0xffffff, 0.1);
    interpoint.drawCircle(0, 0, 4);
    interpoint.endFill();
    interpoint.zIndex = 3;
    interpoint.relativeIndex = index;
    this.addAddInteraction(interpoint);
    this.filterPixi.stage.addChild(interpoint);
    return interpoint;
  };

  initFilterPixi(element: any) {
    const { media, baseStationVersion } = this.props;
    const { clientHeight, clientWidth } = element;
    if (clientHeight > 0 && clientWidth > 0) {
      // clear stage
      this.filterPixi.stage.removeChildren();

      // resize image to fit to div
      const thumbAspectRatio =
        this.imageRef.current.naturalWidth /
        this.imageRef.current.naturalHeight;

      const containerAspectRation = clientWidth / clientHeight;
      let height: any;
      let width: any;
      if (thumbAspectRatio <= containerAspectRation) {
        height = clientHeight;
        width = thumbAspectRatio * clientHeight;
      } else {
        width = clientWidth;
        height = clientWidth / thumbAspectRatio;
      }

      // resize canvas/view to resized image
      this.filterPixi.renderer.view.width = width;
      this.filterPixi.renderer.view.height = height;
      this.filterPixi.stage.interactive = true;

      const regionFilterRegions = _.get(
        this.state,
        ['regions', this.state.activeRegion],
        [],
      );
      let regionCordinates;
      if (regionFilterRegions.length > 0) {
        const [transcodedVideoWidth, transcodedVideoHeight] =
          t_video_size_from_media(media, baseStationVersion);

        regionCordinates = regionFilterRegions.map((p: any) => [
          (p[0] / transcodedVideoWidth) * width,
          (p[1] / transcodedVideoHeight) * height,
        ]);
        // transform to this coordinate system
      } else {
        regionCordinates = [
          [width / 3, height / 3],
          [width - width / 3, height / 3],
          [width - width / 3, height - height / 3],
          [width / 3, height - height / 3],
        ];
      }

      const regionPts: any = [];
      const interPts: any = [];

      const regionGraphic = new PIXI.Graphics();
      regionGraphic['isRegion'] = true;
      this.addInteraction(regionGraphic);
      this.setState({ regionGraphic }, () => {
        this.updateRegion(false);
      });
      this.filterPixi.stage.addChild(regionGraphic);

      regionCordinates.forEach((pt: any, index: any) => {
        const point = this.createPoint(pt[0], pt[1], index);
        regionPts[index] = point;

        const interpoint = this.createInterpoint(index);
        interPts[index] = interpoint;
      });

      const canvasRegions = [...this.state.canvasRegions];
      canvasRegions[this.state.activeRegion] = regionPts;
      this.setState({ canvasRegions, interPts });
    }
  }

  onDragStart = (e: any) => {
    const obj = e.currentTarget;
    obj.cursor = obj.isRegion ? 'move' : 'grabbing';
    obj.dragData = e.data;
    obj.dragging = 1;
    obj.anchorPointerStart = e.data.getLocalPosition(obj.parent);
    if (obj.isRegion) {
      obj.dragObjStart = obj.clone();
    } else {
      obj.dragObjStart = new PIXI.Point();
      obj.dragObjStart.copyFrom(obj.position);
    }
    const { canvasRegions, interPts, activeRegion } = this.state;
    const region = canvasRegions[activeRegion];
    if (obj.isRegion) {
      region.forEach((pt) => {
        pt.visible = false;
      });
      interPts.forEach((pt) => {
        pt.visible = false;
      });
    }
    obj.dragGlobalStart = new PIXI.Point();
    obj.dragGlobalStart.copyFrom(e.data.global);
  };

  onDragMove = (e: any) => {
    const obj = e.currentTarget;
    if (!obj.dragging) return;
    const data = obj.dragData; // it can be different pointer!
    if (obj.dragging === 1) {
      // click or drag?
      if (
        Math.abs(data.global.x - obj.dragGlobalStart.x) +
          Math.abs(data.global.y - obj.dragGlobalStart.y) >=
        3
      ) {
        // DRAG
        obj.dragging = 2;
      } else {
        // CLICK
      }
    }
    if (obj.dragging === 2) {
      const anchorPointerEnd = data.getLocalPosition(obj.parent);
      // DRAG
      obj.position.set(
        obj.dragObjStart.x + (anchorPointerEnd.x - obj.anchorPointerStart.x),
        obj.dragObjStart.y + (anchorPointerEnd.y - obj.anchorPointerStart.y),
      );
      if (!obj.isRegion) {
        this.updateRegion();
      }
    }
  };

  onDragEnd = (e: any) => {
    const obj = e.currentTarget;
    obj.cursor = 'pointer';
    const { canvasRegions, activeRegion, interPts, regionGraphic } = this.state;
    const region = canvasRegions[activeRegion];
    if (obj.isRegion) {
      region.forEach((pt) => {
        if (obj.dragging === 2) {
          pt.position.set(
            pt.position.x + obj.position.x,
            pt.position.y + obj.position.y,
          );
        }
        pt.visible = true;
      });
      interPts.forEach((pt, index) => {
        if (obj.dragging === 2) {
          const previ = index ? index - 1 : region.length - 1;
          pt.relativeIndex = index;
          pt.position.set(
            (region[index].position.x + region[previ].position.x) / 2,
            (region[index].position.y + region[previ].position.y) / 2,
          );
        }
        pt.visible = true;
      });
      regionGraphic.position.set(0, 0);
      this.updateRegion();
    }
    // set the interaction data to null
    obj.dragData = null;
    if (obj.dragging === 1 && !obj.isRegion) {
      this.removePoint(e);
    }
    // ensure state is flushed after the point removal above
    this.setState({}, () => this.updateFilters());
    obj.dragging = 0;
  };

  addPoint = (e: any) => {
    const obj = e.currentTarget;

    const { activeRegion, interPts } = this.state;
    const canvasRegions = [...this.state.canvasRegions];
    const regionPts = canvasRegions[activeRegion];

    const i = obj.relativeIndex;
    const previ = i ? i - 1 : regionPts.length - 1;
    const x = (regionPts[i].position.x + regionPts[previ].position.x) / 2;
    const y = (regionPts[i].position.y + regionPts[previ].position.y) / 2;

    const point = this.createPoint(x, y, i);
    const interpoint = this.createInterpoint(regionPts.length);

    interPts.push(interpoint);
    regionPts.splice(i, 0, point);
    regionPts.forEach((regionPt, index) => (regionPt.relativeIndex = index));

    canvasRegions[this.state.activeRegion] = regionPts;
    this.setState({ canvasRegions, interPts }, () => this.updateRegion());
  };

  removePoint = (e: any) => {
    const obj = e.currentTarget;

    const { activeRegion, interPts } = this.state;
    const canvasRegions = [...this.state.canvasRegions];
    const regionPts = canvasRegions[activeRegion];

    // at least a triangle
    if (regionPts.length < 4) {
      return;
    }

    let i = obj.relativeIndex;
    regionPts
      .splice(i, 1)
      .forEach((pt) => this.filterPixi.stage.removeChild(pt));
    // fix index
    regionPts.forEach((pt, index) => (pt.relativeIndex = index));
    interPts
      .splice(i, 1)
      .forEach((pt) => this.filterPixi.stage.removeChild(pt));

    canvasRegions[this.state.activeRegion] = regionPts;
    this.setState({ canvasRegions, interPts }, () => this.updateRegion());
  };

  addAddInteraction = (obj: any) => {
    obj.interactive = true;
    obj.buttonMode = true;
    obj.on('click', this.addPoint);
    this.addPointHoverEnlarge(obj);
  };

  addPointHoverEnlarge = (obj) => {
    // enlarge the point when we hover
    obj.on('mouseover', () => {
      obj.scale.set(1.33, 1.33);
    });
    // return to normal size when we leave
    obj.on('mouseout', () => {
      obj.scale.set(1, 1);
    });
  };

  addInteraction = (obj: any) => {
    obj.interactive = true;
    obj.buttonMode = true;
    obj
      .on('pointerdown', this.onDragStart)
      .on('pointerup', this.onDragEnd)
      .on('pointerupoutside', this.onDragEnd)
      .on('pointermove', this.onDragMove);
  };

  updateRegion = (setValue = true) => {
    const { regionGraphic, canvasRegions, activeRegion, interPts } = this.state;
    const regionPts = canvasRegions[activeRegion];
    // let startPt = [regionPts[0].position.x, regionPts[0].position.y];
    // let endPt = [regionPts[1].position.x, regionPts[1].position.y];
    regionGraphic.clear();
    regionGraphic.beginFill(0x000000, 0.2);
    regionGraphic.zIndex = 2;
    regionGraphic.lineStyle(1, 0xffffff, 0.5);
    const poly: any = [];
    regionPts.forEach((pt: any) => {
      poly.push([pt.position.x, pt.position.y]);
    });
    interPts.forEach((pt, index) => {
      const previ = index ? index - 1 : regionPts.length - 1;
      pt.relativeIndex = index;
      pt.position.set(
        (regionPts[index].position.x + regionPts[previ].position.x) / 2,
        (regionPts[index].position.y + regionPts[previ].position.y) / 2,
      );
    });
    regionGraphic.drawPolygon(..._.flatten(poly));

    // transform to transcoded video coordinate system
    const { media, baseStationVersion } = this.props;
    const { width, height } = this.filterPixi.renderer.view;

    const [transcodedVideoWidth, transcodedVideoHeight] =
      t_video_size_from_media(media, baseStationVersion);

    const regionCordinates = regionPts.map((pt: any) => [
      (pt.position.x / width) * transcodedVideoWidth,
      (pt.position.y / height) * transcodedVideoHeight,
    ]);

    const _regions = [...this.state.regions];
    _regions[this.state.activeRegion] = regionCordinates;
    if (setValue) this.setState({ regions: _regions });
    regionGraphic.endFill();
  };

  resetRegionFilter = () => {
    const { clientHeight, clientWidth } = this.regionRef.current;
    const thumbAspectRatio =
      this.imageRef.current.naturalWidth / this.imageRef.current.naturalHeight;
    const containerAspectRation = clientWidth / clientHeight;
    let height;
    let width;
    if (thumbAspectRatio <= containerAspectRation) {
      height = clientHeight;
      width = thumbAspectRatio * clientHeight;
    } else {
      width = clientWidth;
      height = clientWidth / thumbAspectRatio;
    }
    const regionCordinates = [
      [width / 3, height / 3],
      [width - width / 3, height / 3],
      [width - width / 3, height - height / 3],
      [width / 3, height - height / 3],
    ];
    const canvasRegions = [...this.state.canvasRegions];
    const activeRegion = canvasRegions[this.state.activeRegion];
    const interPts = [...this.state.interPts.slice(0, 4)];

    if (activeRegion.length < 4) {
      activeRegion.push(this.createPoint(0, 0, activeRegion.length));
      interPts.push(this.createInterpoint(activeRegion.length));
    }

    const regionPts = activeRegion.slice(0, 4).map((obj: any, _idx: any) => {
      obj.position.set(regionCordinates[_idx][0], regionCordinates[_idx][1]);
      return obj;
    });
    activeRegion
      .slice(4)
      .forEach((pt) => this.filterPixi.stage.removeChild(pt));

    this.state.interPts
      .slice(4)
      .forEach((pt) => this.filterPixi.stage.removeChild(pt));

    canvasRegions[this.state.activeRegion] = regionPts;
    const _regions = [...this.state.regions];
    _regions[this.state.activeRegion] = null;
    this.setState(
      {
        canvasRegions,
        regions: _regions,
        interPts,
      },
      () => {
        this.updateRegion(false);
        this.props.updateFilters(
          _.cloneDeep(
            this.state.regions.filter((region: any) => Array.isArray(region)),
          ),
        );
      },
    );
  };

  render() {
    const { media, channelNode } = this.props;

    return (
      <div className={styles['region-filter-ctn']}>
        <CalibrationControls
          imageRef={this.imageRef}
          onThumbnailLoaded={() => this.onThumbnailLoaded()}
          media={media}
          regionRef={this.regionRef}
          channelNode={channelNode}
          channelId={channelNode.ChannelID}
          showCalibration={this.state.showCalibration}
        />
        <div className={styles['canvas-region-container']}>
          <div className={styles['action-btn-ctn']}>
            <div>
              <Button
                block
                onClick={() => {
                  this.setState({
                    showCalibration: !this.state.showCalibration,
                  });
                }}>
                {this.state.showCalibration ? 'Hide' : 'Show'} Calibration
                Controls
              </Button>
            </div>
            <div className={styles['action-panel-ctn']} />
            <div className={styles['reset-btn']}>
              <Button block onClick={() => this.resetRegionFilter()}>
                Reset Area Filters
              </Button>
            </div>
          </div>
          {this.state.noFiltersActive && (
            <div className={styles['region-list-ctn']}>
              <div className={styles['region-row']}>
                Move a point to add a new area. Click on circles to add or
                delete points.
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default RegionFilters;
