/**
 * @generated SignedSource<<80a6166ef384c69b2a152adfbb99411c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreateUpdateSite_SiteNodeQuery$variables = {
  siteId: string;
};
export type CreateUpdateSite_SiteNodeQuery$data = {
  readonly node: {
    readonly AddressInfo?: {
      readonly AddressID: number;
      readonly City: string | null | undefined;
      readonly Country: string | null | undefined;
      readonly PostalCode: string | null | undefined;
      readonly State: string | null | undefined;
      readonly Street: string | null | undefined;
    } | null | undefined;
    readonly Contacts?: ReadonlyArray<{
      readonly email: string | null | undefined;
      readonly name: string | null | undefined;
      readonly phone: string | null | undefined;
      readonly type: string | null | undefined;
    } | null | undefined> | null | undefined;
    readonly EscalationPath?: string | null | undefined;
    readonly ExternalID?: string | null | undefined;
    readonly HasContacts?: boolean | null | undefined;
    readonly HasEscalationPath?: boolean | null | undefined;
    readonly HasIssueNotes?: boolean | null | undefined;
    readonly HasShippingAddress?: boolean | null | undefined;
    readonly HasStatusNotes?: boolean | null | undefined;
    readonly IssueNotes?: string | null | undefined;
    readonly Issues?: string | null | undefined;
    readonly Labels?: ReadonlyArray<{
      readonly LabelID: number;
      readonly id: string;
    } | null | undefined> | null | undefined;
    readonly Name?: string;
    readonly Projects?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly Name: string | null | undefined;
          readonly ProjectID: number;
        };
      } | null | undefined>;
    } | null | undefined;
    readonly ShippingAddressInfo?: {
      readonly AddressID: number;
      readonly City: string | null | undefined;
      readonly Country: string | null | undefined;
      readonly PostalCode: string | null | undefined;
      readonly State: string | null | undefined;
      readonly Street: string | null | undefined;
    } | null | undefined;
    readonly SiteID?: number;
    readonly Status?: string | null | undefined;
    readonly StatusNotes?: string | null | undefined;
    readonly Timezone?: string | null | undefined;
    readonly id?: string;
  } | null | undefined;
};
export type CreateUpdateSite_SiteNodeQuery = {
  response: CreateUpdateSite_SiteNodeQuery$data;
  variables: CreateUpdateSite_SiteNodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "siteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SiteID",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ExternalID",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Timezone",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Street",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "City",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "State",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Country",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "AddressID",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "PostalCode",
  "storageKey": null
},
v13 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "SiteContactObj",
  "kind": "LinkedField",
  "name": "Contacts",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "EscalationPath",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LabelID",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ProjectID",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HasShippingAddress",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HasEscalationPath",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HasContacts",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Status",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Issues",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StatusNotes",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IssueNotes",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HasStatusNotes",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HasIssueNotes",
  "storageKey": null
},
v27 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateUpdateSite_SiteNodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "kind": "RequiredField",
                "field": (v3/*: any*/),
                "action": "LOG",
                "path": "node.SiteID"
              },
              {
                "kind": "RequiredField",
                "field": (v4/*: any*/),
                "action": "LOG",
                "path": "node.Name"
              },
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AddressInfo",
                "kind": "LinkedField",
                "name": "AddressInfo",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AddressInfo",
                "kind": "LinkedField",
                "name": "ShippingAddressInfo",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": null
              },
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "Labels",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "RequiredField",
                    "field": (v16/*: any*/),
                    "action": "LOG",
                    "path": "node.Labels.LabelID"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectConnection",
                "kind": "LinkedField",
                "name": "Projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": {
                          "alias": null,
                          "args": null,
                          "concreteType": "Project",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v17/*: any*/),
                              "action": "LOG",
                              "path": "node.Projects.edges.node.ProjectID"
                            },
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        },
                        "action": "LOG",
                        "path": "node.Projects.edges.node"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/)
            ],
            "type": "Site",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateUpdateSite_SiteNodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AddressInfo",
                "kind": "LinkedField",
                "name": "AddressInfo",
                "plural": false,
                "selections": (v27/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AddressInfo",
                "kind": "LinkedField",
                "name": "ShippingAddressInfo",
                "plural": false,
                "selections": (v27/*: any*/),
                "storageKey": null
              },
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "Labels",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectConnection",
                "kind": "LinkedField",
                "name": "Projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Project",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/)
            ],
            "type": "Site",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6eaa861e8a19b81ab98d72c58787cb4a",
    "id": null,
    "metadata": {},
    "name": "CreateUpdateSite_SiteNodeQuery",
    "operationKind": "query",
    "text": "query CreateUpdateSite_SiteNodeQuery(\n  $siteId: ID!\n) {\n  node(id: $siteId) {\n    __typename\n    ... on Site {\n      id\n      SiteID\n      Name\n      ExternalID\n      Timezone\n      AddressInfo {\n        Street\n        City\n        State\n        Country\n        AddressID\n        PostalCode\n        id\n      }\n      ShippingAddressInfo {\n        Street\n        City\n        State\n        Country\n        AddressID\n        PostalCode\n        id\n      }\n      Contacts {\n        name\n        email\n        phone\n        type\n      }\n      EscalationPath\n      Labels {\n        id\n        LabelID\n      }\n      Projects {\n        edges {\n          node {\n            ProjectID\n            Name\n            id\n          }\n        }\n      }\n      HasShippingAddress\n      HasEscalationPath\n      HasContacts\n      Status\n      Issues\n      StatusNotes\n      IssueNotes\n      HasStatusNotes\n      HasIssueNotes\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c391523652430cff24920f8acf674868";

export default node;
