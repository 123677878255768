import { DFConfigKeys } from '@/dfConfigKeys';
import { getCustomerProfileValue, isInternalUser } from './utils';

export const SidebarTabsVisibilityConfigKeys = {
  locations: DFConfigKeys.fe_locations_tab_visibility,
  investigations: DFConfigKeys.fe_investigations_tab_visibility,
  views: DFConfigKeys.fe_views_tab_visibility,
  insights: DFConfigKeys.fe_insights_tab_visibility,
  alerts: DFConfigKeys.fe_alerts_tab_visibility,
} as const;

export const isSidebarTabAccessible = (
  currentUser: any,
  tab: keyof typeof SidebarTabsVisibilityConfigKeys,
) => {
  const configKey = SidebarTabsVisibilityConfigKeys[tab];

  return (
    getCustomerProfileValue(currentUser, configKey).visibility === 'on' ||
    isInternalUser(currentUser)
  );
};
