import { useMutationWithFeedback } from '@/utils/graphql/hooks/useMutationWithFeedback';
import { useCallback } from 'react';
import { graphql } from 'react-relay';

const DELETE_LABEL_MUTATION = graphql`
  mutation DeleteLabelMutation(
    $labelId: Int!
    $customer_id: Int! # $connections: [ID!]!
  ) {
    deleteLabel(input: { id: $labelId, customerId: $customer_id }) {
      changedSites {
        ...labels_changedSite
      }
    }
  }
`;

export function useDeleteLabelMutation(
  customerId: number,
  labelConnectionId: string,
): [boolean, (labelId: number, onComplete?: () => void) => void] {
  const { executeMutation, loading } = useMutationWithFeedback(
    DELETE_LABEL_MUTATION,
    {
      msgPrefix: 'Deleting Label',
      failureOnly: false,
    },
  );

  const deleteLabel = useCallback(
    (labelId: number, onComplete?: () => void) => {
      executeMutation({
        variables: {
          labelId,
          customer_id: customerId,
          connections: [labelConnectionId],
        },
        onCompleted: () => {
          if (onComplete) {
            onComplete();
          }
        },
        updater: (store) => {
          const connection = store.get(labelConnectionId);
          if (connection) {
            const edges = connection.getLinkedRecords('edges');
            if (edges) {
              const updatedEdges = edges.filter(
                (edge) =>
                  edge.getLinkedRecord('node')?.getValue('LabelID') !== labelId,
              );
              connection.setLinkedRecords(updatedEdges, 'edges');
            }
          }
        },
      });
    },
    [executeMutation, customerId, labelConnectionId],
  );

  return [loading, deleteLabel];
}
