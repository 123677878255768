/**
 * @generated SignedSource<<1461ec2867e08bc845327b67c1891956>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type SceneFragment$data = {
  readonly anchorTimeStamp: string | null | undefined;
  readonly channels: ReadonlyArray<{
    readonly ChannelID: number;
    readonly Name: string | null | undefined;
    readonly ' $fragmentSpreads': FragmentRefs<
      'ChannelTileAndName_Channel' | 'TileThumbnailChannelsMedia'
    >;
  }>;
  readonly createdBy: number | null | undefined;
  readonly id: string;
  readonly isFavorite: boolean | null | undefined;
  readonly isSharedWithTeam: boolean | null | undefined;
  readonly name: string | null | undefined;
  readonly timestamp: string;
};
export type SceneFragment$key = {
  readonly ' $data'?: SceneFragment$data;
  readonly ' $fragmentSpreads': FragmentRefs<'SceneFragment'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: {
    mask: false,
  },
  name: 'SceneFragment',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isFavorite',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'timestamp',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'anchorTimeStamp',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'createdBy',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isSharedWithTeam',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      concreteType: 'Channel',
      kind: 'LinkedField',
      name: 'channels',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'ChannelID',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'Name',
          storageKey: null,
        },
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'ChannelTileAndName_Channel',
        },
        {
          args: null,
          kind: 'FragmentSpread',
          name: 'TileThumbnailChannelsMedia',
        },
      ],
      storageKey: null,
    },
  ],
  type: 'MonitorScene',
  abstractKey: null,
};

(node as any).hash = '0647758628bc8529a164239e2896561d';

export default node;
