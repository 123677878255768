/**
 * @generated SignedSource<<e8196a175edaa10bdecaf9c010936876>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MonitorMutations_ShareSceneWithTeam_Mutation$variables = {
  app_id: number;
  customer_id: number;
  id: string;
  teamScenesConnection: ReadonlyArray<string>;
};
export type MonitorMutations_ShareSceneWithTeam_Mutation$data = {
  readonly shareSceneWithTeam:
    | {
        readonly sceneEdge:
          | {
              readonly node:
                | {
                    readonly isSharedWithTeam: boolean | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};
export type MonitorMutations_ShareSceneWithTeam_Mutation = {
  response: MonitorMutations_ShareSceneWithTeam_Mutation$data;
  variables: MonitorMutations_ShareSceneWithTeam_Mutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'app_id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'customer_id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'teamScenesConnection',
      },
    ],
    v1 = [
      {
        fields: [
          {
            kind: 'Variable',
            name: 'appId',
            variableName: 'app_id',
          },
          {
            kind: 'Variable',
            name: 'customerId',
            variableName: 'customer_id',
          },
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        kind: 'ObjectValue',
        name: 'input',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isSharedWithTeam',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'MonitorMutations_ShareSceneWithTeam_Mutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'ShareSceneWithTeamPayload',
          kind: 'LinkedField',
          name: 'shareSceneWithTeam',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'MonitorSceneEdge',
              kind: 'LinkedField',
              name: 'sceneEdge',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'MonitorScene',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [v2 /*: any*/],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'MonitorMutations_ShareSceneWithTeam_Mutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'ShareSceneWithTeamPayload',
          kind: 'LinkedField',
          name: 'shareSceneWithTeam',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'MonitorSceneEdge',
              kind: 'LinkedField',
              name: 'sceneEdge',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'MonitorScene',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'id',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              filters: null,
              handle: 'prependEdge',
              key: '',
              kind: 'LinkedHandle',
              name: 'sceneEdge',
              handleArgs: [
                {
                  kind: 'Variable',
                  name: 'connections',
                  variableName: 'teamScenesConnection',
                },
              ],
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '72b2c8ae708bdb1c4afe6e682d7f99fe',
      id: null,
      metadata: {},
      name: 'MonitorMutations_ShareSceneWithTeam_Mutation',
      operationKind: 'mutation',
      text: 'mutation MonitorMutations_ShareSceneWithTeam_Mutation(\n  $app_id: Int!\n  $customer_id: Int!\n  $id: String!\n) {\n  shareSceneWithTeam(input: {appId: $app_id, customerId: $customer_id, id: $id}) {\n    sceneEdge {\n      node {\n        isSharedWithTeam\n        id\n      }\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'bf65058d00d68478c5da01d9da9a77a6';

export default node;
