import ChannelSelect from '@/components/ChannelSelect2';
import DaySelector from '@/components/DaySelector';
import { isEmail } from '@/utils/utils';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import CodeList from '../../codes-list';
import { ALARM_INCIDENT_PRIORITIES } from '../../constants';

const { Panel } = Collapse;

enum AlertType {
  CAMERA_DOWN = 'Camera Down',
}

const alertTypeMap = {
  [AlertType.CAMERA_DOWN]: 'camera_monitoring',
};

const convertAlertType = <T extends AlertType | string>(
  value: T,
  toPayload = true,
): T extends AlertType ? string : AlertType => {
  if (toPayload) {
    // Convert from AlertType to payload string
    const payload = alertTypeMap[value as AlertType];
    if (!payload) throw new Error(`Unknown alert type: ${value}`);
    return payload as any;
  } else {
    // Convert from payload string to AlertType
    const entry = Object.entries(alertTypeMap).find(([_, v]) => v === value);
    if (!entry) throw new Error(`Unknown payload type: ${value}`);
    return entry[0] as any;
  }
};

interface AlarmFormData {
  site_id?: number;
  cameras?: number[];
  alarmName?: string;
  alertType?: AlertType;
  timeframe?: {
    days?: string[];
    timezone?: string;
  };
  reviewQueue?: boolean;
  actions?: any[];
  codes?: any[];
  priority?: number;
  runFrequency?: number;
  throttleWindow?: number;
  rollingWindow?: number;
}

interface AlarmPayload {
  step: string;
  site_id: number | undefined;
  alarm_id?: number;
  data: {
    name: string;
    timeframe: {
      days: string[];
      timezone: string;
      time_range: {
        from: string;
        to: string;
      };
    };
    actions: any[];
    codes: any[];
    priority: number;
    sources: {
      channels: { id: number }[];
    };
    delivery: {
      type: string;
      subtype: string;
      threshold: {
        review_queue: boolean;
        run_frequency: number;
        throttle_window: number;
        event_time_buffer: number;
        rolling_window: number;
        type: string;
        metric: number;
      };
    };
  };
}

const getInitialValues = (alarm: any) => {
  if (alarm) {
    return {
      site_id: alarm.site_id,
      cameras: alarm.data.sources.channels.map((c: any) => c.id),
      alarmName: alarm.data.name,
      alertType: convertAlertType(alarm.data.delivery.subtype, false),
      timeframe: {
        days: alarm.data.timeframe.days,
        timezone: alarm.data.timeframe.timezone,
      },
      reviewQueue: alarm.data.delivery.threshold.review_queue,
      actions: alarm.data.actions,
      codes: alarm.data.codes,
      priority: alarm.data.priority,
      runFrequency: alarm.data.delivery.threshold.run_frequency / 3600,
      throttleWindow: alarm.data.delivery.threshold.throttle_window / 3600,
      rollingWindow: alarm.data.delivery.threshold.rolling_window / 60,
    };
  }
  return {
    timeframe: {
      timezone: 'UTC',
    },
    alertType: AlertType.CAMERA_DOWN,
    priority: 3,
    runFrequency: 1,
    throttleWindow: 3,
    rollingWindow: 120,
  };
};

interface SetupAlarmProps {
  filteredSites: any[];
  alarm: any;
  sites: any;
  locations: any;
  onSubmit: () => void;
  onCancel: () => void;
  namespace: string;
}

const SetupAlarm: React.FC<SetupAlarmProps> = ({
  filteredSites,
  alarm,
  sites,
  locations,
  onSubmit,
  onCancel,
  namespace,
}) => {
  const dispatch = useDispatch();
  const creatingAlarm = useSelector(
    (state: any) => state.loading.effects[`${namespace}/setupAlarm`],
  );

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [formData, setFormData] = useState<AlarmFormData>({});
  const [selectedSite, setSelectedSite] = useState<number>();
  const [filterLocationIDs, setFilterLocationIDs] = useState<number[]>([]);
  const formRef = useRef<FormInstance<AlarmFormData>>(null);

  useEffect(() => {
    const initialValues = getInitialValues(alarm) as AlarmFormData;
    setFormData(initialValues);
    formRef.current?.setFieldsValue(initialValues);

    if (alarm) {
      // Add these lines to initialize the site and location IDs
      setSelectedSite(initialValues.site_id);
      const locIDs =
        sites.all
          .find((s: any) => s.SiteID === initialValues.site_id)
          ?.Projects.map((p: any) => p.ProjectID) || [];
      setFilterLocationIDs(locIDs);
    }
  }, [alarm]);

  const onSiteChange = (val: number) => {
    setSelectedSite(val);
    formRef.current?.setFieldValue('cameras', []);

    const locIDs =
      sites.all
        .find((s: any) => s.SiteID === val)
        ?.Projects.map((p: any) => p.ProjectID) || [];
    setFilterLocationIDs(locIDs);

    let locationNode = locations.all.find(
      (l: any) => locIDs.indexOf(l.ProjectID) !== -1,
    );
    if (locationNode && locationNode.Timezone) {
      formRef.current?.setFieldValue(
        ['timeframe', 'timezone'],
        locationNode.Timezone,
      );
    }
  };

  const validateDestination = (
    rule: any,
    value: string,
    callback: Function,
  ) => {
    const typeField = rule.field.replace('to', 'type');
    const typeValue = formRef.current?.getFieldValue(typeField.split('.'));

    if (typeValue === 'email') {
      if (!value || !isEmail(value)) {
        callback('Please enter a valid email address');
        return;
      }
    } else if (!value) {
      callback('Please enter a value');
      return;
    }
    callback();
  };

  const handleStepSubmit = (values: Partial<AlarmFormData>) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setCurrentStep(currentStep + 1);
  };

  const handleFinalSubmit = (values: Partial<AlarmFormData>) => {
    const completeFormData = { ...formData, ...values };
    const payload: AlarmPayload = {
      step: 'last',
      site_id: completeFormData.site_id,
      data: {
        name: completeFormData.alarmName || '',
        timeframe: {
          days: completeFormData.timeframe?.days || [],
          timezone: completeFormData.timeframe?.timezone || 'UTC',
          time_range: {
            from: '00:00:00',
            to: '23:59:59',
          },
        },
        actions: completeFormData.actions || [],
        codes: (completeFormData.codes || []).map((code) => ({
          ...code,
          timeout: code.timeout * 60, //multiply by 60 as backend expects minutes
        })),
        priority: completeFormData.priority || 3,
        sources: {
          channels: (completeFormData.cameras || []).map((c) => ({ id: c })),
        },
        delivery: {
          type: 'camera_monitoring',
          subtype: convertAlertType(completeFormData.alertType || '') || '',
          threshold: {
            review_queue: completeFormData.reviewQueue || false,
            run_frequency: (completeFormData.runFrequency || 1) * 3600,
            throttle_window: (completeFormData.throttleWindow || 3) * 3600,
            event_time_buffer: 5,
            rolling_window: (completeFormData.rollingWindow || 120) * 60,
            type: 'gt',
            metric: 0,
          },
        },
      },
    };

    if (alarm) {
      payload.alarm_id = alarm.alarm_id;
    }

    dispatch({
      type: `${namespace}/setupAlarm`,
      payload,
    }).then(() => {
      onSubmit();
    });
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <h4>Step {currentStep + 1} of 3: Name and Type</h4>
            <div style={{ marginBottom: '10px', display: 'flex' }}>
              <Form
                ref={formRef}
                layout="vertical"
                initialValues={formData}
                onFinish={handleStepSubmit}
                style={{ minWidth: '500px' }}>
                <Form.Item
                  name="site_id"
                  label="Select Site"
                  rules={[{ required: true, message: 'Please select site' }]}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={onSiteChange}>
                    {filteredSites.map((site) => (
                      <Select.Option key={site.SiteID} value={site.SiteID}>
                        {site.Name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {selectedSite && filterLocationIDs.length === 0 && (
                  <div
                    className="df-warn-text"
                    style={{ marginBottom: '10px' }}>
                    No Cameras in this Site, Please choose another site.
                  </div>
                )}

                <Form.Item
                  name="cameras"
                  label="Select Cameras"
                  rules={[
                    { required: true, message: 'Please select cameras' },
                  ]}>
                  <ChannelSelect
                    multiple
                    disabled={!selectedSite || filterLocationIDs.length === 0}
                    filterLocationIDs={filterLocationIDs}
                  />
                </Form.Item>

                <Form.Item
                  name="alarmName"
                  label="Alarm Name"
                  rules={[
                    { required: true, message: 'Please enter alarm name' },
                  ]}>
                  <Input />
                </Form.Item>

                <Form.Item
                  name="alertType"
                  label="Alert Type"
                  rules={[
                    { required: true, message: 'Please select alert type' },
                  ]}>
                  <Select>
                    <Select.Option value={AlertType.CAMERA_DOWN}>
                      Camera Down
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name={['timeframe', 'days']}
                  label="Days"
                  rules={[
                    { required: true, message: 'Please select the days' },
                  ]}>
                  <DaySelector />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Next
                  </Button>
                  <Button onClick={onCancel} style={{ marginLeft: 8 }}>
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <h4>Step {currentStep + 1} of 3: Notifications and Escalations</h4>
            <div style={{ marginBottom: '10px', display: 'flex' }}>
              <Form
                ref={formRef}
                layout="vertical"
                initialValues={formData}
                onFinish={handleStepSubmit}
                style={{ minWidth: '500px' }}>
                <Form.Item name="reviewQueue" valuePropName="checked">
                  <h4>Screener+</h4>
                  <div className={'df-description'}>
                    Subscribe to Dragonfruit's Screener+ service to have our
                    Security Operations Center staff review the alarm before
                    sending to your attention.
                    <br />
                    This reduces false alarms as our staff will verify that the
                    incident is valid before notifying you.
                  </div>
                  <Checkbox>
                    Enable Screener+ subscription for this alarm
                  </Checkbox>
                </Form.Item>
                <h4>Incident Notifications</h4>
                <Form.List name="actions">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map((field, index) => (
                          <div key={field.key}>
                            <Row gutter={8}>
                              <Col span={8}>
                                <Form.Item
                                  {...field}
                                  label={index === 0 ? 'Name' : ''}
                                  name={[field.name, 'name']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter name',
                                    },
                                  ]}>
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={5}>
                                <Form.Item
                                  {...field}
                                  label={index === 0 ? 'Type' : ''}
                                  name={[field.name, 'type']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select type',
                                    },
                                  ]}>
                                  <Select>
                                    <Select.Option value="email">
                                      Email
                                    </Select.Option>
                                    <Select.Option value="text">
                                      Text
                                    </Select.Option>
                                    <Select.Option value="voice">
                                      Voice
                                    </Select.Option>
                                    <Select.Option value="webhook">
                                      Webhook
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={7}>
                                <Form.Item
                                  {...field}
                                  label={index === 0 ? 'Destination' : ''}
                                  name={[field.name, 'to']}
                                  rules={[
                                    {
                                      required: true,
                                      validator: validateDestination,
                                    },
                                  ]}>
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={2}>
                                <Form.Item label={index === 0 ? ' ' : ''}>
                                  <Button
                                    onClick={() => remove(field.name)}
                                    icon={<MinusCircleOutlined />}></Button>
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        ))}
                        <Form.Item>
                          <Button onClick={() => add()} icon={<PlusOutlined />}>
                            Add Notifications
                          </Button>
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.List>

                <Form.Item>
                  <Button
                    onClick={() => setCurrentStep(currentStep - 1)}
                    style={{ marginRight: 8 }}>
                    Previous
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Next
                  </Button>
                  <Button onClick={onCancel} style={{ marginLeft: 8 }}>
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h4>
              Step {currentStep + 1} of 3: Overrides and Advanced Settings
            </h4>
            <div style={{ marginBottom: '10px' }}>
              <Form
                ref={formRef}
                layout="vertical"
                initialValues={formData}
                onFinish={handleFinalSubmit}>
                <div>
                  <Form.Item noStyle name="codes">
                    <CodeList timeModularity="hours" />
                  </Form.Item>
                </div>

                <Collapse ghost>
                  <Panel header="Advanced Options" key="1" forceRender={true}>
                    <Form.Item
                      name="priority"
                      label="Default Priority"
                      extra="Priority assigned to alerts triggered by this rule">
                      <Select style={{ width: '150px' }}>
                        {ALARM_INCIDENT_PRIORITIES.map((n) => (
                          <Select.Option key={n} value={n}>
                            {n}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="runFrequency"
                      label="Run Frequency (hours)"
                      extra="How often should the rules be run">
                      <InputNumber min={1} style={{ width: '150px' }} />
                    </Form.Item>

                    <Form.Item
                      name="throttleWindow"
                      label="Throttle Window (hours)"
                      extra="Hours that need to pass before the rule sends notifications again">
                      <InputNumber min={1} style={{ width: '150px' }} />
                    </Form.Item>

                    <Form.Item
                      name="rollingWindow"
                      label="Minimum Downtime to Report (minutes)"
                      extra="Minutes of past data to consider when evaluating rule">
                      <InputNumber min={1} style={{ width: '150px' }} />
                    </Form.Item>
                  </Panel>
                </Collapse>

                <Form.Item>
                  <Button
                    onClick={() => setCurrentStep(currentStep - 1)}
                    style={{ marginRight: 8 }}>
                    Previous
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={creatingAlarm}>
                    Done
                  </Button>
                  <Button onClick={onCancel} style={{ marginLeft: 8 }}>
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </>
        );
    }
  };

  // TODO: fix the minimun form width
  return (
    <div
      className="steps-content"
      style={{
        minHeight: '500px',
        marginBottom: '10px',
        minWidth: '500px',
      }}>
      {renderStep()}
    </div>
  );
};

export default SetupAlarm;
