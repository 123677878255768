import { ChannelSelectCascader } from '@/components/ChannelSelectCascader';
import type { ChannelSelectorCascaderProps } from '@/components/ChannelSelectCascader/types';
import { getCurrentCustomerID } from '@/utils/utils';
import { useParams } from '@umijs/max';
import { useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import type { ChannelSelectQuery as ChannelSelectQueryType } from './__generated__/ChannelSelectQuery.graphql';

const ChannelSelectQuery = graphql`
  query ChannelSelectQuery($appId: Int!, $customerId: Int!) {
    sites(appId: $appId, customerId: $customerId, sort: [NAME_ASC]) {
      ...ChannelSelectCascaderFragment_ChannelsBySites
    }
    labels(appId: $appId, customerId: $customerId, sort: [NAME_ASC]) {
      ...ChannelSelectCascaderFragment_ChannelsBySiteGroups
    }
  }
`;

/**
 * This component is just a stopgap solution to allow making the graphql query within CreateEvent component. Ideally this query
 * should be called from the app where the CreateEvent component is being used. Until we reach that point, this component will be
 * used to make the query.
 */
const ChannelSelect = (props: ChannelSelectorCascaderProps) => {
  const customerId = useMemo(() => getCurrentCustomerID(), []);
  const urlParams = useParams();
  const appId = urlParams.appID;
  const queryData = useLazyLoadQuery<ChannelSelectQueryType>(
    ChannelSelectQuery,
    {
      appId: !!appId ? Number(appId) : 101,
      customerId,
    },
  );

  return (
    <ChannelSelectCascader
      channelsBySitesFragmentRef={queryData.sites}
      channelsBySiteGroupsFragmentRef={queryData.labels}
      placeholder="Select cameras"
      {...props}
    />
  );
};

export { ChannelSelect };
