import { generateRandomID, STREAM_TYPES } from '@/utils/utils';
import { CheckOutlined } from '@ant-design/icons';
import { Divider, Dropdown } from 'antd';
import React from 'react';
import styles from './style.less';
import { StreamType, StreamTypeKey } from './types';

type Props = {
  streamType?: StreamType;
  streamSelections?: StreamTypeKey[];
  onChange: (streamType: StreamType) => void;
};

const defaultStreamSelections = [
  'SD_1',
  'R720p_15',
  'CIF_1',
  'R720p_1',
  'R1080p_1',
  'R4k_1',
] as StreamTypeKey[];

const getOverlay = (
  currentStreamType: StreamType,
  streamSelections: StreamTypeKey[],
  onChange?: (streamType: StreamType) => void,
) => {
  return (
    <div className={'df-menu-container'}>
      {streamSelections.map((key) => {
        if (!STREAM_TYPES[key]) {
          return <Divider key={key} style={{ margin: '4px 0' }} />;
        }

        const streamType = STREAM_TYPES[key] as StreamType;
        return (
          <div
            key={key}
            className="df-menu-item-container"
            onClick={(e) => {
              e.stopPropagation();
              if (onChange) {
                onChange(streamType);
              }
            }}>
            <div className="df-menu-item">
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                <div style={{ width: 20 }}>
                  {currentStreamType.key === streamType.key && (
                    <CheckOutlined />
                  )}
                </div>
                <div>{streamType.name}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const StreamTypeSelector = ({
  onChange,
  streamSelections = defaultStreamSelections,
  streamType = STREAM_TYPES.SD_1,
}: Props) => {
  const id = React.useMemo(() => generateRandomID(), []);

  return (
    <div className={styles.ctn}>
      <div id={id} className={styles['popup-ctn']} />
      <Dropdown
        trigger={['click', 'hover']}
        getPopupContainer={() => document.getElementById(id) as HTMLElement}
        overlay={getOverlay(streamType, streamSelections, onChange)}>
        <span>{streamType.label}</span>
      </Dropdown>
    </div>
  );
};

export default StreamTypeSelector;
