import LoadingSpinner from '@/components/LoadingSpinner';
import { DFConfigKeys } from '@/dfConfigKeys';
import { useCustomerProfileValue } from '@/utils/hooks';
import { STREAM_TYPES } from '@/utils/utils';
import { Button } from 'antd';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import semver from 'semver';
import { STREAM_QUALITY } from '../StreamQualitySelector/constants';
import { StreamQuality } from '../StreamQualitySelector/types';
import StreamTypeSelector from '../StreamTypeSelector';
import { StreamType, StreamTypeKey } from '../StreamTypeSelector/types';
import ZoomControls from '../ZoomControls';
import styles from './styles.less';
import {
  arePeersOnSameNetwork,
  DFRTCPeerConnection,
  makeSignalingRequest,
  negotiate,
  useStunServerConfig,
  useTurnServerConfig,
} from './utils';

const ARCHIVE_STREAM_DURATION_SECS = 300;
const PLAYHEAD_CHANGE_SENSITIVITY_SECS = 10;
const MAX_RETRIES = 1;
const DEBUG = ENVIRONMENT === 'development';

type WebRTCVideoPlayerProps = {
  baseStationID: string;
  baseStationVersion: string;
  channelID: string;
  isPlaying: boolean;
  startTime?: number;
  isArchive?: boolean;
  streamSelections: StreamTypeKey[];
  preferredStreamQuality: StreamQuality;
  playTObjAfterLoading: () => void;
  pauseTObjForLoading: () => void;
};

const WebRTCVideoPlayer: React.FC<WebRTCVideoPlayerProps> = ({
  baseStationID,
  baseStationVersion,
  channelID,
  isPlaying,
  startTime,
  isArchive = false,
  playTObjAfterLoading,
  pauseTObjForLoading,
  preferredStreamQuality = STREAM_QUALITY['DEFAULT'],
  streamSelections,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const pcRef = useRef<DFRTCPeerConnection | null>(null);
  const [isNegotiating, setIsNegotiating] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [streamType, setStreamType] = useState<StreamType>(
    STREAM_TYPES.DEFAULT,
  );
  const retryCount = useRef<number>(0);
  const prevProps = useRef({
    baseStationID,
    channelID,
    isPlaying,
    startTime,
    preferredStreamQuality,
  });
  const archiveStreamStartTimeRef = useRef<number | null>(null);
  const stunServerConfig = useStunServerConfig();
  const turnServerConfig = useTurnServerConfig();
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoDivRef = useRef<HTMLDivElement>(null);
  //Whether or not the base station is on the same local network as the browser
  const arePeersLocal = useRef<boolean | null>(null);
  const autoTuneVideoQualityConfigVal = useCustomerProfileValue(
    DFConfigKeys.fe_auto_tune_webrtc_video_quality,
  );
  const resolutionSelectionEnabled =
    baseStationVersion && semver.gte(baseStationVersion, '3.6.52');

  const handleFailure = (err: string, attemptReconnecting = false) => {
    if (attemptReconnecting && retryCount.current < MAX_RETRIES) {
      if (DEBUG) {
        console.log('Attempting re-connection');
      }
      setError(null);
      retryCount.current += 1;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      startStream();
    } else {
      if (DEBUG) {
        console.log('Error: ', err);
      }
      setError(err);
      setIsNegotiating(false);
      setIsVideoLoading(false);
      pauseTObjForLoading();
    }
  };

  const _getNegotiatingArgs = (
    overrides: {
      pc?: DFRTCPeerConnection;
      baseStationID?: string;
      channelID?: string;
      streamType?: StreamType;
      streamQuality?: StreamQuality;
      startTime?: number;
      uuid?: string;
    } = {},
  ): Parameters<typeof negotiate> => {
    const pc = overrides.pc || (pcRef.current as DFRTCPeerConnection);
    const startTimeOverride = overrides.startTime || startTime;

    let negotiateArgs = [
      pc,
      overrides.baseStationID || baseStationID,
      overrides.channelID || channelID,
      overrides.streamQuality || preferredStreamQuality,
      overrides.streamType || streamType,
      arePeersLocal.current,
    ] as any;

    if (startTimeOverride) {
      negotiateArgs = negotiateArgs.concat([
        startTimeOverride,
        startTimeOverride + ARCHIVE_STREAM_DURATION_SECS,
      ]);
    } else {
      negotiateArgs = negotiateArgs.concat([undefined, undefined]);
    }

    if (overrides.uuid || pc.uuid) {
      negotiateArgs.push(overrides.uuid || pc.uuid);
    }

    return negotiateArgs;
  };

  const startNegotiation = async () => {
    try {
      if (isNegotiating) {
        return;
      }
      if (DEBUG) {
        console.log('Starting negotation');
      }
      setIsNegotiating(true);
      const config = {
        sdpSemantics: 'unified-plan',
        iceServers: [stunServerConfig, turnServerConfig].filter(Boolean),
      };

      const pc = new RTCPeerConnection(
        config as RTCConfiguration,
      ) as DFRTCPeerConnection;
      pcRef.current = pc;

      pc.addEventListener('iceconnectionstatechange', () => {
        if (
          pc.iceConnectionState === 'disconnected' ||
          pc.iceConnectionState === 'failed' ||
          pc.iceConnectionState === 'closed'
        ) {
          handleFailure('Connection error');
        }
        if (
          pc.iceConnectionState === 'connected' ||
          pc.iceConnectionState === 'completed'
        ) {
          pc.getStats(null).then((stats) => {
            let localCandidate, remoteCandidate;
            stats.forEach((report) => {
              if (
                report.type === 'candidate-pair' &&
                report.state === 'succeeded'
              ) {
                const localCandidateId = report.localCandidateId;
                const remoteCandidateId = report.remoteCandidateId;

                stats.forEach((candidate) => {
                  if (candidate.id === localCandidateId) {
                    localCandidate = candidate;
                  }
                  if (candidate.id === remoteCandidateId) {
                    remoteCandidate = candidate;
                  }
                });
              }
            });
            if (
              localCandidate &&
              remoteCandidate &&
              arePeersLocal.current === null
            ) {
              const isLocal = arePeersOnSameNetwork(
                localCandidate,
                remoteCandidate,
              );
              if (DEBUG) {
                console.log(
                  'Stats obtained, are the connected peers local? ' + isLocal,
                );
              }
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              downgradeIfNeeded(isLocal);
            }
          });
        }
      });

      pc.addEventListener('connectionstatechange', () => {
        if (
          pc.connectionState === 'disconnected' ||
          pc.connectionState === 'failed' ||
          pc.connectionState === 'closed'
        ) {
          handleFailure('Connection error', true);
        }
      });

      pc.addEventListener('track', (evt) => {
        const track = evt.track;

        track.onended = () => {
          handleFailure('Stream stopped or interrupted');
        };

        if (evt.track.kind === 'video') {
          if (videoRef.current) {
            videoRef.current.srcObject = evt.streams[0];
            videoRef.current.play();
            setIsVideoLoading(true);
          }
        }
      });

      await negotiate(..._getNegotiatingArgs());
      setIsNegotiating(false);
    } catch (e: any) {
      if (DEBUG) {
        console.log('Error negotiating: ', e);
      }
      const message = _.get(e, 'message', 'Something went wrong');
      handleFailure(message);
    }
  };

  const resetState = () => {
    setIsNegotiating(false);
    setIsVideoLoading(false);
    setError(null);
    retryCount.current = 0;
    pcRef.current = null;
    archiveStreamStartTimeRef.current = null;
  };

  const startStream = () => {
    if (DEBUG) {
      console.log('Starting stream');
    }
    pauseTObjForLoading();
    if (startTime) archiveStreamStartTimeRef.current = startTime;
    startNegotiation();
  };

  const stopStream = () => {
    if (DEBUG) {
      console.log('Stopping stream');
    }
    if (pcRef.current) {
      const pc = pcRef.current;

      pc.getSenders().forEach((sender) => pc.removeTrack(sender));
      pc.getReceivers().forEach((receiver) => receiver.track.stop());

      pc.close();
      pc.onicecandidate = null;
      pc.ontrack = null;
      pc.oniceconnectionstatechange = null;
      pc.onconnectionstatechange = null;
      pcRef.current = null;
    }

    if (videoRef.current && videoRef.current.srcObject) {
      (videoRef.current.srcObject as MediaStream)
        .getTracks()
        .forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const onStreamTypeChange = (
    newStreamType: StreamType,
    reuseConnection = true,
  ) => {
    setStreamType(newStreamType);
    //If a peer connection already exists, update it
    if (pcRef.current?.uuid && reuseConnection) {
      makeSignalingRequest(
        ..._getNegotiatingArgs({ streamType: newStreamType }),
      );
    } else if (isPlaying) {
      stopStream();
      resetState();
      startStream();
    }
  };

  //Side-effect
  //If the peers are not connected locally i.e are NOT on the same network,
  //use a medium resolution instead of the default high
  //Putting this behind a config flag till the functionality is tested and stable
  const downgradeIfNeeded = (isLocal: boolean) => {
    const pc = pcRef.current as DFRTCPeerConnection;
    const isDowngradeEnabled = !!_.get(
      autoTuneVideoQualityConfigVal,
      'enabled',
      false,
    );
    if (
      isDowngradeEnabled &&
      isLocal === false &&
      pc.params?.video_quality !== STREAM_QUALITY.MEDIUM &&
      preferredStreamQuality === STREAM_QUALITY.DEFAULT &&
      streamType?.name === STREAM_TYPES.DEFAULT.name
    ) {
      if (DEBUG) {
        console.log('Downgrading stream quality to medium');
      }
      arePeersLocal.current = isLocal;
      stopStream();
      startStream();
    }
  };

  useEffect(() => {
    const prev = prevProps.current;
    if (prev.isPlaying !== isPlaying) {
      if (isPlaying) {
        if (DEBUG) {
          console.log('Moved from pause to play');
        }
        resetState();
        startStream();
      } else {
        if (DEBUG) {
          console.log('Moved from play to pause');
        }
        stopStream();
        resetState();
      }
    } else if (prev.channelID !== channelID) {
      stopStream();
      resetState();
      if (isPlaying) {
        startStream();
      }
    } else if (
      isArchive &&
      startTime &&
      isPlaying &&
      prev.startTime &&
      prev.startTime !== startTime
    ) {
      const hasPlayheadPositionChanged =
        Math.abs(startTime - prev.startTime) >=
        PLAYHEAD_CHANGE_SENSITIVITY_SECS;
      const hasStreamExpired =
        archiveStreamStartTimeRef.current &&
        Math.abs(startTime - archiveStreamStartTimeRef.current) >=
          ARCHIVE_STREAM_DURATION_SECS;
      if (hasPlayheadPositionChanged || hasStreamExpired) {
        if (DEBUG) {
          console.log('Playhead moved/ stream expired, reloading stream');
        }
        stopStream();
        resetState();
        startStream();
      }
    } else if (prev.preferredStreamQuality !== preferredStreamQuality) {
      //Reset tile selection back to default
      setStreamType(STREAM_TYPES.DEFAULT);
      onStreamTypeChange(STREAM_TYPES.DEFAULT, false);
    }
    prevProps.current = {
      baseStationID,
      channelID,
      isPlaying,
      startTime,
      preferredStreamQuality,
    };
  }, [baseStationID, channelID, isPlaying, startTime, preferredStreamQuality]);

  useEffect(() => {
    if (DEBUG) {
      console.log('WebRTC component mounted');
    }
    if (isPlaying) {
      startStream();
    }
    return () => {
      stopStream();
    };
  }, []);

  return (
    <div className={styles.ctn}>
      <div
        id="video-ctn"
        className={styles['video-ctn']}
        ref={videoContainerRef}>
        {error && (
          <div className={styles['stream-failed-ctn']}>
            <div style={{ marginBottom: '10px' }}>
              {error.split('\n').map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
            <Button
              onClick={() => {
                resetState();
                startStream();
              }}
              type="primary">
              Retry
            </Button>
          </div>
        )}
        {(isNegotiating || isVideoLoading) && (
          <LoadingSpinner
            color="#FFF"
            position="absolute"
            text={isArchive ? 'Loading Stream...' : 'Loading Live Stream...'}
          />
        )}
        {!error && (
          <div
            ref={videoDivRef}
            style={
              isNegotiating
                ? { display: 'none' }
                : { height: '100%', width: 'auto' }
            }>
            <video
              className={styles['video-element']}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onLoadedData={() => {
                setIsVideoLoading(false);
                playTObjAfterLoading();
              }}
              ref={videoRef}
              width="100%"
              height="100%"
              muted={true}></video>
          </div>
        )}
      </div>
      <ZoomControls
        videoDivRef={videoDivRef}
        videoContainerRef={videoContainerRef}
        className={styles['zoom-controls']}
      />
      {resolutionSelectionEnabled && (
        <div className={styles['stream-type-selector']}>
          <StreamTypeSelector
            streamSelections={streamSelections}
            onChange={onStreamTypeChange}
            streamType={streamType}
          />
        </div>
      )}
    </div>
  );
};

export default WebRTCVideoPlayer;
