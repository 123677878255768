import LoadingSpinner from '@/components/LoadingSpinner';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { useLazyLoadQuery, useQueryLoader } from 'react-relay';

import { useMonitorContext } from '../../../../MonitorContext';
import {
  ChannelsByIdsQuery,
  SitesWithChannelsQuery,
} from '../../../../MonitorQueries';
import type { MonitorQueries_ChannelsByIds_Query } from '../../../../__generated__/MonitorQueries_ChannelsByIds_Query.graphql';
import type { MonitorQueries_SitesWithChannels_Query } from '../../../../__generated__/MonitorQueries_SitesWithChannels_Query.graphql';
import { ChannelsList } from './ChannelsList';
import type { ChannelTileAndName_Channel$key } from './ChannelsList/__generated__/ChannelTileAndName_Channel.graphql';
import { SiteSelection } from './SiteSelection';
import { ChannelsSelectionContainer } from './styles';

const getSelectedSitesLocalStorageKey = (customerId: number) =>
  `${customerId}.vms_plus.camera_selection.filtered_sites`; // we are appending the customer id to make the localstorage key unique to avoid clashes when a user logs into multiple customer accounts on same browser

type ChannelSelectionProps = {
  selectedChannelIDs: number[];
  setSelectedChannelIDs: React.Dispatch<React.SetStateAction<number[]>>;
  passedChannelIds: number[] | undefined;
  isSceneViewingMode: boolean | undefined;
  setTimelinePlayerProps: React.Dispatch<any>;
  selectedChannelTileRefs: ChannelTileAndName_Channel$key[];
  setSelectedChannelTileRefs: React.Dispatch<
    React.SetStateAction<ChannelTileAndName_Channel$key[]>
  >;
};
const ChannelsSelectionContent = ({
  selectedChannelIDs,
  passedChannelIds,
  isSceneViewingMode,
  setSelectedChannelIDs,
  setTimelinePlayerProps,
  selectedChannelTileRefs,
  setSelectedChannelTileRefs,
}: ChannelSelectionProps) => {
  const { appId, customerId } = useMonitorContext();

  const areChannelsPassed = !!passedChannelIds && passedChannelIds?.length > 0;
  const [sidebarSearch, setSidebarSearch] = useState('');
  const [showInactiveChannels, setShowInactiveChannels] = useState(false);

  const passedOrSelectedChannelsData =
    useLazyLoadQuery<MonitorQueries_ChannelsByIds_Query>(ChannelsByIdsQuery, {
      app_id: appId,
      customer_id: customerId,
      filter_channel_ids: areChannelsPassed
        ? passedChannelIds
        : isSceneViewingMode
        ? selectedChannelIDs
        : [],
    });
  const passedOrSelectedSites = useMemo(() => {
    return (
      passedOrSelectedChannelsData.channels?.edges
        .map((channel) => channel?.node?.Project?.Site?.SiteID)
        .filter((siteID) => siteID != undefined) || []
    );
  }, [passedOrSelectedChannelsData.channels?.edges]); // This filtering is done because channels which are deleted will result in null instead of {Project: { Site: { SiteID: 'id' } }} from graphQL query

  const selectedSitesFromLocalStorage = useMemo(
    () => localStorage.getItem(getSelectedSitesLocalStorageKey(customerId)),
    [customerId],
  );

  const existingSelectedSites = useMemo(
    () =>
      selectedSitesFromLocalStorage && selectedSitesFromLocalStorage.length > 0
        ? selectedSitesFromLocalStorage?.split(',').map(Number)
        : [],
    [selectedSitesFromLocalStorage],
  );

  const [sitesWithChannelsQueryRef, loadSitesWithChannelsQuery] =
    useQueryLoader<MonitorQueries_SitesWithChannels_Query>(
      SitesWithChannelsQuery,
    );

  useEffect(() => {
    if (passedOrSelectedSites.length > 0) {
      loadSitesWithChannelsQuery({
        app_id: appId,
        customer_id: customerId,
        filter_site_ids: passedOrSelectedSites,
      });
      localStorage.setItem(
        getSelectedSitesLocalStorageKey(customerId),
        passedOrSelectedSites.join(','),
      );
      return;
    }
  }, [JSON.stringify(passedOrSelectedSites)]);

  useEffect(() => {
    if (existingSelectedSites?.length > 0 && !passedOrSelectedSites?.length) {
      loadSitesWithChannelsQuery({
        app_id: appId,
        customer_id: customerId,
        filter_site_ids: existingSelectedSites,
      });
      return;
    }
  }, [JSON.stringify(existingSelectedSites)]);

  const handleIncludeInactiveToggled = () => {
    setShowInactiveChannels((prev) => !prev);
  };

  const existingSiteIds = useMemo(() => {
    if (areChannelsPassed || isSceneViewingMode) {
      return passedOrSelectedSites;
    }

    if (existingSelectedSites.length > 0) {
      return existingSelectedSites;
    }
  }, [
    areChannelsPassed,
    existingSelectedSites,
    isSceneViewingMode,
    passedOrSelectedSites,
  ]);

  const setSelectedChannelsAndUpdateTimelinePlayerProps = (
    channels: number[],
  ) => {
    setSelectedChannelIDs(channels);

    setTimelinePlayerProps({
      autoPlay: true,
      showShare: true,
      showLive: true,
      showLink: false,
      channelIDs: channels,
      channelIdOrder: channels,
    });
  };

  return (
    <ChannelsSelectionContainer>
      <SiteSelection
        existingSiteIds={existingSiteIds}
        loadSitesWithChannelsQuery={loadSitesWithChannelsQuery}
        setSidebarSearch={setSidebarSearch}
        handleIncludeInactiveToggled={handleIncludeInactiveToggled}
        showInactiveChannels={showInactiveChannels}
      />
      {sitesWithChannelsQueryRef ? (
        <Suspense fallback={<LoadingSpinner />}>
          <ChannelsList
            queryReference={sitesWithChannelsQueryRef}
            sidebarSearch={sidebarSearch}
            showInactiveChannels={showInactiveChannels}
            selectedChannels={selectedChannelIDs}
            setSelectedChannels={
              setSelectedChannelsAndUpdateTimelinePlayerProps
            }
            selectedChannelTileRefs={selectedChannelTileRefs}
            setSelectedChannelTileRefs={setSelectedChannelTileRefs}
          />
        </Suspense>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            textAlign: 'center',
          }}>
          <p>Please select sites from the selector above to proceed</p>
        </div>
      )}
    </ChannelsSelectionContainer>
  );
};

const ChannelsSelection = ({
  selectedChannelIDs,
  passedChannelIds,
  isSceneViewingMode,
  setSelectedChannelIDs,
  setTimelinePlayerProps,
  selectedChannelTileRefs,
  setSelectedChannelTileRefs,
}: ChannelSelectionProps) => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ChannelsSelectionContent
        selectedChannelIDs={selectedChannelIDs}
        setSelectedChannelIDs={setSelectedChannelIDs}
        passedChannelIds={passedChannelIds}
        isSceneViewingMode={isSceneViewingMode}
        setTimelinePlayerProps={setTimelinePlayerProps}
        selectedChannelTileRefs={selectedChannelTileRefs}
        setSelectedChannelTileRefs={setSelectedChannelTileRefs}
      />
    </Suspense>
  );
};

export { ChannelsSelection };
