import { Tabs, Typography } from 'antd';
import _ from 'lodash';
import React from 'react';

import LineFilters from '@/components/SearchForm2/LineFilters';
import PathFilters from '@/components/SearchForm2/PathFilters';
import RegionFilters from '@/components/SearchForm2/RegionFilters';
import { CheckCircleFilled } from '@ant-design/icons';
import styles from './style.less';

type State = any;

export type Props = {
  baseStationVersion?: string;
  channelNode: any;
  value: any;
  onChange: Function;
  filtersToShow?: FILTERS[];
};

export enum FILTERS {
  'LINE_CALIBRATE' = 'Line_Calibrate',
  'LINE' = 'Line',
  'REGION' = 'Area', // User facing name changed to Area from Region
  'PATH' = 'Path',
}

class SpatialFilters extends React.Component<Props, State> {
  static defaultProps = {
    value: undefined,
    onChange: () => {},
  };
  constructor(props: Props) {
    super(props);
    let spatialFilters = _.get(this.props, 'value');

    if (spatialFilters === undefined) {
      spatialFilters = _.get(this.props, 'channelNode.spatialFilters');
    }

    this.state = {
      lineFilters: _.get(spatialFilters, 'lineFilters', []),
      regionFilters: _.get(spatialFilters, 'regionFilters', []),
      pathFilters: _.get(spatialFilters, 'pathFilters', []),
    };
  }

  private getDefaultFilterValue = (type: FILTERS): any[] => {
    if (type === FILTERS.LINE_CALIBRATE || type === FILTERS.LINE) {
      return this.state.lineFilters;
    }
    if (type === FILTERS.REGION) {
      return this.state.regionFilters;
    }
    if (type === FILTERS.PATH) {
      return this.state.pathFilters;
    }
    return [];
  };

  render() {
    const channelNode = _.get(this.props, 'channelNode', null);
    let media = _.get(this.props, 'channelNode.Media[0]');
    if (!media || !_.get(media, 'Thumbnail.SignedUrl')) {
      media = _.get(this.props, 'channelNode.LatestMedia[0]');
    }
    const {
      baseStationVersion,
      onChange,
      filtersToShow = [FILTERS.LINE, FILTERS.REGION, FILTERS.PATH],
    } = this.props;

    const getAllDefaultFiltersString = () => {
      const lineFilterStr = this.state.lineFilters
        .map((item, idx) => `Line ${idx + 1}`)
        .join(', ');
      const regionFilterStr = this.state.regionFilters
        .map((item, idx) => `Area ${idx + 1}`)
        .join(', ');
      const pathFilterStr = this.state.pathFilters
        .map((item, idx) => `Path ${idx + 1}`)
        .join(', ');

      return [lineFilterStr, regionFilterStr, pathFilterStr]
        .filter(Boolean)
        .join(', ');
    };

    const allDefaultFiltersString = getAllDefaultFiltersString();

    let getFilter = (type: FILTERS) => {
      if (type === FILTERS.LINE_CALIBRATE) {
        return (
          <LineFilters
            baseStationVersion={baseStationVersion}
            channelNode={channelNode}
            media={media}
            updateFilters={(filter: any) => {
              this.setState({ lineFilters: filter }, () => {
                onChange(this.state);
              });
            }}
            defaultFilterValue={this.state.lineFilters}
            hasCalibration={true}
            noFiltersActive={allDefaultFiltersString === ''}
          />
        );
      }
      if (type === FILTERS.LINE) {
        return (
          <LineFilters
            baseStationVersion={baseStationVersion}
            channelNode={channelNode}
            media={media}
            updateFilters={(filter: any) => {
              this.setState({ lineFilters: filter }, () => {
                onChange(this.state);
              });
            }}
            defaultFilterValue={this.state.lineFilters}
            noFiltersActive={allDefaultFiltersString === ''}
          />
        );
      }
      if (type === FILTERS.REGION) {
        return (
          <RegionFilters
            baseStationVersion={baseStationVersion}
            channelNode={channelNode}
            media={media}
            updateFilters={(filter: any) => {
              this.setState({ regionFilters: filter }, () => {
                onChange(this.state);
              });
            }}
            defaultFilterValue={this.state.regionFilters}
            noFiltersActive={allDefaultFiltersString === ''}
          />
        );
      }
      if (type === FILTERS.PATH) {
        return (
          <PathFilters
            baseStationVersion={baseStationVersion}
            channelNode={channelNode}
            media={media}
            updateFilters={(filter: any) => {
              this.setState({ pathFilters: filter }, () => {
                onChange(this.state);
              });
            }}
            defaultFilterValue={this.state.pathFilters}
            noFiltersActive={allDefaultFiltersString === ''}
          />
        );
      }
    };

    if (!filtersToShow.length) {
      return null;
    }

    if (filtersToShow.length == 1) {
      return (
        <div className={styles['spatial-filters-ctn']}>
          {getFilter(filtersToShow[0])}
        </div>
      );
    }
    return (
      <div className={styles['spatial-filters-ctn']}>
        <div className={styles['tab-ctn']}>
          <Tabs defaultActiveKey={filtersToShow[0]} centered>
            {filtersToShow.map((type) => {
              return (
                <Tabs.TabPane
                  tab={
                    <span className={styles['tab-style']}>
                      {this.getDefaultFilterValue(type).length > 0 && (
                        <>
                          <CheckCircleFilled style={{ color: 'limegreen' }} />
                          &nbsp;&nbsp;
                        </>
                      )}
                      {type}
                    </span>
                  }
                  key={type}>
                  <div className={styles['camera-name-ctn']}>
                    <Typography.Text strong>Camera Name:&nbsp;</Typography.Text>
                    <Typography.Text>{channelNode.Name}</Typography.Text>
                  </div>

                  {getFilter(type)}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
        {allDefaultFiltersString.length > 0 && (
          <div className={styles['active-filters-list-ctn']}>
            <CheckCircleFilled style={{ color: 'limegreen' }} />
            &nbsp;
            <strong>Active Filters:</strong> {allDefaultFiltersString}
          </div>
        )}
      </div>
    );
  }
}

export default SpatialFilters;
