import type { SceneFragment$data } from './__generated__/SceneFragment.graphql';

export const getSceneName = (scene: SceneFragment$data) => {
  if (scene.name) {
    return scene.name;
  }

  const channels = scene.channels || [];

  const name = channels
    .map((channel) => channel.Name)
    .filter((nm) => nm)
    .slice(0, 3)
    .join(', ');

  if (channels.length > 3) {
    return `${name} and ${channels.length - 3} more`;
  }

  return name;
};
