export const getCurrentTimestamp = () => {
  return Math.floor(+new Date() / 1000);
};

export const getChannelThumbnailImageUrl = (channelData: {
  LatestMedia: string | null | undefined;
}): string => {
  const { LatestMedia } = channelData;

  if (!LatestMedia) return '';

  const latestMediaString = LatestMedia.replace(/'/g, '"')
    .replace(/\bTrue\b/g, 'true')
    .replace(/\bFalse\b/g, 'false')
    .replace(/\bNone\b/g, 'null');

  const latestMediaJson = JSON.parse(latestMediaString);

  return latestMediaJson?.[0]?.Thumbnail?.SignedUrl || '';
};
