import _ from 'lodash';
import moment from 'moment-timezone';

import {
  ChannelGroupNode,
  CH_GRP_TYPE,
  CH_TYPE,
  LocationNode,
} from '@/types/location';

const getStatusChangedAtFormattedTime = (changedAt) =>
  moment.utc(changedAt).fromNow();

export const getChannelONVIFDetails = (channel) => {
  let funcStatusMsg = 'No status update received from client';
  const _funcStatusMsg = _.get(channel, 'FuncStatusMsg', null);
  if (_funcStatusMsg && _funcStatusMsg !== '') {
    funcStatusMsg = _funcStatusMsg;
  }
  const channel_onvif_details = {
    errMessage: '',
    edit: channel.ChannelID === 0,
    // channel,
    channelID: channel.ChannelID,
    serverName: channel.Name,
    serverURL: channel.ServerURL || '/',
    username: channel.Username,
    password: channel.Password,
    ipAddress: channel.IPAddress,
    rtspPort: channel.RTSPPort || 554,
    httpPort: channel.HTTPPort || 80,
    channelStatus: channel.FuncStatus,
    monitorStatus: channel.MonitorStatus,
    channelStatusMsg: funcStatusMsg,
    lastStatusUpdateTime: getStatusChangedAtFormattedTime(
      channel.FuncStatusChangedAt,
    ),
  };

  return channel_onvif_details;
};

export enum ONVIFCameraLabel {
  MANUAL = 1,
  AUTO_DISCOVER = 2,
  ALREADY_ADDED = 3,
}

export class ONVIFCamera {
  Name: string | null;
  ChannelID: number | null;
  FuncStatus: string | null;
  FuncStatusChangedAt: string | null;
  FuncStatusMsg: string | null;
  HTTPPort: string | null;
  IPAddress: string | null;
  Password: string | null;
  RTSPPort: string | null;
  ServerURL: string | null;
  Username: string | null;
  MonitorStatus: string | null;
  Manufacturer: string | null;
  Model: string | null;
  Hardware: string | null;
  labels: Set<ONVIFCameraLabel>;

  constructor(_ch: any) {
    this.Name = _.get(_ch, 'Name', null);
    this.ChannelID = _.get(_ch, 'ChannelID', null) || _.get(_ch, 'ID', null);
    this.FuncStatus = _.get(_ch, 'ChannelDetails.FuncStatus', null);
    this.FuncStatusChangedAt = _.get(
      _ch,
      'ChannelDetails.FuncStatusChangedAt',
      null,
    );
    this.FuncStatusMsg = _.get(_ch, 'ChannelDetails.FuncStatusMsg', null);
    this.HTTPPort = _.get(_ch, 'ChannelDetails.HTTPPort', null);
    this.IPAddress =
      _.get(_ch, 'ChannelDetails.IPAddress', null) ||
      _.get(_ch, 'RTSPconfig.ip_address', null);
    this.Password =
      _.get(_ch, 'ChannelDetails.Password', null) ||
      _.get(_ch, 'RTSPconfig.password', null);
    this.RTSPPort =
      _.get(_ch, 'ChannelDetails.RTSPPort', null) ||
      _.get(_ch, 'RTSPconfig.port_number', null);
    this.ServerURL =
      _.get(_ch, 'ChannelDetails.ServerURL', null) ||
      _.get(_ch, 'RTSPconfig.server_url', null);
    this.Username =
      _.get(_ch, 'ChannelDetails.Username', null) ||
      _.get(_ch, 'RTSPconfig.username', null);
    this.MonitorStatus =
      _.get(_ch, 'MonitorStatus', null) ||
      _.get(_ch, 'RTSPconfig.monitor_status', null);

    this.Manufacturer =
      _.get(_ch, 'ChannelDetails.Manufacturer', null) ||
      _.get(_ch, 'ChannelDetails.MoreInfo.Manufacturer', null);

    this.Model =
      _.get(_ch, 'ChannelDetails.Model', null) ||
      _.get(_ch, 'ChannelDetails.MoreInfo.Model', null);
    this.Hardware =
      _.get(_ch, 'ChannelDetails.Hardware', null) ||
      _.get(_ch, 'ChannelDetails.MoreInfo.Hardware', null);

    this.labels = new Set<ONVIFCameraLabel>();
  }

  update(onvif_ch: ONVIFCamera) {
    if (onvif_ch.Name) this.Name = onvif_ch.Name;
    if (onvif_ch.ChannelID) this.ChannelID = onvif_ch.ChannelID;
    if (onvif_ch.FuncStatus) this.FuncStatus = onvif_ch.FuncStatus;
    if (onvif_ch.FuncStatusChangedAt)
      this.FuncStatusChangedAt = onvif_ch.FuncStatusChangedAt;
    if (onvif_ch.FuncStatusMsg) this.FuncStatusMsg = onvif_ch.FuncStatusMsg;
    if (onvif_ch.HTTPPort) this.HTTPPort = onvif_ch.HTTPPort;
    if (onvif_ch.IPAddress) this.IPAddress = onvif_ch.IPAddress;
    if (onvif_ch.Password) this.Password = onvif_ch.Password;
    if (onvif_ch.RTSPPort) this.RTSPPort = onvif_ch.RTSPPort;
    if (onvif_ch.ServerURL) this.ServerURL = onvif_ch.ServerURL;
    if (onvif_ch.Username) this.Username = onvif_ch.Username;
    if (onvif_ch.MonitorStatus) this.MonitorStatus = onvif_ch.MonitorStatus;
    if (onvif_ch.Manufacturer) this.Manufacturer = onvif_ch.Manufacturer;
    if (onvif_ch.Model) this.Model = onvif_ch.Model;
    if (onvif_ch.Hardware) this.Hardware = onvif_ch.Hardware;

    this.labels = new Set([...this.labels, ...onvif_ch.labels]);
  }

  addLabels(labels: ONVIFCameraLabel[]) {
    this.labels = new Set([...this.labels, ...labels]);
  }

  isCheckable() {
    return (
      this.Name && this.HTTPPort && this.IPAddress
      // this.Password &&
      // this.RTSPPort &&
      // this.ServerURL &&
      // this.Username
    );
  }

  isAddedToLocation() {
    return this.ChannelID && this.ChannelID > 0;
  }

  getRTSPurl() {
    return `rtsp://${this.Username}:${this.Password}@${this.IPAddress}:${this.RTSPPort}${this.ServerURL}`;
  }

  getUniqueKey() {
    return `${this.IPAddress}:${this.RTSPPort}${this.ServerURL}`;
  }

  updateFromONVIFConfigFormField(channelServerCred: any) {
    this.Name = _.get(channelServerCred, 'camera_name', null) || this.Name;
    this.Username = _.get(channelServerCred, 'username', null) || this.Username;
    this.Password = _.get(channelServerCred, 'password', null) || this.Password;
    this.IPAddress =
      _.get(channelServerCred, 'ip_address', null) || this.IPAddress;
    this.RTSPPort =
      _.get(channelServerCred, 'port_number', null) || this.RTSPPort;
    this.ServerURL =
      _.get(channelServerCred, 'server_url', null) || this.ServerURL;
  }
}

export const getChannelList = (
  node: LocationNode,
  { ch_grp, ch }: { ch_grp: CH_GRP_TYPE; ch: CH_TYPE },
) => {
  const node_queue: (LocationNode | ChannelGroupNode)[] = [node];
  let channel_list: any[] = [];

  while (node_queue.length > 0) {
    const nodee = node_queue.shift();
    const chs = _.get(nodee, 'Channels', [])
      .map((id: number) => ch.byId[+id])
      .filter((c) => c);
    const ch_grps = _.get(nodee, 'ChannelGroups', [])
      .map((id: number) => ch_grp.byId[+id])
      .filter((cg) => cg);
    channel_list = channel_list.concat(chs);
    if (ch_grps.length > 0) node_queue.push(...ch_grps);
  }

  return channel_list.map((c) => new ONVIFCamera(c));
};

export type RTSPURLOBJ = {
  username: string | null;
  password: string | null;
  ip: string | null;
  port: string | null;
  serverURl: string | null;
  query: string | null;
};

export class RTSPURL {
  static rtspRegex: RegExp =
    /^rtsp:\/\/(\b([^:?\/%]){1,1000}):(\b([^:?\/%]){1,1000})@((\b\d{1,3}.\b\d{1,3}.\b\d{1,3}.\b\d{1,3}\b)|([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}))((:(\b\d{1,5})){0,1})((\/([^:?%])*){0,1})((\?([^:?\/%])*){0,1})$/g;
  static usernameRegex: RegExp = /\b([^:?\/%]){1,1000}$/g;
  static passwordRegex: RegExp = /\b([^:?\/%]){1,1000}$/g;
  static ipRegex: RegExp =
    /^((\b\d{1,3}.\b\d{1,3}.\b\d{1,3}.\b\d{1,3}\b)|([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}))$/g;
  static portRegex: RegExp = /\d{1,5}$/g;

  static validateRTSP_URL(url: string): boolean {
    const arr = url.match(RTSPURL.rtspRegex);
    return Array.isArray(arr) && arr.length > 0 && arr[0].length === url.length;
  }
  static validateRTSP_Username(username: string): boolean {
    const arr = username.match(RTSPURL.usernameRegex);
    return (
      Array.isArray(arr) && arr.length > 0 && arr[0].length === username.length
    );
  }
  static validateRTSP_Password(password: string): boolean {
    const arr = password.match(RTSPURL.passwordRegex);
    return (
      Array.isArray(arr) && arr.length > 0 && arr[0].length === password.length
    );
  }
  static validateRTSP_IP(ip: string): boolean {
    const arr = ip.match(RTSPURL.ipRegex);
    return Array.isArray(arr) && arr.length > 0 && arr[0].length === ip.length;
  }
  static validateRTSP_Port(port: string): boolean {
    const arr = port.match(RTSPURL.portRegex);
    return (
      Array.isArray(arr) && arr.length > 0 && arr[0].length === port.length
    );
  }
  static getRTSPURLOBJFromRTSPurl(url: string): RTSPURLOBJ {
    const rtspUrl_obj: RTSPURLOBJ = {
      username: null,
      password: null,
      ip: null,
      port: null,
      serverURl: null,
      query: null,
    };

    const regex_str = /^rtsp:\/\/(.*):(.*)@(\d*.\d*.\d*.\d*)/g;
    if (url.match(regex_str)) {
      // RTSP URL structure
      // rtsp://{username}:{password}@{ip}:{port}{serverurl}?{query}
      const rtsp_url = url.trim().replace('rtsp://', '');

      const ip_port_serverurl_index = rtsp_url.lastIndexOf('@');
      const username_password_string = rtsp_url.substring(
        0,
        ip_port_serverurl_index || rtsp_url.length,
      );
      const ip_port_serverurl_string = rtsp_url.substring(
        ip_port_serverurl_index > 0 ? ip_port_serverurl_index + 1 : 0,
        rtsp_url.length,
      );

      const password_index = username_password_string.indexOf(':');
      rtspUrl_obj.username = username_password_string.substring(
        0,
        password_index || username_password_string.length,
      );
      rtspUrl_obj.password = username_password_string.substring(
        password_index > 0 ? password_index + 1 : 0,
        username_password_string.length,
      );

      const server_url_index = ip_port_serverurl_string.indexOf('/');
      const ip_port_string = ip_port_serverurl_string.substring(
        0,
        server_url_index || ip_port_serverurl_string.length,
      );
      const port_index = ip_port_string.indexOf(':');
      rtspUrl_obj.ip = ip_port_string.substring(
        0,
        port_index > 0 ? port_index : ip_port_string.length,
      );
      rtspUrl_obj.port =
        port_index > 0
          ? ip_port_string.substring(port_index + 1, ip_port_string.length)
          : null;

      const server_query_string = ip_port_serverurl_string.substring(
        server_url_index > 0 ? server_url_index + 1 : 0,
        ip_port_serverurl_string.length,
      );
      const query_index = server_query_string.lastIndexOf('?');
      if (query_index > 0) {
        rtspUrl_obj.serverURl =
          '/' + server_query_string.substring(0, query_index);
        rtspUrl_obj.query = server_query_string.substring(
          query_index + 1,
          url.length,
        );
      } else {
        rtspUrl_obj.serverURl = '/' + server_query_string;
      }
    }
    return rtspUrl_obj;
  }
}
