import type { SelectorStoreUpdater } from 'node_modules/@types/relay-runtime/lib/store/RelayStoreTypes';
import {
  commitLocalUpdate,
  ConnectionHandler,
  useRelayEnvironment,
} from 'react-relay';
import type { TeamScenesPaginationQuery$variables } from './__generated__/TeamScenesPaginationQuery.graphql';

const useTeamScenesConnectionInfo = (appId: number, customerId: number) => {
  const environment = useRelayEnvironment();

  const invalidateTeamScenes: SelectorStoreUpdater = (store) => {
    const root = store.getRoot();
    const teamScenesData = root
      .getLinkedRecord('monitorTeamScenes', {
        appId,
        customerId,
      })
      ?.getLinkedRecord('scenesData');
    teamScenesData?.invalidateRecord();
  };

  const getTeamScenesConnectionId = (
    variables: Partial<TeamScenesPaginationQuery$variables>,
  ) => {
    let connectionId: string | null = null;
    commitLocalUpdate(environment, (store) => {
      const root = store.getRoot();
      const teamScenesData = root
        .getLinkedRecord('monitorTeamScenes', {
          appId,
          customerId,
        })
        ?.getLinkedRecord('scenesData');
      if (teamScenesData) {
        connectionId = ConnectionHandler.getConnectionID(
          teamScenesData.getDataID(),
          'TeamScenesConnection_scenes',
          variables,
        );
      }
    });
    return connectionId as string | null;
  };

  return {
    invalidateTeamScenes,
    getTeamScenesConnectionId,
  };
};

export default useTeamScenesConnectionInfo;
