import {
  addAttachmentToInvestigation,
  addAttachmentToReport,
  addBatchEventsToInvestigation,
  addEventToInvestigation,
  addEventToReport,
  addSummaryToReport,
  archiveReport,
  createInvestigation,
  createInvestigationGroup,
  createReport,
  deleteInvestigation,
  deleteInvestigationAttachment,
  deleteInvestigationGroup,
  deleteReport,
  deleteReportItem,
  getAttachmentUploadUrl,
  getInvestigation,
  getInvestigationConnectionCode,
  getInvestigations,
  getReport,
  markInvestigaitonMediaUploadComplete,
  moveInvestigation,
  moveInvestigationGroup,
  renameInvestigationGroup,
  sendInstructionsEmail,
  toggleReportItemInfo,
  updateEventMediaUploadTimecode,
  updateInvestigation,
  updateInvestigationAttachment,
  updateInvestigationTimelineConfig,
  updateReport,
  updateReportItem,
  updateReportItemsOrder,
} from '@/services/investigations';
import _ from 'lodash';

import { getSignedUploadUrl, uploadFileToS3 } from '@/services/upload';

import {
  processCreateInvestigtion,
  processGetInvestigtion,
  processGetInvestigtions,
  processUpdateInvestigtion,
  updateInvestigationsData,
} from '@/types/investigations';
import { message, notification } from 'antd';
import { history } from 'umi';

import { logError } from '@/monitoring';
import { InvestigationGroupType } from '@/pages/investigations/investigation-group/types';
import type {
  InvestigationData,
  InvestigationState,
} from '@/types/investigations';
import { processInvestigationsTree } from '@/utils/investigation';
import { getCurrentCustomerID, getMapChannelMedia } from '@/utils/utils';
import type { Model } from 'dva';

const eventTarget = (() => {
  let handle: any;
  const off = () => {
    handle = undefined;
    return handle;
  };
  return {
    on(fn: any) {
      handle = fn;
      return off;
    },
    off,
    trigger(e: any) {
      if (handle) {
        handle(e);
      }
    },
  };
})();

export type InvestigationModalState = {
  all: any[];
  byID: Object;
  forTimelineByID: Object; // similar to byID but only contains preview information
  reportsByID: Object;
  uploads: Object;
  mediaUploads: Object;
  investigationGroups: {
    all: InvestigationGroupType[];
    byId: Record<number | 'root', InvestigationGroupType>;
  };
} & InvestigationState;

const InvestigationsModal: Model & { state: InvestigationModalState } = {
  namespace: 'investigations',
  state: {
    all: [],
    byID: {},
    forTimelineByID: {},
    reportsByID: {},
    uploads: {},
    mediaUploads: {},
    investigationGroups: {
      all: [],
      byId: {},
    },
    investigations: {
      byId: {},
    },
    events: {
      byId: {},
      mapInvestigationEvent: {},
      mapChannelEvent: {},
    },
    attachments: {
      byId: {},
      mapInvestigationAttachment: {},
    },
    reports: {
      byId: {},
      mapInvestigationReport: {},
    },
    summaries: {
      byId: {},
      mapEventSummary: {},
    },
  },
  subscriptions: {
    setup({ dispatch }) {
      return eventTarget.on(dispatch);
    },
  },
  effects: {
    *fetchInvestigations(action, { call, put }) {
      const response = yield call(getInvestigations);
      if (response.success) {
        const { allInvestigations, allInvestigationGroups } =
          processInvestigationsTree(response.data);
        yield put({
          type: 'saveInvestigations',
          payload: {
            allInvestigations,
            allInvestigationGroups,
          },
        });
        const investigation_data = processGetInvestigtions(allInvestigations);
        if (Object.keys(investigation_data).length > 0) {
          yield put({
            type: 'saveInvestigationsData',
            data: investigation_data,
          });
        }
      }
    },
    *createInvestigation(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => createInvestigation(payload));
      if (response.success) {
        yield put({ type: 'fetchInvestigations' });
        // todo
        const investigation_data = processCreateInvestigtion(response.data);
        if (Object.keys(investigation_data).length > 0) {
          yield put({
            type: 'saveInvestigationsData',
            data: investigation_data,
          });
        }
      }
      return response;
    },
    *updateInvestigation(action, { call, put }) {
      const { investigationID, payload } = action;
      const response = yield call(() =>
        updateInvestigation(investigationID, payload),
      );
      if (response.success) {
        yield put({
          type: 'modifyInvestigation',
          payload: response.data,
        });
        // todo
        const investigation_data = processUpdateInvestigtion(response.data);
        if (Object.keys(investigation_data).length > 0) {
          yield put({
            type: 'saveInvestigationsData',
            data: investigation_data,
          });
        }
      }
    },
    *moveInvestigation(action, { call, put }) {
      const { investigationID, payload } = action;
      const response = yield call(() =>
        moveInvestigation(investigationID, payload),
      );
      if (response.success) {
        const { allInvestigations, allInvestigationGroups } =
          processInvestigationsTree(response.data);
        yield put({
          type: 'saveInvestigations',
          payload: {
            allInvestigations,
            allInvestigationGroups,
          },
        });
        const investigation_data = processGetInvestigtions(allInvestigations);
        if (Object.keys(investigation_data).length > 0) {
          yield put({
            type: 'saveInvestigationsData',
            data: investigation_data,
          });
        }
        yield put({
          type: 'fetchInvestigation',
          investigation: { InvestigationID: investigationID },
        });
      }
      return response;
    },
    *updateInvestigationTimelineConfig(action, { call, put }) {
      const { investigationID, payload } = action;
      const response = yield call(() =>
        updateInvestigationTimelineConfig(investigationID, payload),
      );
      if (response.success) {
        yield put({
          type: 'modifyInvestigation',
          payload: response.data,
        });
        yield put({
          type: 'modifyTimelineConfig',
          investigationID,
          payload: response.data,
        });
      }
      return response;
    },
    *deleteInvestigation(action, { call, put }) {
      const { investigationID } = action;
      const response = yield call(() => deleteInvestigation(investigationID));
      if (response.success) {
        yield put({ type: 'fetchInvestigations' });
        history.push('/investigations');
      }
    },
    *fetchInvestigation(action, { call, put }) {
      const { investigation } = action;
      const response = yield call(() =>
        getInvestigation(investigation.InvestigationID),
      );
      if (response.success) {
        yield put({
          type: 'saveInvestigationByID',
          payload: response.data,
        });
        // todo
        const investigation_data = processGetInvestigtion(response.data);
        if (Object.keys(investigation_data).length > 0) {
          yield put({
            type: 'saveInvestigationsData',
            data: investigation_data,
          });
        }
      }
    },
    *fetchInvestigationForTimeline(action, { call, put }) {
      const { investigationID } = action;
      const response = yield call(() =>
        getInvestigation(investigationID, true),
      );
      if (response.success) {
        yield put({
          type: 'saveInvestigationForTimelineByID',
          payload: response.data,
        });
        // todo
        const investigation_data = processGetInvestigtion(response.data);
        if (Object.keys(investigation_data).length > 0) {
          yield put({
            type: 'saveInvestigationsData',
            data: investigation_data,
          });
        }
      }
      return response;
    },
    *createInvestigationGroup(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => createInvestigationGroup(payload));
      if (response.success) {
        yield put({ type: 'fetchInvestigations' });
      }
      return response;
    },
    *renameInvestigationGroup(action, { call, put }) {
      const { investigationGroupID, payload } = action;
      const response = yield call(() =>
        renameInvestigationGroup(investigationGroupID, payload),
      );
      if (response.success) {
        yield put({ type: 'fetchInvestigations' });
      }
      return response;
    },
    *moveInvestigationGroup(action, { call, put }) {
      const { investigationGroupID, payload } = action;
      const response = yield call(() =>
        moveInvestigationGroup(investigationGroupID, payload),
      );
      if (response.success) {
        const { allInvestigations, allInvestigationGroups } =
          processInvestigationsTree(response.data);
        yield put({
          type: 'saveInvestigations',
          payload: {
            allInvestigations,
            allInvestigationGroups,
          },
        });
        const investigation_data = processGetInvestigtions(allInvestigations);
        if (Object.keys(investigation_data).length > 0) {
          yield put({
            type: 'saveInvestigationsData',
            data: investigation_data,
          });
        }
      }
      return response;
    },
    *deleteInvestigationGroup(action, { call, put }) {
      const { investigationGroupID } = action;
      const response = yield call(() =>
        deleteInvestigationGroup(investigationGroupID),
      );
      if (response.success) {
        yield put({ type: 'fetchInvestigations' });
      }
      return response;
    },
    *fetchReport(action, { call, put }) {
      const { investigationID, reportID } = action;
      const response = yield call(() => getReport(investigationID, reportID));
      if (response.success) {
        yield put({
          type: 'saveReportByID',
          payload: response.data,
        });
      }
    },
    *addEventToInvestigation(action, { call, put }) {
      const { payload } = action;
      const { investigationID, name, startTime, endTime, channelID } = payload;
      const response = yield call(() =>
        addEventToInvestigation(
          investigationID,
          name,
          `${startTime.format('YYYY-MM-DDTHH:mm:ss.000000')}Z`,
          `${endTime.format('YYYY-MM-DDTHH:mm:ss.000000')}Z`,
          channelID,
        ),
      );
      if (response.success) {
        yield put({
          type: 'investigation_events/fetchInvestigationEvents',
          investigationID,
        });
        yield put({ type: 'fetchInvestigations' });
      } else {
        logError(new Error('Creating investigation event failed'), {
          customer_id: getCurrentCustomerID(),
          channel_id: channelID,
          investigation_id: investigationID,
          start_time: startTime,
          end_time: endTime,
          name,
          timestamp: Date.now(),
        });
      }
      return response;
    },
    *addBatchEventsToInvestigation(action, { call, put }) {
      const { payload } = action;
      const { investigationID, eventsData } = payload;
      const response = yield call(() =>
        addBatchEventsToInvestigation(investigationID, { events: eventsData }),
      );
      if (response.success) {
        yield put({
          type: 'investigation_events/fetchInvestigationEvents',
          investigationID,
        });
        yield put({ type: 'fetchInvestigations' });
      }

      return response;
    },
    *getMediaUploadUrl(action, { call }) {
      const { payload } = action;
      const response = yield call(() => getSignedUploadUrl(payload));
      return response;
    },
    *uploadMedia(action, { call }) {
      const { payload } = action;
      window.onbeforeunload = (e: any) => {
        const dialogText =
          'Upload in progress. Do you really want to leave this site?';
        e.returnValue = dialogText;
        return dialogText;
      };
      payload.onUploadProgress = (progressEvent: any) => {
        payload.stats = {
          progress: Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          ),
        };
        eventTarget.trigger({ type: 'updateUploadMedia', payload });
      };
      const { response, error } = yield call(uploadFileToS3, payload);
      if (response) {
        return { response };
      }
      payload.error = error;
      eventTarget.trigger({ type: 'updateUploadMedia', payload });
      message.error('Error uploading file. try again.', 3);
      return { error };
    },
    *clearUploadMedia(action, { put }) {
      const { payload } = action;
      const { investigation } = payload;
      yield put({
        type: 'removeUploadMedia',
        payload: {
          investigationID: investigation.InvestigationID,
        },
      });
    },
    *markInvestigaitonMediaUploadComplete(action, { call, put }) {
      const { investigation, payload } = action;
      const response = yield call(() =>
        markInvestigaitonMediaUploadComplete(investigation, payload),
      );
      if (response.success) {
        yield put({ type: 'locations/fetchLocations' });
        yield put({
          type: 'investigation_events/fetchInvestigationEvents',
          investigationID: investigation.InvestigationID,
        });
      }
    },
    *updateEventMediaUploadTimecode(action, { call, put }) {
      const { investigationEvent, payload } = action;
      const response = yield call(() =>
        updateEventMediaUploadTimecode(investigationEvent, payload),
      );
      if (response.success) {
        yield put({
          type: 'investigation_events/modifyInvestigationEvent',
          payload: response.data,
        });
        yield put({
          type: 'modifyInvestigationForTimelineByID',
          payload: {
            investigationID: investigationEvent.InvestigationID,
            eventID: investigationEvent.InvestigationEventID,
            data: response.data,
          },
        });
      }
    },
    *getAttachmentUploadUrl(action, { call }) {
      const { payload } = action;
      const { investigation, name } = payload;
      const response = yield call(() =>
        getAttachmentUploadUrl(investigation, name),
      );
      return response;
    },
    *uploadAttachment(action, { call }) {
      const { payload } = action;
      window.onbeforeunload = (e: any) => {
        const dialogText =
          'Upload in progress. Do you really want to leave this site?';
        e.returnValue = dialogText;
        return dialogText;
      };
      payload.onUploadProgress = (progressEvent: any) => {
        payload.stats = {
          progress: Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          ),
        };
        eventTarget.trigger({ type: 'updateUploadAttachment', payload });
      };
      const { response, error } = yield call(uploadFileToS3, payload);
      if (response) {
        return { response };
      }
      payload.error = error;
      eventTarget.trigger({ type: 'updateUploadAttachment', payload });
      message.error('Error uploading file. try again.', 3);
      return { error };
    },
    *clearUploadAttachments(action, { put }) {
      const { payload } = action;
      const { investigation } = payload;
      yield put({
        type: 'removeUploadAttachments',
        payload: {
          investigationID: investigation.InvestigationID,
        },
      });
    },
    *addAttachmentToInvestigation(action, { call, put }) {
      const { payload } = action;
      const { investigation, name, s3Path, mime_type } = payload;
      const response = yield call(() =>
        addAttachmentToInvestigation(investigation, name, mime_type, s3Path),
      );
      if (response.success) {
        yield put({
          type: 'addAttachment',
          payload: response.data,
          investigationID: investigation.InvestigationID,
        });
        yield put({ type: 'fetchInvestigations' });
      }
    },
    *deleteInvestigationAttachment(action, { call, put }) {
      const { investigationID, attachmentID } = action;
      const response = yield call(() =>
        deleteInvestigationAttachment(investigationID, attachmentID),
      );
      if (response.success) {
        yield put({
          type: 'removeAttachment',
          payload: { investigationID, attachmentID },
        });
        yield put({ type: 'fetchInvestigations' });
      }
    },
    *updateInvestigationAttachment(action, { call, put }) {
      const { investigationID, attachmentID, payload } = action;
      const response = yield call(() =>
        updateInvestigationAttachment(investigationID, attachmentID, payload),
      );
      if (response.success) {
        yield put({
          type: 'renameAttachment',
          payload: response.data,
          investigationID,
          attachmentID,
        });
      }
    },
    *createReport(action, { call, put }) {
      const { investigation, payload } = action;
      const { name, description, reportItems } = payload; // reportItems will be object: {eventID: ['345'], attachmentId: ['56'], summaryID:['23']}
      const response = yield call(() =>
        createReport(investigation, name, description, reportItems),
      );
      if (response.success) {
        yield put({
          type: 'addReport',
          payload: response.data,
          investigationID: investigation.InvestigationID,
        });
        yield put({ type: 'fetchInvestigations' });
      }
      return response;
    },
    *deleteReport(action, { call, put }) {
      const { investigationID, reportID } = action;
      const response = yield call(() =>
        deleteReport(investigationID, reportID),
      );
      if (response.success) {
        yield put({
          type: 'removeReport',
          investigationID,
          reportID,
        });
        yield put({ type: 'fetchInvestigations' });
      }
    },
    *updateReport(action, { call, put }) {
      const { investigationID, reportID, payload } = action;
      const response = yield call(() =>
        updateReport(investigationID, reportID, payload),
      );
      if (response.success) {
        yield put({
          type: 'modifyReport',
          payload: response.data,
          investigationID,
          reportID,
        });
        yield put({ type: 'fetchReport', investigationID, reportID });
      }
      return response;
    },
    *updateReportItem(action, { call, put }) {
      const { reportID, reportItemID, payload } = action;
      const response = yield call(() =>
        updateReportItem(reportID, reportItemID, payload),
      );
      if (response.success) {
        yield put({
          type: 'modifyReportItem',
          reportID,
          reportItemID,
          payload: response.data,
        });
      }
    },
    *toggleReportItemInfo(action, { call, put }) {
      const { reportID, reportItemID } = action;
      const response = yield call(() =>
        toggleReportItemInfo(reportID, reportItemID, {}),
      );
      if (response.success) {
        yield put({
          type: 'modifyReportItem',
          reportID,
          reportItemID,
          payload: response.data,
        });
      }
    },
    *deleteReportItem(action, { call, put }) {
      const { reportID, reportItemID } = action;
      const response = yield call(() =>
        deleteReportItem(reportID, reportItemID),
      );
      if (response.success) {
        yield put({
          type: 'removeReportItem',
          reportID,
          reportItemID,
          payload: response.data,
        });
      }
    },
    *moveReportItem(action, { put, select }) {
      const { reportID, reportItemID, toIndex } = action;
      yield put({
        type: 'moveReportItemToIndex',
        reportID,
        reportItemID,
        toIndex,
      });

      const investigations = yield select((state: any) => state.investigations);
      const items = investigations.reportsByID[reportID].ReportItems;
      yield put({
        type: 'updateReportItemsOrder',
        reportID,
        payload: items.map((item: any) => _.pick(item, ['ReportItemID'])),
      });
    },
    *updateReportItemsOrder(action, { call, put }) {
      const { reportID, payload } = action;
      const response = yield call(() =>
        updateReportItemsOrder(reportID, payload),
      );
      if (response.success) {
        yield put({
          type: 'saveReportByID',
          payload: response.data,
        });
      }
    },
    *archiveReport(action, { call, put }) {
      const { investigationID, reportID } = action;
      const response = yield call(() =>
        archiveReport(investigationID, reportID),
      );
      if (response.success) {
        yield put({
          type: 'removeReport',
          investigationID,
          reportID,
        });
        yield put({ type: 'fetchInvestigations' });
      }
    },
    *addEventToReport(action, { call, put }) {
      const { investigationID, reportID, eventID } = action;
      const response = yield call(() =>
        addEventToReport(investigationID, reportID, eventID),
      );
      if (response.success) {
        notification.open({
          message: 'Event added to report',
          className: 'df-notification',
          placement: 'bottomRight',
        });
        yield put({
          type: 'modifyReport',
          payload: response.data,
          investigationID,
          reportID,
        });
      }
    },
    *addAttachmentToReport(action, { call, put }) {
      const { investigationID, reportID, attachmentID } = action;
      const response = yield call(() =>
        addAttachmentToReport(investigationID, reportID, attachmentID),
      );
      if (response.success) {
        notification.open({
          message: ' Attachment added to report',
          className: 'df-notification',
          placement: 'bottomRight',
        });
        yield put({
          type: 'modifyReport',
          payload: response.data,
          investigationID,
          reportID,
        });
      }
    },
    *addSummaryToReport(action, { call, put }) {
      const { investigationID, reportID, summaryID, eventID } = action;
      const response = yield call(() =>
        addSummaryToReport(investigationID, reportID, summaryID, eventID),
      );
      if (response.success) {
        notification.open({
          message: 'Summary added to report',
          className: 'df-notification',
          placement: 'bottomRight',
        });
        yield put({
          type: 'modifyReport',
          payload: response.data,
          investigationID,
          reportID,
        });
      }
      return response;
    },
    *fetchInvestigationConnectionCode(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() =>
        getInvestigationConnectionCode(payload.investigationID),
      );
      if (response.success) {
        yield put({
          type: 'saveInvestigationCode',
          payload: response.data,
          investigationID: payload.investigationID,
        });
      }
      return response;
    },
    *emailInstructions(action, { call }) {
      const { payload } = action;
      yield call(() => sendInstructionsEmail(payload));
    },
  },
  reducers: {
    saveInvestigations(state, action) {
      const investigationGroupsById =
        action.payload.allInvestigationGroups.reduce((acc, group) => {
          // If it's the root group (the one without an ID), assign it a special key root
          if (group.GroupID === undefined) {
            acc['root'] = group;
          } else {
            acc[group.GroupID] = group;
          }
          return acc;
        }, {});
      return {
        ...state,
        all: action.payload.allInvestigations,
        investigationGroups: {
          all: action.payload.allInvestigationGroups,
          byId: investigationGroupsById,
        },
      };
    },
    addInvestigation(state, action) {
      const { all } = state;
      all.push(action.payload);
      return { ...state, all };
    },
    modifyInvestigation(state, action) {
      let { all, byID } = state;
      all = all.map((investigation: any) => {
        if (investigation.InvestigationID === action.payload.InvestigationID) {
          return action.payload;
        }
        return investigation;
      });
      byID[action.payload.InvestigationID] = action.payload;
      return { ...state, all, byID };
    },
    removeInvestigation(state, action) {
      let { all } = state;
      all = all.filter(
        (investigation: any) =>
          investigation.InvestigationID !== action.payload.investigationID,
      );
      return { ...state, all };
    },
    saveInvestigationByID(state, action) {
      const { byID, forTimelineByID } = state;
      byID[action.payload.InvestigationID] = action.payload;
      forTimelineByID[action.payload.InvestigationID] = action.payload;
      return { ...state, byID, forTimelineByID };
    },
    saveInvestigationForTimelineByID(state, action) {
      const { forTimelineByID } = state;
      forTimelineByID[action.payload.InvestigationID] = action.payload;
      return { ...state, forTimelineByID };
    },
    modifyTimelineConfig(state, action) {
      const { forTimelineByID } = state;
      forTimelineByID[action.investigationID] = action.payload;
      return { ...state, forTimelineByID };
    },
    modifyInvestigationForTimelineByID(state, action) {
      const { forTimelineByID } = state;
      const investigation = forTimelineByID[action.payload.investigationID];
      if (investigation) {
        investigation.InvestigationEvents =
          investigation.InvestigationEvents.map((event: any) => {
            if (event.InvestigationEventID === action.payload.eventID) {
              event = { ...event, ...action.payload.data };
            }
            return event;
          });
        forTimelineByID[action.payload.investigationID] = investigation;
        return { ...state, forTimelineByID };
      }
      return state;
    },
    updateUploadMedia(state, action) {
      const { mediaUploads } = state;
      mediaUploads[action.payload.investigationID] = {
        ...mediaUploads[action.payload.investigationID],
        [action.payload.fields.key]: action.payload,
      };
      return { ...state, mediaUploads };
    },
    removeUploadMedia(state, action) {
      const { mediaUploads } = state;
      delete mediaUploads[action.payload.investigationID];
      window.onbeforeunload = null;
      return { ...state, mediaUploads };
    },
    updateUploadAttachment(state, action) {
      const { uploads } = state;
      uploads[action.payload.investigationID] = {
        ...uploads[action.payload.investigationID],
        [action.payload.fields.key]: action.payload,
      };
      return { ...state, uploads };
    },
    removeUploadAttachments(state, action) {
      const { uploads } = state;
      delete uploads[action.payload.investigationID];
      window.onbeforeunload = null;
      return { ...state, uploads };
    },
    addAttachment(state, action) {
      const { byID } = state;
      const investigation = byID[action.investigationID];
      const attachment = action.payload;
      investigation.InvestigationAttachments = [
        attachment,
        ...investigation.InvestigationAttachments,
      ];
      byID[action.payload.investigationID] = investigation;
      return { ...state, byID };
    },
    removeAttachment(state, action) {
      const { byID } = state;
      const investigation = byID[action.payload.investigationID];
      investigation.InvestigationAttachments =
        investigation.InvestigationAttachments.filter(
          (event: any) =>
            event.InvestigationAttachmentID !== action.payload.attachmentID,
        );
      return { ...state, byID };
    },
    renameAttachment(state, action) {
      const { byID } = state;
      const investigation = byID[action.payload.investigationID];
      investigation.InvestigationAttachments =
        investigation.InvestigationAttachments.map((event: any) => {
          if (
            event.InvestigationAttachmentID ===
            action.payload.InvestigationAttachmentID
          ) {
            return action.payload;
          }
          return event;
        });
      return { ...state, byID };
    },
    saveReportByID(state, action) {
      const { reportsByID } = state;
      reportsByID[action.payload.InvestigationReportID] = action.payload;
      return { ...state, reportsByID };
    },
    modifyReportItem(state, action) {
      const { reportsByID } = state;
      const report = reportsByID[action.reportID];
      report.ReportItems = report.ReportItems.map((item: any) => {
        if (item.ReportItemID === action.reportItemID) {
          item = { ...item, ...action.payload };
        }
        return item;
      });
      reportsByID[action.reportID] = report;
      return { ...state, reportsByID };
    },
    removeInvestigationReport(state, action) {
      let { all } = state;
      all = all.filter(
        (investigation: any) =>
          investigation.InvestigationID !== action.payload.investigationID,
      );
      return { ...state, all };
    },
    moveReportItemToIndex(state, action) {
      const { reportsByID } = state;
      const report = reportsByID[action.reportID];
      const fromIndex = report.ReportItems.findIndex(
        (item: any) => item.ReportItemID === action.reportItemID,
      );
      const item = report.ReportItems[fromIndex];
      report.ReportItems.splice(fromIndex, 1);
      report.ReportItems.splice(action.toIndex, 0, item);
      reportsByID[action.reportID] = report;
      return { ...state, reportsByID };
    },
    removeReportItem(state, action) {
      const { reportsByID } = state;
      const report = reportsByID[action.reportID];
      report.ReportItems = report.ReportItems.filter(
        (item: any) => item.ReportItemID !== action.reportItemID,
      );
      reportsByID[action.reportID] = report;
      return { ...state, reportsByID };
    },
    addReport(state, action) {
      const { byID } = state;
      const investigation = byID[action.investigationID];
      const report = action.payload;
      investigation.InvestigationReports = [
        report,
        ...investigation.InvestigationReports,
      ];
      byID[action.payload.investigationID] = investigation;
      return { ...state, byID };
    },
    modifyReport(state, action) {
      const { byID } = state;
      const investigation = byID[action.investigationID];
      investigation.InvestigationReports =
        investigation.InvestigationReports.map((report: any) => {
          if (report.InvestigationReportID === action.reportID) {
            report = { ...report, ...action.payload };
          }
          return report;
        });
      byID[action.payload.investigationID] = investigation;
      return { ...state, byID };
    },
    removeReport(state, action) {
      const { byID } = state;
      const investigation = byID[action.investigationID];
      investigation.InvestigationReports =
        investigation.InvestigationReports.filter(
          (report: any) => report.InvestigationReportID !== action.reportID,
        );
      byID[action.investigationID] = investigation;
      return { ...state, byID };
    },
    renameReport(state, action) {
      const { byID } = state;
      const investigation = byID[action.payload.investigationID];
      investigation.InvestigationReports =
        investigation.InvestigationReports.map((report: any) => {
          if (
            report.InvestigationReportID ===
            action.payload.InvestigationReportID
          ) {
            return action.payload;
          }
          return report;
        });
      return { ...state, byID };
    },
    saveInvestigationCode(state, action) {
      return {
        ...state,
        currentInvestigationCode: action.payload.Code,
      };
    },
    saveInvestigationsData(state, action: { type; data: InvestigationData }) {
      const { investigations, events, attachments, reports, summaries } =
        _.cloneDeep(state);
      const newInvestigationData = updateInvestigationsData(
        {
          investigations,
          events,
          attachments,
          reports,
          summaries,
        },
        action.data,
      );
      const newState = {
        ...state,
        ...newInvestigationData,
      };
      if (!_.isEqual(state, newState)) {
        return newState;
      }
      return state;
    },
    updateEventMedia(state, action: { type; medias: any[] }) {
      const { events } = state;
      const { medias } = action;

      const mapChannelMedia: Record<number, any[]> = getMapChannelMedia(medias);
      Object.entries(mapChannelMedia).forEach(([chID, media_s]) => {
        if (
          +chID in events.mapChannelEvent &&
          events.mapChannelEvent[+chID].length > 0
        ) {
          events.mapChannelEvent[+chID].forEach((evntID: number) => {
            if (+evntID in events.byId) {
              events.byId[+evntID].updateMedia(media_s);
            }
          });
        }
      });

      return state;
    },
  },
};

export default InvestigationsModal;
