import { getCurrentCustomerID } from '@/utils/utils';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import { APP_ID } from './constants';

interface FleetManagerContextState {
  appId: number;
  customerId: number;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  selectedSiteFilter: number | undefined;
  setSelectedSiteFilter: (siteId: number | undefined) => void;
  handleShowInRegistry: (siteId: number | undefined) => void;
}

export const FleetManagerContext = createContext<
  FleetManagerContextState | undefined
>(undefined);

interface FleetManagerProviderProps {
  children: JSX.Element;
}

export const FleetManagerProvider = ({
  children,
}: FleetManagerProviderProps) => {
  const [activeTab, setActiveTab] = useState<string>('fleet-status');
  const [selectedSiteFilter, setSelectedSiteFilter] = useState<number>();

  const handleShowInRegistry = useCallback((siteId: number | undefined) => {
    setSelectedSiteFilter(siteId);
    setActiveTab('sites-and-labels');
  }, []);

  const customerId = useMemo(() => getCurrentCustomerID(), []);

  const value: FleetManagerContextState = {
    appId: APP_ID,
    customerId,
    activeTab,
    setActiveTab,
    selectedSiteFilter,
    setSelectedSiteFilter,
    handleShowInRegistry,
  };

  return (
    <FleetManagerContext.Provider value={value}>
      {children}
    </FleetManagerContext.Provider>
  );
};

export const useFleetManagerContext = () => {
  const context = React.useContext(FleetManagerContext);
  if (context === undefined) {
    throw new Error(
      'useFleetManagerContext must be used within a FleetManagerProvider',
    );
  }
  return context;
};
