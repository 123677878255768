import { ChannelNode } from '@/types/location';
import { dispatchWithFeedback } from '@/utils/utils';
import { Button, Form, InputNumber } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import { DFConfigKeys } from '@/dfConfigKeys';
import styles from '../style.less';

type MyProps = {
  channelID: number;
  locationID: number;
  close_setting_modal: () => void;
  accounts?: any;
  locations?: any;
  dispatch?: (_any: any) => Promise<any>;
  loadingAddConfigProfiles?: boolean;
};
type MyState = {
  cli_cld_uploadfrequency: string | undefined;
};

// @ts-expect-error
@connect(({ locations, loading }) => ({
  locations,
  loadingAddConfigProfiles: loading.effects['accounts/addConfigProfiles'],
}))
class Maps extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = { isDirty: false };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (
      prevProps.channelID !== this.props.channelID ||
      prevProps.locationID !== this.props.locationID
    ) {
      this.setup();
    }
  }

  setup() {
    const { locations, channelID, locationID } = this.props;

    let configNode;

    if (channelID) {
      configNode = _.get(locations, `ch.byId[${channelID}]`);
      if (!configNode instanceof ChannelNode) {
        return;
      }
    }
    if (locationID) {
      configNode = _.get(locations, `loc.byId[${locationID}]`);
    }

    let geo_location = _.get(configNode.ConfigProfiles, [
      DFConfigKeys.geo_location,
      'values',
    ]);
    let latitude = _.get(geo_location, 'latitude');
    let longitude = _.get(geo_location, 'longitude');

    // @ts-expect-error
    this.setState({ latitude, longitude });
    this.formRef?.current?.setFieldsValue({ latitude, longitude });
  }

  onSubmit() {
    const { locationID, channelID } = this.props;

    return this.formRef.current?.validateFields().then(
      (values) => {
        let config = {
          profile_type: 'geo_location',
          profile_name: JSON.stringify({
            latitude: values.latitude,
            longitude: values.longitude,
          }),
        };

        if (channelID) {
          config.entity_type = 'Channel';
          config.entity_id = channelID;
        } else if (locationID) {
          config.entity_type = 'Project';
          config.entity_id = locationID;
        } else {
          return null;
        }

        return dispatchWithFeedback(
          this.props.dispatch,
          'Setting values',
          {
            type: 'accounts/addConfigProfiles',
            payload: { configs: [config] },
          },
          true,
        ).then(() => this.setState({ isDirty: false }));
      },
      (err: any) => console.log('err', err),
    );
  }

  render() {
    const { loadingAddConfigProfiles } = this.props;
    const { latitude, longitude } = this.state;

    return (
      <div
        className={styles['setting-component-ctn']}
        style={{ height: this.props.locationID && 'initial' }}>
        <div
          className={`${styles['setting-component-list']} scroll-bar-slim-style`}>
          <div className={styles['setting-component']}>
            <div className={styles['component-title']}>Physical Location</div>
            <Form ref={this.formRef}>
              <div style={{ display: 'flex' }}>
                <Form.Item
                  name="latitude"
                  initialValue={latitude}
                  style={{ marginRight: '16px' }}
                  onChange={() => this.setState({ isDirty: true })}>
                  <InputNumber
                    placeholder="Latitude"
                    min={-90}
                    max={90}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  name="longitude"
                  initialValue={longitude}
                  onChange={() => this.setState({ isDirty: true })}>
                  <InputNumber
                    placeholder="Longitude"
                    min={-180}
                    max={180}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
        <div
          className={styles['setting-action-btns']}
          style={{
            height: this.props.locationID ? 'initial' : undefined,
            justifyContent: this.props.locationID ? 'initial' : undefined,
          }}>
          {this.props.close_setting_modal && (
            <Button
              className={styles['action-btn']}
              onClick={() => {
                this.props.close_setting_modal();
              }}
              style={{ margin: '0 8px' }}>
              Cancel
            </Button>
          )}
          <Button
            className={styles['action-btn']}
            type="primary"
            onClick={() => this.onSubmit()}
            loading={loadingAddConfigProfiles}
            disabled={!this.state.isDirty}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}
export default Maps;
