import InfoSidebar from '@/components/InfoSidebar';
import LoadingSpinner from '@/components/LoadingSpinner';
import type { MonitorSearchState } from '@/models/monitor_search';
import { interpretClipData } from '@/utils/utils';
import { useDispatch } from '@umijs/max';
import { Pagination, Select, Typography } from 'antd';
import _ from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RowCountMap } from '../../constants';
import type { RowCountType } from '../../types';
import { SortTypeMap } from '../SearchForm/constants';
import type { SortType } from '../SearchForm/types';
import SearchResultCard from './SearchResultCard';
import {
  NoResultsFoundContainer,
  SearchResultsContainer,
  SearchResultsContent,
  SearchResultsFooter,
  SearchResultsFooterSortCountContainer,
} from './style';
import {
  computeSearchEvents,
  computeTimelinePlayerProps,
  getClipKey,
} from './utils';

const SortOptions = [
  {
    value: SortTypeMap.CHRONOLOGICAL,
    label: <Typography.Text>Sort Chronologically</Typography.Text>,
  },
  {
    value: SortTypeMap.CONFIDENCE,
    label: <Typography.Text>Sort by Confidence</Typography.Text>,
  },
];

const RowCountOptions = [
  {
    value: RowCountMap.FIT,
    label: <Typography.Text>Display Rows to Fit</Typography.Text>,
  },
  {
    value: RowCountMap.ONE,
    label: <Typography.Text>Display 1 Row</Typography.Text>,
  },
  {
    value: RowCountMap.TWO,
    label: <Typography.Text>Display 2 Rows</Typography.Text>,
  },
  {
    value: RowCountMap.THREE,
    label: <Typography.Text>Display 3 Rows</Typography.Text>,
  },
  {
    value: RowCountMap.FIVE,
    label: <Typography.Text>Display 5 Rows</Typography.Text>,
  },
  {
    value: RowCountMap.TEN,
    label: <Typography.Text>Display 10 Rows</Typography.Text>,
  },
  {
    value: RowCountMap.FIFTY,
    label: <Typography.Text>Display 50 Rows</Typography.Text>,
  },
];

interface SearchResultsProps {
  setTimelinePlayerProps: React.Dispatch<any>;
  setRowSelectorValue: React.Dispatch<React.SetStateAction<RowCountType>>;
  rowSelectorValue: RowCountType;
  calculateResultCount: (rowCountSelectorValue: RowCountType) => number;
  selectedChannelIDs: number[];
}

const SearchResults = ({
  setTimelinePlayerProps,
  setRowSelectorValue,
  calculateResultCount,
  rowSelectorValue,
  selectedChannelIDs,
}: SearchResultsProps) => {
  const dispatch = useDispatch();
  const monitorSearchState = useSelector<any, MonitorSearchState>(
    (state) => state.monitor_search,
  );
  const monitorSearchPending = useSelector(
    // @ts-expect-error
    (state) => state.loading.effects['monitor_search/search'],
  );
  const locations = useSelector(
    // @ts-expect-error
    (state) => state.locations,
  );
  const infoSidebarRef = useRef<InfoSidebar | null>(null);

  // This is used for search queries to show merged results (all results on the page will use this so moving it out of handleClipClick)
  const search_result_events = useMemo(() => {
    return computeSearchEvents(
      monitorSearchState.searchResults.clips as any[],
      locations,
    );
  }, [monitorSearchState.searchResults.clips, locations]);
  const [selectedClip, setSelectedClip] = useState<null | object>(null);

  const handleClipClick = (clip: any) => {
    setSelectedClip(clip);
    setTimelinePlayerProps(
      computeTimelinePlayerProps(
        clip,
        monitorSearchState.searchResults.clips as any[],
        monitorSearchState.currentPayload.queryID,
        search_result_events,
        locations,
        selectedChannelIDs,
      ),
    );
    infoSidebarRef.current?.toggleInfoSidebar(true);
  };

  const handlePagination = (pageNumber: number, pageSize: number) => {
    const payload = {
      p_number: pageNumber,
      p_size: pageSize,
    };
    dispatch({
      type: 'monitor_search/paginate',
      payload,
    });
  };

  const handleSorting = (sortOption: SortType) => {
    const payload = {
      sortType: sortOption,
    };
    dispatch({
      type: 'monitor_search/sort',
      payload,
    });
  };

  const handleRowsSelectorChange = (rowCountOption: RowCountType) => {
    setRowSelectorValue(rowCountOption);
    const pageSize = calculateResultCount(rowCountOption);
    const payload = {
      p_number: 1,
      p_size: pageSize,
    };
    dispatch({
      type: 'monitor_search/paginate',
      payload,
    });
  };

  const handleSimilarSearch = (clip: any) => {
    const newMonitorSearchPayload = { ...monitorSearchState.currentPayload };
    const info = interpretClipData(clip, locations);
    if (_.get(info, 'vehicle_details')) {
      if (_.get(info.vehicle_details, 'vehicle_colors')) {
        newMonitorSearchPayload.vehicle_colors =
          info.vehicle_details.vehicle_colors;
      }
    }
    if (_.get(info, 'person_details')) {
      const person_top_color = _.get(info.person_details, 'top_colors', []);
      const person_bottom_color = _.get(
        info.person_details,
        'bottom_colors',
        [],
      );
      if (person_top_color.length > 0 || person_bottom_color.length > 0) {
        newMonitorSearchPayload.person_colors = {};

        if (person_top_color.length > 0) {
          newMonitorSearchPayload.person_colors.top_colors = person_top_color;
        }
        if (person_bottom_color.length > 0) {
          newMonitorSearchPayload.person_colors.bottom_colors =
            person_bottom_color;
        }
      }
    }
    const clip_info = _.get(info, 'clip', {});
    if (clip_info && _.get(clip_info, 'ObjectID')) {
      newMonitorSearchPayload.ObjectID = _.get(clip_info, 'ObjectID');
    }
    newMonitorSearchPayload.p_number = 1;
    dispatch({
      type: 'monitor_search/search',
      payload: newMonitorSearchPayload,
    });
    infoSidebarRef.current?.toggleInfoSidebar(false);
  };

  const renderCards = () => {
    if (monitorSearchState.searchResults.clips?.length === 0) {
      return (
        <NoResultsFoundContainer>No results found.</NoResultsFoundContainer>
      );
    }

    if (monitorSearchPending) {
      return <LoadingSpinner />; // Render the loading spinner
    }

    return (
      <>
        <SearchResultsContent>
          {(monitorSearchState.searchResults.clips as any[]).map((clip) => (
            <SearchResultCard
              key={getClipKey(clip)}
              clip={clip}
              selected={getClipKey(clip) === getClipKey(selectedClip)}
              onClick={handleClipClick}
            />
          ))}
        </SearchResultsContent>
        <SearchResultsFooter>
          <Pagination
            total={
              monitorSearchState.searchResults.total_pages *
              monitorSearchState.currentPayload.p_size
            }
            showSizeChanger={false}
            pageSize={monitorSearchState.currentPayload.p_size}
            onChange={handlePagination}
            current={monitorSearchState.searchResults.p_number}
          />
          <SearchResultsFooterSortCountContainer>
            <Select
              options={RowCountOptions}
              onChange={handleRowsSelectorChange}
              value={rowSelectorValue}
              style={{ width: '160px' }}
            />
            <Select
              options={SortOptions}
              onChange={handleSorting}
              defaultValue={SortTypeMap.CHRONOLOGICAL}
            />
          </SearchResultsFooterSortCountContainer>
        </SearchResultsFooter>
      </>
    );
  };

  return (
    <>
      {selectedClip && (
        <InfoSidebar
          clip={selectedClip}
          searchResults={[selectedClip]}
          ref={infoSidebarRef}
          objectSearch={handleSimilarSearch}
          linkToCamera={false}
        />
      )}
      <SearchResultsContainer
        $show_results={monitorSearchState.searchResults.clips !== null}>
        {renderCards()}
      </SearchResultsContainer>
    </>
  );
};

export default SearchResults;
