/**
 * @generated SignedSource<<efd8f7dd4daa016371cda5cd6f357286>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type EventLog_sites$data = {
  readonly edges: ReadonlyArray<
    | {
        readonly node:
          | {
              readonly Name: string | null | undefined;
              readonly SiteID: number;
              readonly id: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
  readonly ' $fragmentType': 'EventLog_sites';
};
export type EventLog_sites$key = {
  readonly ' $data'?: EventLog_sites$data;
  readonly ' $fragmentSpreads': FragmentRefs<'EventLog_sites'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'EventLog_sites',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'SiteEdge',
      kind: 'LinkedField',
      name: 'edges',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'Site',
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'id',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'SiteID',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'Name',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      storageKey: null,
    },
  ],
  type: 'SiteConnection',
  abstractKey: null,
};

(node as any).hash = 'db373a01e6b4be874241189d5c2dc814';

export default node;
