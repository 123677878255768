import { ALL_APPS } from '@/pages/apps/app/constants/appList';
import { isInternalUser } from '@/utils/utils';
import { Tooltip } from 'antd';
import React from 'react';
import { EventType, EVENT_TYPE_LABEL_MAPPING } from '../constants';
import './styles.less';

interface EntityNames {
  sites: Record<number, string>;
  labels: Record<number, string>;
  users: Record<number, string>;
}

export const getEventTypeFilters = (currentUser: any) => {
  let keysToRemove: EventType[] = [];

  if (!isInternalUser(currentUser)) {
    keysToRemove = [
      EventType.ENTITY_CONFIG_MODIFIED,
      EventType.ENTITY_CONFIG_REMOVED,
      EventType.APP_ADDED,
      EventType.APP_UPDATED,
      EventType.APP_DELETED,
    ];
  }

  return Object.values(EventType)
    .filter((key) => !keysToRemove.includes(key))
    .map((key) => ({
      text: EVENT_TYPE_LABEL_MAPPING[key],
      value: key,
    }));
};

export const formatEventLogMessage = (
  eventType: EventType,
  data: any,
  entityNames: EntityNames,
): React.ReactNode => {
  switch (eventType) {
    // Site related events
    case EventType.CREATE_SITE:
      return (
        <>Created site {entityNames.sites[data.SiteID] || 'Unknown Site'}</>
      );
    case EventType.UPDATE_SITE:
      return (
        <>Updated site {entityNames.sites[data.site_id] || 'Unknown Site'}</>
      );
    case EventType.DELETE_SITE:
      return <>Deleted site {data.deletedSiteName}</>;

    // Label related events
    case EventType.CREATE_LABEL:
      return (
        <>
          Created site group{' '}
          {entityNames.labels[data.LabelID] || 'Unknown Site Group'}
        </>
      );
    case EventType.UPDATE_LABEL:
      return (
        <>
          Updated site group{' '}
          {entityNames.labels[data.id] || 'Unknown Site Group'}
        </>
      );
    case EventType.DELETE_LABEL:
      return <>Deleted site group {data.deletedLabelName}</>;

    // User related events
    case EventType.INVITE_USER:
      return <>Invited user {data.email}</>;
    case EventType.REMOVE_USER:
      return data.email ? (
        <>Removed user {data.email}</>
      ) : (
        <>Removed user {entityNames.users[data.userID] || 'Unknown User'}</>
      );
    case EventType.DELETE_INVITE:
      return <>Deleted invitation for {data.email}</>;

    // Access rules events
    case EventType.CREATE_ACCESS_RULES:
      return (
        <>
          Created access rule for{' '}
          {entityNames.users[data.UserID] || 'Unknown User'}
        </>
      );
    case EventType.UPDATE_ACCESS_RULES:
      return (
        <>
          Updated access rule for{' '}
          {entityNames.users[data.UserID] || 'Unknown User'}
        </>
      );
    case EventType.DELETE_ACCESS_RULES:
      return (
        <>
          Deleted access rule for{' '}
          {entityNames.users[data.UserID] || 'Unknown User'}
        </>
      );
    case EventType.UPDATE_SITE_STATUS:
      return (
        <>
          Updated site status for{' '}
          {entityNames.sites[data.site_id] || 'Unknown Site'}
        </>
      );

    // Config and license events
    case EventType.ENTITY_CONFIG_MODIFIED:
      const formatProfileName = (profile: string) => {
        try {
          return JSON.stringify(JSON.parse(profile), null, 2);
        } catch {
          return profile; // Return as-is if not valid JSON
        }
      };

      return (
        <>
          Modified config "{data.profile_type || 'Unknown Profile Type'}" for{' '}
          {data.entity_type || 'Unknown Entity Type'}:{' '}
          {data.entity_id || 'Unknown Entity ID'}
          <Tooltip
            title={
              <pre className="scrollable-tooltip">
                {formatProfileName(data.profile_name)}
              </pre>
            }
            overlayClassName="tooltip-overlay">
            <span className="df-link">&nbsp;Details</span>
          </Tooltip>
        </>
      );
    case EventType.ENTITY_CONFIG_REMOVED:
      return (
        <>
          Removed config {data.profile_type || 'Unknown Profile Type'} for{' '}
          {data.entity_type || 'Unknown Entity Type'}:{' '}
          {data.entity_id || 'Unknown Entity ID'}
        </>
      );
    case EventType.APP_ADDED:
      return (
        <>Added app {ALL_APPS[String(data.AppID)]?.appName || 'Unknown App'}</>
      );
    case EventType.APP_UPDATED:
      return (
        <>
          Updated app {ALL_APPS[String(data.AppID)]?.appName || 'Unknown App'}
        </>
      );
    case EventType.APP_DELETED:
      return (
        <>
          Deleted app {ALL_APPS[String(data.AppID)]?.appName || 'Unknown App'}
        </>
      );
    default:
      return <span>-</span>;
  }
};
