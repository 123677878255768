/**
 * @generated SignedSource<<7dd5cba573ed17312f91647fa4f44917>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateUpdateFleetManagerEngagementPlanInput = {
  appId: number;
  clientMutationId?: string | null | undefined;
  customerId: number;
  engagementPlan: string;
};
export type CreateUpdateEngagementPlanMutation$variables = {
  input: CreateUpdateFleetManagerEngagementPlanInput;
};
export type CreateUpdateEngagementPlanMutation$data = {
  readonly createUpdateFleetManagerEngagementPlan: {
    readonly fleetManagerEngagementPlan: {
      readonly " $fragmentSpreads": FragmentRefs<"EngagementPlanFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type CreateUpdateEngagementPlanMutation = {
  response: CreateUpdateEngagementPlanMutation$data;
  variables: CreateUpdateEngagementPlanMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateUpdateEngagementPlanMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUpdateFleetManagerEngagementPlanPayload",
        "kind": "LinkedField",
        "name": "createUpdateFleetManagerEngagementPlan",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FleetManagerEngagementPlan",
            "kind": "LinkedField",
            "name": "fleetManagerEngagementPlan",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EngagementPlanFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateUpdateEngagementPlanMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUpdateFleetManagerEngagementPlanPayload",
        "kind": "LinkedField",
        "name": "createUpdateFleetManagerEngagementPlan",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FleetManagerEngagementPlan",
            "kind": "LinkedField",
            "name": "fleetManagerEngagementPlan",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "967fd54e21974fb95b6bd752ecbc1eb3",
    "id": null,
    "metadata": {},
    "name": "CreateUpdateEngagementPlanMutation",
    "operationKind": "mutation",
    "text": "mutation CreateUpdateEngagementPlanMutation(\n  $input: CreateUpdateFleetManagerEngagementPlanInput!\n) {\n  createUpdateFleetManagerEngagementPlan(input: $input) {\n    fleetManagerEngagementPlan {\n      ...EngagementPlanFragment\n      id\n    }\n  }\n}\n\nfragment EngagementPlanFragment on FleetManagerEngagementPlan {\n  id\n  content\n}\n"
  }
};
})();

(node as any).hash = "e53ea78a85f24a7d1dfd873069876575";

export default node;
