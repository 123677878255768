/**
 * @generated SignedSource<<5ab998c05eece0abead6b71c7f6e1132>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ContextualComboboxQuery$variables = {
  customerId: number;
};
export type ContextualComboboxQuery$data = {
  readonly contextOptions:
    | ReadonlyArray<
        | {
            readonly context: string;
            readonly options: ReadonlyArray<
              | {
                  readonly OptionID: number;
                  readonly OptionText: string;
                }
              | null
              | undefined
            >;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};
export type ContextualComboboxQuery = {
  response: ContextualComboboxQuery$data;
  variables: ContextualComboboxQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'customerId',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'customerId',
        variableName: 'customerId',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'context',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'OptionID',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'OptionText',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'ContextualComboboxQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'ContextOptions',
          kind: 'LinkedField',
          name: 'contextOptions',
          plural: true,
          selections: [
            {
              kind: 'RequiredField',
              field: v2 /*: any*/,
              action: 'LOG',
              path: 'contextOptions.context',
            },
            {
              kind: 'RequiredField',
              field: {
                alias: null,
                args: null,
                concreteType: 'Option',
                kind: 'LinkedField',
                name: 'options',
                plural: true,
                selections: [
                  {
                    kind: 'RequiredField',
                    field: v3 /*: any*/,
                    action: 'LOG',
                    path: 'contextOptions.options.OptionID',
                  },
                  {
                    kind: 'RequiredField',
                    field: v4 /*: any*/,
                    action: 'LOG',
                    path: 'contextOptions.options.OptionText',
                  },
                ],
                storageKey: null,
              },
              action: 'LOG',
              path: 'contextOptions.options',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'ContextualComboboxQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'ContextOptions',
          kind: 'LinkedField',
          name: 'contextOptions',
          plural: true,
          selections: [
            v2 /*: any*/,
            {
              alias: null,
              args: null,
              concreteType: 'Option',
              kind: 'LinkedField',
              name: 'options',
              plural: true,
              selections: [v3 /*: any*/, v4 /*: any*/],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '458ea6d826150f32ee5f8bcca0e014c7',
      id: null,
      metadata: {},
      name: 'ContextualComboboxQuery',
      operationKind: 'query',
      text: 'query ContextualComboboxQuery(\n  $customerId: Int!\n) {\n  contextOptions(customerId: $customerId) {\n    context\n    options {\n      OptionID\n      OptionText\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '126935b84bf0f69738a8d365483cfde9';

export default node;
