import LocationsIcon from '@/assets/location-plain';
import Icon from '@ant-design/icons';
import { Checkbox, Typography } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import ChannelTileAndName from './ChannelTileAndName';
import {
  ChannelGroupContainer,
  ChannelGroupHeaderContainer,
  ChannelGroupListContainer,
} from './styles';
import type { ChannelTileAndName_Channel$key } from './__generated__/ChannelTileAndName_Channel.graphql';
import type { SiteChannelsListFragment$key } from './__generated__/SiteChannelsListFragment.graphql';

export const SiteChannelsListFragment = graphql`
  fragment SiteChannelsListFragment on Site {
    Name
    SiteID
    Channels(sort: [NAME_ASC]) {
      edges {
        node {
          Name
          ChannelID
          MonitorStatus
          ...ChannelTileAndName_Channel
        }
      }
    }
  }
`;

interface SiteChannelsListProps {
  siteNode: SiteChannelsListFragment$key;
  allSelectedChannels: number[];
  addChannel: (
    channelID: number | number[],
    channelTileRef:
      | ChannelTileAndName_Channel$key
      | ChannelTileAndName_Channel$key[],
  ) => void;
  removeChannel: (channelID: number | number[]) => void;
  sidebarSearch?: string;
  showInactiveChannels: boolean;
}

const SiteChannelsList = ({
  siteNode,
  allSelectedChannels,
  addChannel,
  removeChannel,
  sidebarSearch,
  showInactiveChannels,
}: SiteChannelsListProps) => {
  const siteChannelsData = useFragment<SiteChannelsListFragment$key>(
    SiteChannelsListFragment,
    siteNode,
  );
  const channelEdges = siteChannelsData.Channels?.edges;
  const siteName = siteChannelsData.Name;
  const channelsToShow = (channelEdges ?? [])
    ?.filter((channel) => {
      if (!channel?.node?.ChannelID) return false; // Handle null or undefined channel and channel.node together
      const channelNode = channel.node;

      if (
        sidebarSearch &&
        !_.includes(channelNode?.Name?.toLowerCase(), sidebarSearch)
      ) {
        return false;
      }

      if (!showInactiveChannels && channelNode?.MonitorStatus === 'inactive') {
        return false;
      }

      return true;
    })
    .map((channel) => channel!.node);

  const [allSelected, setAllSelected] = useState(false);

  // Effect to calculate `allSelected` based on `selectedChannels`
  useEffect(() => {
    const allChannelIDs = channelsToShow
      .map((channel) => Number(channel?.ChannelID))
      .filter((id) => !isNaN(id));
    const areAllSelected = allChannelIDs.every((channelID) =>
      allSelectedChannels?.includes(channelID),
    );
    setAllSelected(areAllSelected);
  }, [allSelectedChannels, channelsToShow]);

  const handleSelectAll = () => {
    const channelIDs = channelsToShow.map((channel) =>
      Number(channel?.ChannelID),
    );

    if (allSelected) {
      removeChannel(channelIDs);
    } else {
      const channels = channelsToShow.filter(
        (channel) => !allSelectedChannels?.includes(Number(channel?.ChannelID)),
      );
      const channelIds = channels.map((channel) => Number(channel?.ChannelID));
      addChannel(channelIds, channels as ChannelTileAndName_Channel$key[]);
    }
  };

  return (
    <ChannelGroupContainer>
      <ChannelGroupHeaderContainer>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Icon component={LocationsIcon} style={{ fontSize: 16 }} />
          <Typography style={{ fontWeight: 500 }}>{siteName}</Typography>
        </div>
        <Checkbox checked={allSelected} onChange={handleSelectAll} />
      </ChannelGroupHeaderContainer>
      <ChannelGroupListContainer>
        {channelsToShow?.map((channel) => (
          <ChannelTileAndName
            tileFragmentRef={channel as ChannelTileAndName_Channel$key}
            key={channel?.ChannelID}
            siteName={siteName as string}
            isSelected={
              allSelected ||
              allSelectedChannels?.includes(Number(channel?.ChannelID))
            }
            onClick={() => {
              if (allSelectedChannels?.includes(Number(channel?.ChannelID))) {
                removeChannel(Number(channel?.ChannelID));
              } else {
                addChannel(
                  Number(channel?.ChannelID),
                  channel as ChannelTileAndName_Channel$key,
                );
              }
            }}
          />
        ))}
      </ChannelGroupListContainer>
    </ChannelGroupContainer>
  );
};

export { SiteChannelsList };
