/**
 * @generated SignedSource<<0012f3aff949c86b1df4c6cef67a1ba9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type TileThumbnailChannelsMedia$data = ReadonlyArray<{
  readonly LatestMedia: string | null | undefined;
  readonly ' $fragmentType': 'TileThumbnailChannelsMedia';
}>;
export type TileThumbnailChannelsMedia$key = ReadonlyArray<{
  readonly ' $data'?: TileThumbnailChannelsMedia$data;
  readonly ' $fragmentSpreads': FragmentRefs<'TileThumbnailChannelsMedia'>;
}>;

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: {
    plural: true,
  },
  name: 'TileThumbnailChannelsMedia',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'LatestMedia',
      storageKey: null,
    },
  ],
  type: 'Channel',
  abstractKey: null,
};

(node as any).hash = '2534d9a049c7b21078e9b2910a86efb0';

export default node;
