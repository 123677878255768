export const TileLayout = {
  FIT: 'fit',
  ONE_COL: 1,
  TWO_COL: 2,
  THREE_COL: 3,
  FIVE_COL: 5,
  TEN_COL: 10,
} as const;

export const TileLayoutLabels = {
  [TileLayout.FIT]: 'Display Columns to Fit',
  [TileLayout.ONE_COL]: 'Display 1 Column',
  [TileLayout.TWO_COL]: 'Display 2 Columns',
  [TileLayout.THREE_COL]: 'Display 3 Columns',
  [TileLayout.FIVE_COL]: 'Display 5 Columns',
  [TileLayout.TEN_COL]: 'Display 10 Columns',
} as const;
