import type { SelectorStoreUpdater } from 'node_modules/@types/relay-runtime/lib/store/RelayStoreTypes';
import {
  commitLocalUpdate,
  ConnectionHandler,
  useRelayEnvironment,
} from 'react-relay';
import type { MyScenesPaginationQuery$variables } from './__generated__/MyScenesPaginationQuery.graphql';

const useMyScenesConnectionInfo = (appId: number, customerId: number) => {
  const environment = useRelayEnvironment();

  const invalidateMyScenes: SelectorStoreUpdater = (store) => {
    const root = store.getRoot();
    const userScenesData = root
      .getLinkedRecord('monitorUserScenes', {
        appId,
        customerId,
      })
      ?.getLinkedRecord('scenesData');
    userScenesData?.invalidateRecord();
  };

  const getMyScenesConnectionId = (
    variables: Partial<MyScenesPaginationQuery$variables>,
  ) => {
    let connectionId: string | null = null;
    commitLocalUpdate(environment, (store) => {
      const root = store.getRoot();
      const myScenesData = root
        .getLinkedRecord('monitorUserScenes', {
          appId,
          customerId,
        })
        ?.getLinkedRecord('scenesData');
      if (myScenesData) {
        connectionId = ConnectionHandler.getConnectionID(
          myScenesData.getDataID(),
          'MyScenesConnection_scenes',
          variables,
        );
      }
    });
    return connectionId as string | null;
  };

  return {
    invalidateMyScenes,
    getMyScenesConnectionId,
  };
};

export default useMyScenesConnectionInfo;
