/**
 * @generated SignedSource<<c8881d7a56cdfd0bb5dc0deccf3fd73e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type activityLog_SmokeAndFireAlarmsQuery$variables = {
  app_id: number;
  customer_id: number;
};
export type activityLog_SmokeAndFireAlarmsQuery$data = {
  readonly smokeAndFireAlarmRules:
    | {
        readonly edges: ReadonlyArray<
          | {
              readonly node:
                | {
                    readonly AlarmID: number;
                    readonly Name: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};
export type activityLog_SmokeAndFireAlarmsQuery = {
  response: activityLog_SmokeAndFireAlarmsQuery$data;
  variables: activityLog_SmokeAndFireAlarmsQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'app_id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'customer_id',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'appId',
        variableName: 'app_id',
      },
      {
        kind: 'Variable',
        name: 'customerId',
        variableName: 'customer_id',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'AlarmID',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'Name',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'activityLog_SmokeAndFireAlarmsQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'SmokeAndFireAlarmRulesConnection',
          kind: 'LinkedField',
          name: 'smokeAndFireAlarmRules',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'SmokeAndFireAlarmRulesEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'SmokeAndFireAlarmRules',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [v2 /*: any*/, v3 /*: any*/],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'activityLog_SmokeAndFireAlarmsQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'SmokeAndFireAlarmRulesConnection',
          kind: 'LinkedField',
          name: 'smokeAndFireAlarmRules',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'SmokeAndFireAlarmRulesEdge',
              kind: 'LinkedField',
              name: 'edges',
              plural: true,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'SmokeAndFireAlarmRules',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    v3 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'id',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '4144d7d3f3bb2acd253f246aaa1b6124',
      id: null,
      metadata: {},
      name: 'activityLog_SmokeAndFireAlarmsQuery',
      operationKind: 'query',
      text: 'query activityLog_SmokeAndFireAlarmsQuery(\n  $app_id: Int!\n  $customer_id: Int!\n) {\n  smokeAndFireAlarmRules(appId: $app_id, customerId: $customer_id) {\n    edges {\n      node {\n        AlarmID\n        Name\n        id\n      }\n    }\n  }\n}\n',
    },
  };
})();

(node as any).hash = '5c134ddc0029cb7b0d39f36b83142924';

export default node;
