import PageHeader from '@/components/PageHeader2';
import { Tabs } from 'antd';
import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'umi';
import { APP_ID, CAPABILITIES, CAPABILITIES_LABEL_MAP } from './constants';
import LiveView from './LiveView';
import Transactions from './transactions';
import {
  FilterParams,
  generateInputPayloadForFetchingTransactions,
  PaginationParams,
  SortingParams,
} from './transactions/utils';
import { ModelState } from './types';

const CheckoutInsightsApp = () => {
  const appConfig = useSelector((state: any) => state.apps.byID[APP_ID]);
  const dispatch = useDispatch();
  const capabilities = _.get(appConfig, 'capabilities', []);
  let tabsToShow = CAPABILITIES;
  if (capabilities.indexOf('*') == -1) {
    tabsToShow = tabsToShow.filter((tab) => capabilities.indexOf(tab) !== -1);
  }

  const transactions: ModelState['transactions'] = useSelector(
    (state: any) => state['checkout_insights'].transactions,
  );
  const fetchTransactions = (
    paginationConfig: PaginationParams,
    filterConfig: FilterParams,
    sortingConfig: SortingParams,
  ) => {
    dispatch({
      type: 'checkout_insights/fetchTransactions',
      payload: generateInputPayloadForFetchingTransactions(
        paginationConfig,
        filterConfig,
        sortingConfig,
      ),
    });
  };
  const fetchTransactionFilters = () => {
    dispatch({
      type: 'checkout_insights/fetchTransactionFilters',
    });
  };

  const incidents: ModelState['transactions'] = useSelector(
    (state: any) => state['checkout_insights'].incidents,
  );
  const fetchIncidents = (
    paginationConfig: PaginationParams,
    filterConfig: FilterParams,
    sortingConfig: SortingParams,
  ) => {
    dispatch({
      type: 'checkout_insights/fetchIncidents',
      payload: generateInputPayloadForFetchingTransactions(
        paginationConfig,
        filterConfig,
        sortingConfig,
      ),
    });
  };
  const fetchIncidentFilters = () => {
    dispatch({
      type: 'checkout_insights/fetchIncidentFilters',
    });
  };

  const users = useSelector((state) => state.accounts.users);
  const fetchUsers = () => {
    dispatch({ type: 'accounts/fetchUsers' });
  };
  useEffect(() => {
    if (!users.length) {
      fetchUsers();
    }
  }, [users]);

  return (
    <div>
      <PageHeader title={appConfig.Name || 'Checkout Insights'} />
      <div style={{ paddingRight: 16 }}>
        <Tabs>
          {tabsToShow.includes('transactions') ? (
            <Tabs.TabPane
              key="transactions"
              tab={CAPABILITIES_LABEL_MAP['transactions']}>
              <div style={{ marginBottom: '16px' }}>
                <Transactions
                  transactions={transactions}
                  fetchTransactions={fetchTransactions}
                  fetchTransactionFilters={fetchTransactionFilters}
                />
              </div>
            </Tabs.TabPane>
          ) : null}
          {tabsToShow.includes('incidents') ? (
            <Tabs.TabPane
              key="incidents"
              tab={CAPABILITIES_LABEL_MAP['incidents']}>
              <div style={{ marginBottom: '16px' }}>
                <Transactions
                  transactions={incidents}
                  fetchTransactions={fetchIncidents}
                  fetchTransactionFilters={fetchIncidentFilters}
                  isIncident={true}
                />
              </div>
            </Tabs.TabPane>
          ) : null}
          {tabsToShow.includes('live-view') ? (
            <Tabs.TabPane
              key="live-view"
              tab={CAPABILITIES_LABEL_MAP['live-view']}>
              <LiveView />
            </Tabs.TabPane>
          ) : null}
        </Tabs>
      </div>
    </div>
  );
};

CheckoutInsightsApp.CAPABILITIES = CAPABILITIES;
CheckoutInsightsApp.CAPABILITIES_LABEL_MAP = CAPABILITIES_LABEL_MAP;

export default CheckoutInsightsApp;
