/**
 * @generated SignedSource<<919134b6f03f3b59d787f283afd73b15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type ChannelSelectCascaderFragment_ChannelsBySiteGroups$data = {
  readonly edges: ReadonlyArray<
    | {
        readonly node:
          | {
              readonly LabelID: number;
              readonly Name: string | null | undefined;
              readonly Sites:
                | {
                    readonly edges: ReadonlyArray<
                      | {
                          readonly node:
                            | {
                                readonly Channels:
                                  | {
                                      readonly edges: ReadonlyArray<
                                        | {
                                            readonly node:
                                              | {
                                                  readonly ChannelID: number;
                                                  readonly Name:
                                                    | string
                                                    | null
                                                    | undefined;
                                                }
                                              | null
                                              | undefined;
                                          }
                                        | null
                                        | undefined
                                      >;
                                    }
                                  | null
                                  | undefined;
                                readonly Name: string | null | undefined;
                                readonly SiteID: number;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined
                    >;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
  readonly ' $fragmentType': 'ChannelSelectCascaderFragment_ChannelsBySiteGroups';
};
export type ChannelSelectCascaderFragment_ChannelsBySiteGroups$key = {
  readonly ' $data'?: ChannelSelectCascaderFragment_ChannelsBySiteGroups$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ChannelSelectCascaderFragment_ChannelsBySiteGroups'>;
};

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'Name',
      storageKey: null,
    },
    v1 = [
      {
        kind: 'Literal',
        name: 'sort',
        value: ['NAME_ASC'],
      },
    ];
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'ChannelSelectCascaderFragment_ChannelsBySiteGroups',
    selections: [
      {
        alias: null,
        args: null,
        concreteType: 'LabelEdge',
        kind: 'LinkedField',
        name: 'edges',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'Label',
            kind: 'LinkedField',
            name: 'node',
            plural: false,
            selections: [
              v0 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'LabelID',
                storageKey: null,
              },
              {
                alias: null,
                args: v1 /*: any*/,
                concreteType: 'SiteConnection',
                kind: 'LinkedField',
                name: 'Sites',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    concreteType: 'SiteEdge',
                    kind: 'LinkedField',
                    name: 'edges',
                    plural: true,
                    selections: [
                      {
                        alias: null,
                        args: null,
                        concreteType: 'Site',
                        kind: 'LinkedField',
                        name: 'node',
                        plural: false,
                        selections: [
                          v0 /*: any*/,
                          {
                            alias: null,
                            args: null,
                            kind: 'ScalarField',
                            name: 'SiteID',
                            storageKey: null,
                          },
                          {
                            alias: null,
                            args: v1 /*: any*/,
                            concreteType: 'ChannelConnection',
                            kind: 'LinkedField',
                            name: 'Channels',
                            plural: false,
                            selections: [
                              {
                                alias: null,
                                args: null,
                                concreteType: 'ChannelEdge',
                                kind: 'LinkedField',
                                name: 'edges',
                                plural: true,
                                selections: [
                                  {
                                    alias: null,
                                    args: null,
                                    concreteType: 'Channel',
                                    kind: 'LinkedField',
                                    name: 'node',
                                    plural: false,
                                    selections: [
                                      {
                                        alias: null,
                                        args: null,
                                        kind: 'ScalarField',
                                        name: 'ChannelID',
                                        storageKey: null,
                                      },
                                      v0 /*: any*/,
                                    ],
                                    storageKey: null,
                                  },
                                ],
                                storageKey: null,
                              },
                            ],
                            storageKey: 'Channels(sort:["NAME_ASC"])',
                          },
                        ],
                        storageKey: null,
                      },
                    ],
                    storageKey: null,
                  },
                ],
                storageKey: 'Sites(sort:["NAME_ASC"])',
              },
            ],
            storageKey: null,
          },
        ],
        storageKey: null,
      },
    ],
    type: 'LabelConnection',
    abstractKey: null,
  };
})();

(node as any).hash = '977590cd5781a662a3354990fbbdbf87';

export default node;
