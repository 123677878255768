import DataList from '@/components/DataList';
import DateRangeFilter from '@/components/DataList/DateRangeFilter';
import { getFlexibleDateFormat } from '@/utils/utils';
import { Button } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useDispatch, useSelector } from 'umi';
import { EventType, EVENT_TYPE_LABEL_MAPPING } from '../constants';
import { formatEventLogMessage, getEventTypeFilters } from './utils';
import type { EventLog_labels$key } from './__generated__/EventLog_labels.graphql';
import type { EventLog_sites$key } from './__generated__/EventLog_sites.graphql';

const DEFAULT_PAGE_SIZE = 25;

export const SitesEventLogFragment = graphql`
  fragment EventLog_sites on SiteConnection {
    edges {
      node {
        id
        SiteID
        Name
      }
    }
  }
`;

export const LabelsEventLogFragment = graphql`
  fragment EventLog_labels on LabelConnection {
    edges {
      node {
        id
        LabelID
        Name
      }
    }
  }
`;

interface Filters {
  p_size: number;
  p_number: number;
  type?: EventType[];
  user_id?: string[];
  timestamp?: [moment.Moment, moment.Moment];
}

interface EventLogProps {
  sitesRef: EventLog_sites$key;
  labelsRef: EventLog_labels$key;
}

const EventLog: React.FC<EventLogProps> = ({ sitesRef, labelsRef }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<Filters>({
    p_size: DEFAULT_PAGE_SIZE,
    p_number: 1,
  });

  const timezone = moment.tz.guess();

  // Get data from Redux store
  const events = useSelector((state: any) => state.fleet_manager.log);
  const users = useSelector((state: any) => state.accounts.users);
  const isLoading = useSelector(
    ({ loading }: any) => loading.effects['fleet_manager/fetchEventLog'],
  );

  const currentUser = useSelector((state: any) => state.user.currentUser);

  const fetchLog = (params = {}) => {
    dispatch({
      type: 'fleet_manager/fetchEventLog',
      payload: params,
    });
  };

  // Fetch users and initial event log on mount
  useEffect(() => {
    dispatch({
      type: 'accounts/fetchUsers',
      payload: {},
    });
    fetchLog(filters);
  }, []);

  // Create mapping of user IDs to full names
  const userNameById: Record<string, string> = useMemo(() => {
    const byId: Record<string, string> = {};
    if (Array.isArray(users)) {
      users.forEach((obj) => {
        if (obj.User) {
          byId[
            String(obj.User.UserID)
          ] = `${obj.User.FirstName} ${obj.User.LastName}`;
        }
      });
    }
    return byId;
  }, [users]);

  const sites = useFragment(SitesEventLogFragment, sitesRef);
  const labels = useFragment(LabelsEventLogFragment, labelsRef);

  // Transform the Relay data into entityNames
  const entityNames = useMemo(
    () => ({
      sites:
        sites?.edges?.reduce((acc: Record<number, string>, edge: any) => {
          if (edge?.node) {
            acc[edge.node.SiteID] = edge.node.Name;
          }
          return acc;
        }, {}) || {},
      labels:
        labels?.edges?.reduce((acc: Record<number, string>, edge: any) => {
          if (edge?.node) {
            acc[edge.node.LabelID] = edge.node.Name;
          }
          return acc;
        }, {}) || {},
      users: userNameById,
    }),
    [sites, labels, userNameById],
  );

  const columns = [
    {
      title: 'Time',
      dataIndex: 'timestamp',
      render: (t: number) =>
        getFlexibleDateFormat(moment.unix(t).tz(timezone), false, false, true),
      renderForExport: (t: number) =>
        moment.unix(t).tz(timezone).format('YYYY-MM-DD HH:mm z'),
      key: 'timestamp',
      filterDropdown: (props: any) => <DateRangeFilter {...props} />,
    },
    {
      title: 'Event',
      dataIndex: 'type',
      key: 'type',
      render: (type: EventType) => EVENT_TYPE_LABEL_MAPPING[type] || type,
      filters: getEventTypeFilters(currentUser),
      filteredValue: filters.type || [],
    },
    {
      title: 'User',
      dataIndex: 'user_id',
      key: 'user_id',
      render: (userId: string) => userNameById[userId] || 'Unknown User',
      filters: Array.isArray(users)
        ? users.map((user) => ({
            text: `${user.User.FirstName} ${user.User.LastName}`,
            value: String(user.User.UserID),
          }))
        : [],
      filteredValue: filters.user_id || [],
    },
    {
      title: 'Details',
      dataIndex: 'data',
      key: 'details',
      render: (data: any, record: any) =>
        formatEventLogMessage(record.type, data, entityNames),
    },
  ];

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            marginBottom: '16px',
          }}>
          <Button onClick={() => fetchLog(filters)}>Refresh</Button>
        </div>
        <DataList
          isLoading={isLoading}
          columns={columns}
          dataList={_.get(events, 'list', [])}
          onChange={(pagination = {}, newFilters = {}) => {
            const updatedFilters = { ...filters, ...newFilters, ...pagination };
            setFilters(updatedFilters);
            fetchLog(updatedFilters);
          }}
          pagination={{
            p_number: filters.p_number,
            p_size: filters.p_size,
            total_pages: _.get(events, 'total_pages', 1),
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: [25, 50, 100, 250],
          }}
          isControlled={true}
          exportedFilename="event-log"
        />
      </div>
    </div>
  );
};

export default EventLog;
