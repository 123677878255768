import type { Model } from 'dva';
import getAlarmBaseModel, { AlarmState } from '../../model_generator';

const CameraMonitoringAlarmModel: Model & { state: AlarmState } = {
  namespace: 'camera_monitoring_alarm',
  state: {},
  ...getAlarmBaseModel('camera_monitoring_alarm', 119),
};

export default CameraMonitoringAlarmModel;
