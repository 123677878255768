export const STREAM_QUALITY = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  DEFAULT: 'default',
} as const;

export const STREAM_QUALITY_LABELS = {
  [STREAM_QUALITY.LOW]: 'Low Resolution',
  [STREAM_QUALITY.MEDIUM]: 'Medium Resolution',
  [STREAM_QUALITY.HIGH]: 'High Resolution',
  [STREAM_QUALITY.DEFAULT]: 'Default Resolution',
} as const;
