import Combobox from '@/components/Combobox';
import { SiteSelectionCascader } from '@/components/SiteSelectionCascader';
import type { Site } from '@/components/SiteSelectionCascader/types';
import { ColorPicker, Form, Input, Modal } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useFleetManagerContext } from './FleetManagerContext';
import { useCreateLabelMutation } from './mutations/CreateLabelMutation';
import { useUpdateLabelMutation } from './mutations/UpdateLabelMutation';
import type { CreateUpdateLabel_label$key } from './__generated__/CreateUpdateLabel_label.graphql';
import type { CreateUpdateLabel_labels$key } from './__generated__/CreateUpdateLabel_labels.graphql';
import type { CreateUpdateLabel_sites$key } from './__generated__/CreateUpdateLabel_sites.graphql';

type CreateUpdateLabelProps = {
  labelRef?: CreateUpdateLabel_label$key;
  labelConnectionRef: CreateUpdateLabel_labels$key;
  siteConnectionRef: CreateUpdateLabel_sites$key;
  children?: React.ReactNode;
};

const labelFragment = graphql`
  fragment CreateUpdateLabel_label on Label {
    ...labels_label @relay(mask: false)
  }
`;

const labelConnectionFragment = graphql`
  fragment CreateUpdateLabel_labels on LabelConnection {
    __id
    edges {
      node {
        ...labels_label @relay(mask: false)
      }
    }
  }
`;

const siteConnectionFragment = graphql`
  fragment CreateUpdateLabel_sites on SiteConnection {
    __id
    edges {
      node {
        Name
        SiteID
      }
    }
  }
`;

const CreateUpdateLabel: React.FC<CreateUpdateLabelProps> = ({
  labelRef,
  labelConnectionRef,
  siteConnectionRef,
  children,
}) => {
  const label = useFragment(labelFragment, labelRef);
  const labels = useFragment(labelConnectionFragment, labelConnectionRef);
  const sites = useFragment(siteConnectionFragment, siteConnectionRef);
  const [form] = Form.useForm();
  const { customerId } = useFleetManagerContext();
  const [isCreating, createLabel] = useCreateLabelMutation(
    customerId,
    labels?.__id,
  );
  const [isUpdating, updateLabel] = useUpdateLabelMutation(customerId);
  const [isModalOpen, updateIsModalOpen] = useState(false);
  const isLoading = isCreating || isUpdating;

  // Extract unique label types for dropdown options
  const uniqueLabelTypes = useMemo(() => {
    const types = labels?.edges.map((edge) => edge?.node?.LabelType) || [];
    return Array.from(new Set(types))
      .filter(Boolean)
      .map((value) => {
        return { label: value, value: value };
      });
  }, [labels]);

  const initialValues = {
    name: label?.Name || '',
    type: label?.LabelType || '',
    color: label?.Color || '#000', // Default to black if color is not set
    site_ids: label?.Sites?.edges.map((site) => site?.node?.SiteID) || [],
  };

  const handleSubmit = useCallback(() => {
    const onComplete = () => {
      updateIsModalOpen(false);
      if (!label) {
        form.resetFields();
      }
    };
    form.validateFields().then((values) => {
      const { name, type, color, site_ids } = values;
      if (label) {
        updateLabel(
          label.LabelID,
          {
            name,
            labelType: type?.value,
            color,
            siteIds: site_ids,
          },
          onComplete,
        );
      } else {
        createLabel(name, type, color, site_ids, onComplete);
      }
    });
  }, [form, createLabel, updateLabel, label]);

  return (
    <>
      <Modal
        width={400}
        title={label ? 'Update Site Group' : 'Add Site Group'}
        visible={isModalOpen}
        onOk={handleSubmit}
        okButtonProps={{
          loading: isLoading,
        }}
        onCancel={() => updateIsModalOpen(false)}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter a value' }]}>
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Group Type"
            name="type"
            rules={[
              { required: true, message: 'Please select or add a new type' },
            ]}>
            <Combobox options={uniqueLabelTypes} />
          </Form.Item>
          <Form.Item
            label="Color"
            name="color"
            rules={[{ required: true, message: 'Please select a color' }]}>
            <ColorPicker
              value={form.getFieldValue('color')}
              onChange={(newColor) => {
                form.setFieldsValue({ color: newColor.toHexString() });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Sites"
            name="site_ids"
            rules={[
              { required: true, message: 'Please select atleast one site' },
            ]}>
            <span>
              <SiteSelectionCascader
                sites={sites?.edges.map((site) => site?.node) as Site[]}
                siteGroups={
                  labels?.edges.map((l) => ({
                    LabelID: l?.node?.LabelID,
                    Name: l?.node?.Name,
                    Color: l?.node?.Color,
                    LabelType: l?.node?.LabelType,
                    Sites: l?.node?.Sites?.edges.map(
                      (site) => site?.node,
                    ) as Site[],
                  })) || []
                }
                existingSelectedSites={initialValues.site_ids}
                onChange={(options) => {
                  const siteIds = options.map(
                    (option) => option[option.length - 1],
                  ) as string[];
                  form.setFieldsValue({ site_ids: siteIds });
                }}
                placeholder="Select sites"
              />
            </span>
          </Form.Item>
        </Form>
      </Modal>
      <span onClick={() => updateIsModalOpen(true)}>{children}</span>
    </>
  );
};

export default CreateUpdateLabel;
