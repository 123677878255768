/**
 * @generated SignedSource<<a48d742bf4af6d5d8074d152fb6998a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSiteInput = {
  addressObj?: AddressObject | null | undefined;
  clientMutationId?: string | null | undefined;
  contactDetails?: ReadonlyArray<SiteContact | null | undefined> | null | undefined;
  customerId: number;
  escalationPath?: string | null | undefined;
  externalId?: string | null | undefined;
  locationMapIds?: ReadonlyArray<number | null | undefined> | null | undefined;
  name?: string | null | undefined;
  projectIds?: ReadonlyArray<number | null | undefined> | null | undefined;
  shippingAddressObj?: AddressObject | null | undefined;
  siteGroupIds?: ReadonlyArray<number | null | undefined> | null | undefined;
  siteId: number;
  timezone?: string | null | undefined;
};
export type SiteContact = {
  email?: string | null | undefined;
  name?: string | null | undefined;
  phone?: string | null | undefined;
  type?: string | null | undefined;
};
export type AddressObject = {
  addressId?: number | null | undefined;
  city?: string | null | undefined;
  country?: string | null | undefined;
  postalCode?: string | null | undefined;
  state?: string | null | undefined;
  street?: string | null | undefined;
};
export type UpdateSiteMutation$variables = {
  input: UpdateSiteInput;
};
export type UpdateSiteMutation$data = {
  readonly updateSite: {
    readonly changedLabels: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"sites_changedLabels">;
    } | null | undefined> | null | undefined;
    readonly site: {
      readonly " $fragmentSpreads": FragmentRefs<"sites_siteDetails">;
    } | null | undefined;
  } | null | undefined;
};
export type UpdateSiteMutation = {
  response: UpdateSiteMutation$data;
  variables: UpdateSiteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SiteID",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Street",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "City",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "State",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Country",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "AddressID",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "PostalCode",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSitePayload",
        "kind": "LinkedField",
        "name": "updateSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "sites_siteDetails"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Label",
            "kind": "LinkedField",
            "name": "changedLabels",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "sites_changedLabels"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSitePayload",
        "kind": "LinkedField",
        "name": "updateSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ExternalID",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "Timezone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AddressInfo",
                "kind": "LinkedField",
                "name": "AddressInfo",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AddressInfo",
                "kind": "LinkedField",
                "name": "ShippingAddressInfo",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteContactObj",
                "kind": "LinkedField",
                "name": "Contacts",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "EscalationPath",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "Labels",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "LabelID",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectConnection",
                "kind": "LinkedField",
                "name": "Projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Project",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ProjectID",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "HasShippingAddress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "HasEscalationPath",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "HasContacts",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "Status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "Issues",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "StatusNotes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "IssueNotes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "HasStatusNotes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "HasIssueNotes",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Label",
            "kind": "LinkedField",
            "name": "changedLabels",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteConnection",
                "kind": "LinkedField",
                "name": "Sites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Site",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4f8af2ee489ac9e45cd437d718982923",
    "id": null,
    "metadata": {},
    "name": "UpdateSiteMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSiteMutation(\n  $input: UpdateSiteInput!\n) {\n  updateSite(input: $input) {\n    site {\n      ...sites_siteDetails\n      id\n    }\n    changedLabels {\n      ...sites_changedLabels\n      id\n    }\n  }\n}\n\nfragment sites_changedLabels on Label {\n  id\n  Sites {\n    edges {\n      node {\n        SiteID\n        Name\n        id\n      }\n    }\n  }\n}\n\nfragment sites_siteDetails on Site {\n  id\n  SiteID\n  Name\n  ExternalID\n  Timezone\n  AddressInfo {\n    Street\n    City\n    State\n    Country\n    AddressID\n    PostalCode\n    id\n  }\n  ShippingAddressInfo {\n    Street\n    City\n    State\n    Country\n    AddressID\n    PostalCode\n    id\n  }\n  Contacts {\n    name\n    email\n    phone\n    type\n  }\n  EscalationPath\n  Labels {\n    id\n    LabelID\n  }\n  Projects {\n    edges {\n      node {\n        ProjectID\n        Name\n        id\n      }\n    }\n  }\n  HasShippingAddress\n  HasEscalationPath\n  HasContacts\n  Status\n  Issues\n  StatusNotes\n  IssueNotes\n  HasStatusNotes\n  HasIssueNotes\n}\n"
  }
};
})();

(node as any).hash = "2d2133e89ec2d80a2046218420664e37";

export default node;
