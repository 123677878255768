import type { DefaultOptionType } from 'antd/lib/cascader';
import type { ChannelCascaderValue } from './types';

const findPath = (
  value?: ChannelCascaderValue,
  options: DefaultOptionType[] = [],
  currentPath: ChannelCascaderValue = [],
): ChannelCascaderValue => {
  for (const option of options) {
    // Check current option
    const newPath = [...currentPath, option.value];
    if (option.value === value?.[0] && !option?.children) {
      return newPath;
    }

    // Check children if they exist
    const path = findPath(value, option.children, newPath);
    if (path.length > 0) {
      return path;
    }
  }

  return [];
};

export const getFullCascaderPaths = (
  flatValues: ChannelCascaderValue[],
  options: DefaultOptionType[],
) => {
  return flatValues
    .map((value) => (value.length === 1 ? findPath(value, options) : value))
    .filter((path) => path.length > 0);
};

const checkIfValuePathsAreEqual = (
  arr1: ChannelCascaderValue,
  arr2: ChannelCascaderValue,
) => {
  return (
    arr1.length === arr2.length &&
    arr1.every((val, index) => val === arr2[index])
  );
};

export const disableDuplicateCascaderOptions = (
  cascaderOptions: DefaultOptionType[],
  valuePath: ChannelCascaderValue,
  startPath: ChannelCascaderValue = [],
) => {
  const leafValue = valuePath[valuePath.length - 1];
  for (const option of cascaderOptions) {
    const pathSoFar = [...startPath, option.value];
    if (
      option.value === leafValue &&
      !checkIfValuePathsAreEqual(valuePath, pathSoFar) &&
      !option.children
    ) {
      option.disabled = true;
    } else if (option.children) {
      disableDuplicateCascaderOptions(option.children, valuePath, pathSoFar);
    }
  }
};

export const updateCascaderOptions = (
  cascaderOptions: DefaultOptionType[],
  values: ChannelCascaderValue[],
) => {
  values.forEach((value) => {
    disableDuplicateCascaderOptions(cascaderOptions, value);
  });

  return cascaderOptions;
};
