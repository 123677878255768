import { Col, Form, Input, Row } from 'antd';
import _ from 'lodash';
import React from 'react';

interface AddressProps {
  title: string;
  formKey: string;
}

export const isAddressEmpty = (address: {}) => {
  return _.isEmpty(address) || _.every(address, (value) => value === undefined);
};

const Address: React.FC<AddressProps> = ({ title, formKey }) => {
  return (
    <div>
      <h4 style={{ fontWeight: 'normal' }}>{title}</h4>
      <Form.Item name={[formKey, 'street']}>
        <Input placeholder="Street address" />
      </Form.Item>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item name={[formKey, 'city']}>
            <Input placeholder="City" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={[formKey, 'state']}>
            <Input placeholder="State" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item name={[formKey, 'postalCode']}>
            <Input placeholder="ZIP/Postal Code" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={[formKey, 'country']}>
            <Input placeholder="Country" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Address;
