import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import _ from 'lodash';
import type { PreloadedQuery } from 'react-relay';
import { usePreloadedQuery } from 'react-relay';
import { SitesWithChannelsQuery } from '../../../../../MonitorQueries';
import type { MonitorQueries_SitesWithChannels_Query } from '../../../../../__generated__/MonitorQueries_SitesWithChannels_Query.graphql';
import { SelectedChannelList } from './SelectedChannelList';
import { SiteChannelsList } from './SiteChannelsList';
import { ChannelGroupsListContainer } from './styles';
import { repositionArrayElement } from './utils';
import type { ChannelTileAndName_Channel$key } from './__generated__/ChannelTileAndName_Channel.graphql';
import type { SiteChannelsListFragment$key } from './__generated__/SiteChannelsListFragment.graphql';

interface ChannelsListProps {
  queryReference: PreloadedQuery<MonitorQueries_SitesWithChannels_Query>;
  sidebarSearch: string;
  showInactiveChannels: boolean;
  selectedChannels: number[];
  setSelectedChannels: (channels: number[]) => void;
  selectedChannelTileRefs: ChannelTileAndName_Channel$key[];
  setSelectedChannelTileRefs: React.Dispatch<
    React.SetStateAction<ChannelTileAndName_Channel$key[]>
  >;
}

const ChannelsList = ({
  queryReference,
  sidebarSearch,
  showInactiveChannels,
  selectedChannels,
  setSelectedChannels,
  selectedChannelTileRefs,
  setSelectedChannelTileRefs,
}: ChannelsListProps) => {
  const queryData = usePreloadedQuery<MonitorQueries_SitesWithChannels_Query>(
    SitesWithChannelsQuery,
    queryReference,
  );

  const addChannel = (
    channelID: number | number[],
    channelTileRef:
      | ChannelTileAndName_Channel$key
      | ChannelTileAndName_Channel$key[],
  ) => {
    const channelIDs = Array.isArray(channelID) ? channelID : [channelID];
    const channelTileRefs = Array.isArray(channelTileRef)
      ? channelTileRef
      : [channelTileRef];

    const newChannelIDs = _.cloneDeep(selectedChannels);
    const newChannelTileRefs = _.cloneDeep(selectedChannelTileRefs);

    channelIDs.forEach((id, index) => {
      if (!newChannelIDs.includes(id)) {
        newChannelIDs.push(id);
      }
      if (!newChannelTileRefs.includes(channelTileRefs[index])) {
        newChannelTileRefs.push(channelTileRefs[index]);
      }
    });

    setSelectedChannels(newChannelIDs);
    setSelectedChannelTileRefs(newChannelTileRefs);
  };

  const reorderChannel = (oldIndex: number, newIndex: number) => {
    const newChannelIDs = [...selectedChannels];
    const newChannelTileRefs = [...selectedChannelTileRefs];

    repositionArrayElement(newChannelIDs, oldIndex, newIndex);
    repositionArrayElement(newChannelTileRefs, oldIndex, newIndex);

    setSelectedChannels(newChannelIDs);
    setSelectedChannelTileRefs(newChannelTileRefs);
  };

  const removeChannel = (channelID: number | number[]) => {
    const channelIDs = Array.isArray(channelID) ? channelID : [channelID];

    const newChannelIDs = _.cloneDeep(selectedChannels);
    const newChannelTileRefs = _.cloneDeep(selectedChannelTileRefs);

    channelIDs.forEach((id) => {
      const indexToRemove = newChannelIDs.indexOf(id);
      if (indexToRemove !== -1) {
        newChannelIDs.splice(indexToRemove, 1);
        newChannelTileRefs.splice(indexToRemove, 1);
      }
    });

    setSelectedChannels(newChannelIDs);
    setSelectedChannelTileRefs(newChannelTileRefs);
  };

  const siteChannelsListTabContent = queryData.sites?.edges.map(
    (siteNode, index) => {
      return (
        <SiteChannelsList
          key={index}
          siteNode={siteNode?.node as SiteChannelsListFragment$key}
          allSelectedChannels={selectedChannels}
          addChannel={addChannel}
          removeChannel={removeChannel}
          sidebarSearch={sidebarSearch}
          showInactiveChannels={showInactiveChannels}
        />
      );
    },
  );

  const selectedChannelListTabContent = (
    <SelectedChannelList
      selectedChannels={selectedChannels}
      channelRefs={selectedChannelTileRefs}
      removeChannel={removeChannel}
      reorderChannel={reorderChannel}
    />
  );

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'All Cameras',
      children: siteChannelsListTabContent,
    },
    {
      key: '2',
      label: 'Selected Cameras',
      children: selectedChannelListTabContent,
    },
  ];

  return (
    <ChannelGroupsListContainer>
      <Tabs defaultActiveKey="1" items={tabItems} centered />
    </ChannelGroupsListContainer>
  );
};

export { ChannelsList };
