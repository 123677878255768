/**
 * @generated SignedSource<<690cea5819852b0ef4fd9f732a10f206>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FleetStatusSidebar_SiteNodeQuery$variables = {
  siteId: string;
};
export type FleetStatusSidebar_SiteNodeQuery$data = {
  readonly node: {
    readonly HasIssueNotes?: boolean | null | undefined;
    readonly HasStatusNotes?: boolean | null | undefined;
    readonly IssueNotes?: string | null | undefined;
    readonly Issues?: string | null | undefined;
    readonly IssuesOptionID?: number | null | undefined;
    readonly Name?: string | null | undefined;
    readonly SiteID?: number;
    readonly Status?: string | null | undefined;
    readonly StatusNotes?: string | null | undefined;
    readonly StatusOptionID?: number | null | undefined;
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"UpdateSiteStatusMutation_site">;
  } | null | undefined;
};
export type FleetStatusSidebar_SiteNodeQuery = {
  response: FleetStatusSidebar_SiteNodeQuery$data;
  variables: FleetStatusSidebar_SiteNodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "siteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SiteID",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StatusOptionID",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "StatusNotes",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Issues",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IssuesOptionID",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "IssueNotes",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HasStatusNotes",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HasIssueNotes",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FleetStatusSidebar_SiteNodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UpdateSiteStatusMutation_site"
              }
            ],
            "type": "Site",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FleetStatusSidebar_SiteNodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "type": "Site",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abe1b93df47eddaedea31de258be73f5",
    "id": null,
    "metadata": {},
    "name": "FleetStatusSidebar_SiteNodeQuery",
    "operationKind": "query",
    "text": "query FleetStatusSidebar_SiteNodeQuery(\n  $siteId: ID!\n) {\n  node(id: $siteId) {\n    __typename\n    ... on Site {\n      id\n      SiteID\n      Name\n      Status\n      StatusOptionID\n      StatusNotes\n      Issues\n      IssuesOptionID\n      IssueNotes\n      HasStatusNotes\n      HasIssueNotes\n      ...UpdateSiteStatusMutation_site\n    }\n    id\n  }\n}\n\nfragment UpdateSiteStatusMutation_site on Site {\n  id\n  SiteID\n  Status\n  StatusNotes\n  Issues\n  IssueNotes\n  HasStatusNotes\n  HasIssueNotes\n  StatusOptionID\n  IssuesOptionID\n}\n"
  }
};
})();

(node as any).hash = "7432fd8f1733dd4ee01b6a6121936f35";

export default node;
