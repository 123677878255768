/**
 * @generated SignedSource<<64bdb2631dcd44064c80638cb6f2f1e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateSiteStatusInput = {
  clientMutationId?: string | null | undefined;
  issueNotes?: string | null | undefined;
  issuesOptionId?: number | null | undefined;
  issuesOptionText?: string | null | undefined;
  siteId: number;
  statusNotes?: string | null | undefined;
  statusOptionId?: number | null | undefined;
  statusOptionText?: string | null | undefined;
};
export type UpdateSiteStatusMutation$variables = {
  input: UpdateSiteStatusInput;
};
export type UpdateSiteStatusMutation$data = {
  readonly updateSiteStatus: {
    readonly site: {
      readonly HasIssueNotes: boolean | null | undefined;
      readonly HasStatusNotes: boolean | null | undefined;
      readonly IssueNotes: string | null | undefined;
      readonly Issues: string | null | undefined;
      readonly IssuesOptionID: number | null | undefined;
      readonly SiteID: number;
      readonly Status: string | null | undefined;
      readonly StatusNotes: string | null | undefined;
      readonly StatusOptionID: number | null | undefined;
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type UpdateSiteStatusMutation = {
  response: UpdateSiteStatusMutation$data;
  variables: UpdateSiteStatusMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSiteStatusPayload",
    "kind": "LinkedField",
    "name": "updateSiteStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "SiteID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "Status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "StatusNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "Issues",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "IssueNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "HasStatusNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "HasIssueNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "StatusOptionID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "IssuesOptionID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateSiteStatusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateSiteStatusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d0965c64389b306db8cd623f84411d83",
    "id": null,
    "metadata": {},
    "name": "UpdateSiteStatusMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateSiteStatusMutation(\n  $input: UpdateSiteStatusInput!\n) {\n  updateSiteStatus(input: $input) {\n    site {\n      id\n      SiteID\n      Status\n      StatusNotes\n      Issues\n      IssueNotes\n      HasStatusNotes\n      HasIssueNotes\n      StatusOptionID\n      IssuesOptionID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f18a5daedf2662d0cb094769edc91a9";

export default node;
